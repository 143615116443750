/**
 * 模块名称: 到款列表(未认款退款)
 * @author wangchanghong@372163.com
 */

import React, {useEffect, useState} from 'react'
import api from '@/api'
import {
    Form,
    Input,
    Tooltip, Modal, Table, Button, message
} from 'antd'

const { Column } = Table


const FormItem = Form.Item

let pageSize = 10
let currentPage = 1
let filter = {}

const DaoKuan = (props) => {

    const form = props.form;
    const { canSelectId, selectedDKIds, selectDaoKuan, daoKuanVisible, setDaoKuanVisible, setForbidden } = props
    const { getFieldDecorator, validateFields, resetFields } = props.form

    const [daoKuanSubmitLoading, setDaoKuanSubmitLoading] = useState(false)
    const [list, setList] = useState([])
    const [count, setCount] = useState(0)
    const [requiredStatus, setRequiredStatus] = useState('success')
    const [requiredMoneyStatus, setRequiredMoneyStatus] = useState('success')
    const [loading, setLoading] = useState(false)
    const [defaultSelected, setDefaultSelected] = useState([])
    const [selectData, setSelectData] = useState({})

    useEffect(() => {
        resetFields()
        setList([])
        setSelectData({})
        setDefaultSelected([...selectedDKIds])
    }, [selectedDKIds])

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        }
    }

    const getList = () => {
        setSelectData({})
        setDefaultSelected([])
        setDaoKuanSubmitLoading(true)
        setLoading(true)
        api.fundMoneyGetMoneyBackListForClaim({ ...filter, limit: pageSize, page: currentPage })
            .then(data => {
                setList(data.list)
                data.list.map(item => {
                    if(canSelectId && item.id === canSelectId){
                        setSelectData(item)
                    }
                })
                setCount(data.count)
            })
            .finally(() => {
                setLoading(false)
                setDaoKuanSubmitLoading(false)
            })
    }

    const onDaoKuanOk = () => {
        if(JSON.stringify({...selectData}) === '{}' ){
            message.error("请至少勾选一条到款信息")
            return;
        }
        if (+selectData.balance < +selectData.amount) {
            message.error("该笔到款已发生过认款，暂时无法使用未认款退款")
            return
        }
        selectDaoKuan(selectData)
        setDaoKuanVisible(false);
        setForbidden(false)
        setList([])
        resetFields()

    }

    const onDaoKuanCancel = () => {
        form.resetFields();
        setList([])
        setDefaultSelected([...selectedDKIds])
        currentPage = 1
        setDaoKuanVisible(false)
        setForbidden(true)
    }


    const onChangeTable = (pagination, filters, sorter) => {
        currentPage = pagination.current
        pageSize = pagination.pageSize
        getList()
    }

    const rowSelection = {
        onSelect: (record, selected, selectedRows) => {
          //console.log(record, selected, selectedRows)
          if(selected){
            setSelectData(record)
            setDefaultSelected([record.id])
          }else{
            setSelectData({})
            setDefaultSelected([])
          }
        },
        onChange: (selectedRowKeys, selectedRows) => {
            // console.log(selectedRowKeys, selectedRows)
            /*let defSelected = [...selectedDKIds];

            let pool = selectData
            if(canSelectId){//有可以编辑默认选中的
                if(selectedRowKeys.length < defSelected.length){
                    pool = {}
                }else if(selectedRowKeys.length === defSelected.length){
                    selectedRows.map(item => {
                        if(item.id === canSelectId || !defSelected.includes(item.id)){
                            pool = item
                        }
                    })
                }else{

                    let newItem = {}
                    selectedRows.map(item => {
                        if(!selectedRowKeys.includes(canSelectId)){
                            if(!defSelected.includes(item.id)){
                                if(item.id !== pool.id){
                                    newItem = item
                                }
                            }
                        }else{
                            if(item.id === canSelectId || !defSelected.includes(item.id)){
                                if(item.id !== pool.id){
                                    newItem = item
                                }
                            }
                        }

                    })

                    selectedRowKeys.splice(selectedRowKeys.indexOf(pool.id), 1)
                    pool = newItem
                }

            }else{//没有默认选中的
                if(selectedRowKeys.length === defSelected.length){
                    pool = {}
                }else if(selectedRowKeys.length === defSelected.length + 1){
                    pool = {}
                    selectedRows.map(item => {
                        if( !defSelected.includes(item.id)){
                            pool = item
                        }
                    })
                }else{
                    let newItem = {};
                    selectedRows.map(item => {
                        if( !defSelected.includes(item.id) && pool.id !== item.id ){
                            newItem = item
                        }
                    })

                    selectedRowKeys.splice(selectedRowKeys.indexOf(pool.id), 1)
                    pool = newItem

                }
            }

            // console.log(defSelected, selectedRowKeys, pool)
            setSelectData(pool)
            setDefaultSelected(selectedRowKeys)*/
        },
        getCheckboxProps: record => ({
            disabled:  canSelectId !== record.id && selectedDKIds.includes(record.id)
        }),
        hideSelectAll: true,
        type:'checkbox',
        selectedRowKeys: defaultSelected,
        hideDefaultSelections: true,
    }


    const onSubmit = (e) => {
        e.preventDefault()
        validateFields((err, vals) => {
            if (!err) {
                let err = false;
                if(!vals['name'] && !vals['account']){
                    message.error('付款方用户名或付款方账号至少填写一项')
                    setRequiredStatus('error')
                    err = true
                }else{
                    setRequiredStatus('success')
                }

                if(!vals['money']){
                    message.error('到款金额必填')
                    setRequiredMoneyStatus('error')
                    err = true
                }else{
                    setRequiredMoneyStatus('success')
                }

                if(err){
                    return
                }


                filter = { ...filter, ...vals }
                getList()
            }
        })
    }

    const onReset = () => {
        currentPage = 1
        resetFields()
    }

    return (
        <Modal
            title="查找到款信息"
            visible={daoKuanVisible}
            onOk={onDaoKuanOk}
            confirmLoading={daoKuanSubmitLoading}
            onCancel={onDaoKuanCancel}
            width={1100}
            maskClosable={true}
        >
            <Form {...formItemLayout} onSubmit={onSubmit} layout='inline'>
                <Tooltip title={"付款方用户名或付款方账号至少填写一项"}>
                    <FormItem label="付款方用户名" validateStatus={requiredStatus} >
                        {getFieldDecorator('name')(<Input placeholder="请输入" />)}
                    </FormItem>
                </Tooltip>
                <Tooltip title={"付款方用户名或付款方账号至少填写一项"}>
                    <FormItem label="付款方账号" validateStatus={requiredStatus}>
                        {getFieldDecorator('account')(
                            <Input placeholder="请输入" />
                        )}
                    </FormItem>
                </Tooltip>
                <FormItem label="到款金额"  validateStatus={requiredMoneyStatus}>
                    {getFieldDecorator('money')(
                        <Input placeholder="请输入" />
                    )}
                </FormItem>
                <Button onClick={onReset} style={{marginTop: 5}}>重置</Button>
                <Button style={{ marginLeft: 8, marginTop: 5 }} type="primary" htmlType="submit">查询</Button>
            </Form>
            <div style={{height: 16}}></div>
            <Table
                dataSource={list}
                rowKey="id"
                className="table-page daokuan-table"
                loading={loading}
                pagination={{
                    pageSize,
                    total: count,
                    current: currentPage,
                    showQuickJumper: true,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
                }}
                scroll={{x: 1400}}
                onChange={onChangeTable}
                rowSelection={rowSelection}
            >
                <Column title="序号" dataIndex="id" />
                <Column title="到款银行" dataIndex="bank" />
                <Column title="到款日期" dataIndex="date" />
                <Column title="付款方户名" dataIndex="name" />
                <Column title="付款方账号" dataIndex="account" />
                <Column title="到款金额" dataIndex="amount" />
                <Column title="余额" dataIndex="balance" />
                <Column title="备注" dataIndex="note" />
            </Table>
        </Modal>
    )
}

export default Form.create()(DaoKuan)