/**
 * 模块名称: 退款管理
 * @author xuxiaobo@372163.com
 */

import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Main from './Main'
// import Details from './Details'
// import Add from './Add'
// 权签需求
import NewAdd from './NewAdd'
import NotNewAdd from './NotNewAdd' // 未认款退款
import NewDetails from './NewDetails'
import './assets/style.scss'

const Refund = ({ match, history }) => {

  return (
    // <div>
    //   <Switch> 
    //     <Route path={match.path + '/details'} component={Details} />
    //     <Route path={match.path + '/edit'} component={Add} />
    //     <Route path={match.path + '/add'} component={Add} />
    //     <Route component={Main} />
    //   </Switch>
    // </div>

    // 权签需求
    <div>
      <Switch> 
        <Route path={match.path + '/details'} component={NewDetails} />
        <Route path={match.path + '/edit'} component={NewAdd} />
        <Route path={match.path + '/add'} component={NewAdd} />
        <Route path={match.path + '/addNot'} component={NotNewAdd} />
        <Route path={match.path + '/editNot'} component={NotNewAdd} />
        <Route component={Main} />
      </Switch>
    </div>
  )
}

export default Refund