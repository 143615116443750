/**
 * 模块名称: 未认款退款
 */

import React, { useState, useEffect, useRef } from 'react'
import {
	Descriptions,
	Steps,
	Button,
	Modal,
	Input,
	Icon,
	Form,
	Radio,
	Upload,
	message,
	Tooltip,
	Table,
	Popconfirm,
	Spin,
	Row,
	Col,
	Select,
	Tag
} from 'antd'
import api from '@/api'
import { parseSearch, debounce, changeMoneyToChinese } from '@/utils'
import { upLoadModule } from '@/utils/common'
import RKInfo from "./components/renkuanInfo"
import DaoKuan from "./components/daokuan"
import CryptoJS from 'crypto-js'
import BtnGroup from '@/components/BtnGroup'

const { Dragger } = Upload
const DItem = Descriptions.Item
const { Step } = Steps
const { TextArea } = Input
const FormItem = Form.Item
const { Column } = Table
const Option = Select.Option

let selectDaoKuanCB = null
let userInfo = null
let clientName = '', relationList = '', relateAuthor = ''
const NotNewAdd = (props) => {
	const { getFieldDecorator, validateFields, resetFields, setFieldsValue, getFieldsValue, validateFieldsAndScroll, getFieldValue } = props.form
	const { location, history } = props
	const draggerRef = useRef(null)
	const [detail, setDetail] = useState({})
	const [clientInfo, setClientInfo] = useState({})
	const [relationShipList, setRelationShipList] = useState([])
	const [logConList, setLogConList] = useState([])
	const [payList, setPayList] = useState([])
	const [RKForms, setRKForms] = useState([])
	const [daoKuanVisible, setDaoKuanVisible] = useState(false);
	const [DKIds, setDKIds] = useState([])
	const [canSelectId, setCanSelectId] = useState(0)
	const [fileList, setFileList] = useState([])
	const [submitLoading, setSubmitLoading] = useState(false)
	const [relationErrClass, setRelationErrClass] = useState('')
	const [RKZhuti, setRKZhuti] = useState(0)
	const [canEdit, setCanEdit] = useState(true)
	const [detailLoading, setDetailLoading] = useState(true)
	const [reload, setReload] = useState({})
	const [initialData, setInitialData] = useState({})
	const [forbidden, setForbidden] = useState(false)
	const [nameSame, setNameSame] = useState(false)
	const [addFlag, setAddFlag] = useState(true)
	const search = parseSearch(location.search)
	const relationGroupRef = useRef(null)
	const [editReasonShow, setEditReasonShow] = useState(false)
	const [allowReplenish, setAllowReplenish] = useState(0)

	const [refundData, setRefundData] = useState({})
	const [selectData, setSelectData] = useState({
		reasonList: [],
		accountList: [],
	})
	const [saveLoading, setSaveLoading] = useState(false)
	const [detailDataId, setdetailDataId] = useState(0)
	const [drawerTitle, setDrawerTitle] = useState('退款申请')
	const [moneyChinese, setMoneyChinese] = useState('')
	const [userAuth, setUserAuth] = useState({})

	useEffect(() => {
		relateAuthor = ''
	}, [])

	useEffect(() => {
    document.documentElement.scrollTop = 0
		if (search.id) {
			setDrawerTitle('退款驳回重提')
			setEditReasonShow(false)
			setAllowReplenish(search.allowReplenish ? search.allowReplenish : 0)
			api.getRefundDetail({ id: search.id }).then(data => {
				if (data.reasonType == "5") {
					setEditReasonShow(true)
				}
				else {
					setEditReasonShow(false)
				}
				setRefundData(data)
				setUserAuth(data.userAuth)
				setdetailDataId(data.id)
				const { payeeCompany, bank, bankAccountNo, address, contacts, contactsTel, } = data.extInfo
				const { companyId, reasonType, reason, money, remark, attachment } = data
				getSelectData(data.relationType, data.relationId).then(() => {
					setFieldsValue({
						companyId,
						// money,
						payeeCompany,
						bank,
						bankAccountNo,
						address,
						contacts,
						contactsTel,
						reasonType,
						reason,
						remark
					})
				})
				// setMoneyChinese(changeMoneyToChinese(money))
				if (attachment.length) {
					attachment.forEach((item, index) => {
					  fileList.push({
						status: 'done',
						uid: index,
						name: item.name,
						url: item.url
					  })
					})
					setFileList(attachment.map((item, index) => ({ ...item, uid: index })))
				}
				const apis = [
					api.getCustomerGetInfo({ id: data.relationId, type: data.relationType }),
					api.fundMoneyClaimGetRelationShip(),
					api.fundMoneyBackGetPayType(),
					api.getUser(),
				]
				setDetailLoading(true)
				Promise.all(apis)
					.then(res => {
						const _clientInfo = res.shift()
						setClientInfo(_clientInfo)
						clientName = _clientInfo.name.trim()
						const _relationShipList = res.shift()
						relationList = _relationShipList
						setRelationShipList(_relationShipList)

						const _payList = res.shift()
						setPayList(_payList)
						userInfo = res.shift()

						//这个不能写在Promise的外面，因为使用了_fundAccountList变量，而_fundAccountList变量是在这里初始化的
						if (search.id) {
							getDetail(search.id, clientName) //在getDetail里配置是否可编辑
						} else {

							// setCanEdit(true)
							setDetailLoading(false)
						}
					})
			}).catch(() => history.goBack())
		} else {
			// 新增进入
			// api.fundMoneyClaimGetClaimType({ relationType: search.clientType }).then(data => {
			// 	detail.claimType = data
			// 	setDetail(detail)
			// }) 获取业类型
			setDetailLoading(true)
			setDrawerTitle('退款申请')
			setEditReasonShow(false)
			// search.relationType = search.relationType ? search.relationType : 0;
			// search.relationId = search.relationId ? search.relationId : 1;
			api.getCusTomDetail({ relationType: search.relationType, relationId: search.relationId }).then(data => {
				getSelectData(data.relationType, data.relationId)
				setDetailLoading(false)
				setRefundData(data)
				setdetailDataId(0)
			})

			const apis = [
				api.getCustomerGetInfo({ id: search.relationId, type: search.relationType }),
				api.fundMoneyClaimGetRelationShip(),
				api.fundMoneyBackGetPayType(),
				api.getUser(),
			]
			setDetailLoading(true)
			Promise.all(apis)
				.then(res => {
					const _clientInfo = res.shift()
					setClientInfo(_clientInfo)
					clientName = _clientInfo.name.trim()

					const _relationShipList = res.shift()
					relationList = _relationShipList
					setRelationShipList(_relationShipList)

					const _payList = res.shift()
					setPayList(_payList)
					userInfo = res.shift()

					//这个不能写在Promise的外面，因为使用了_fundAccountList变量，而_fundAccountList变量是在这里初始化的
					if (search.id) {
						getDetail(search.id, clientName) //在getDetail里配置是否可编辑
					} else {

						// setCanEdit(true)
						setDetailLoading(false)
					}
				})

		}
		canEdit && !search.id && addRKForm()
	}, [reload])

	// 获取数据
	const getSelectData = (relationType, customerId) => {
		return Promise.all([
			api.getSelectRefundReasonList({ type: "refund_reason_type" }),  // 退款原因
			api.getFundAccountList({ relationType, customerId, type: 1, refundType: 1 }) // 主体
		]).then(list => {
			setSelectData({
				reasonList: list[0],
				accountList: list[1].list
			})
			return list
		})
	}

	const onChangeReasonType = (data) => {
		if (data == "5") {
			setEditReasonShow(true)
		}
		else {
			setEditReasonShow(false)
		}
	}

	//获取认款详情
	const getDetail = (id) => {
		setDetailLoading(true)
		api.getRefundDetail({ id }).then(
			detail => {
				setDetail(detail)
				relateAuthor = detail.author
				setLogConList(detail.procedureLogList)
				setFieldsValue({
					relationshipId: detail.extInfo.relationshipId,
					remark: detail.remark
				})

				// const _RKForms = detail.claimItemList.list;
				const _RKForms = JSON.parse(detail.extInfo.moneyback)
				// console.log("_RKForms", _RKForms)
				// console.log('_RKForms=>', _RKForms)
				let nList = _RKForms.reduce((total, curr) => {
					if (curr.moneyBackDetail !== null) {
						return [...total, curr.moneyBackDetail.name]
					} else {
						return [...total]
					}

				}, [])
				let res = [...new Set(nList)].filter(item => item !== clientName)
				for (let i = 0; i < res.length; i++) {
					if (res[i] == '') res = []
				}
				setRKForms(_RKForms)
				setDetailLoading(false)
				// setCanEdit(detail.busCode === 3 && detail.stfId === userInfo.id)

				if (_RKForms.length && _RKForms[0].moneyBackDetail) {
					let item = _RKForms[0].moneyBackDetail

				}
			}
		)
	}

	// 文件上传 
	const uploadFiles = upLoadModule({
	fileList: fileList,
	setFileList: setFileList,
	draggerRef: draggerRef,
	accept: '.png, .jpg, .jpeg, .PDF, .zip, .xls, .xlsx',
	allowSizeType: 2,
	limitNum: 20
	})

	const getPureName = (str) => {
		let RexStr = /\(|\)|\（|\）|\uff08|\uff09/g
		str = str.replace(RexStr, function () {
			return ''
		})
		return str
	}

	const handleSubmit = (e) => {
		e.preventDefault()
		validateFieldsAndScroll((err, vals) => {
			if (!err) {
				let nameFlag = null
				if (RKForms.length === 1) {
					let nameB = null
					if (RKForms[0].moneyBackDetail && clientInfo.name.trim()) {
						nameB = getPureName(RKForms[0].moneyBackDetail.name)
						if (getPureName(clientInfo.name.trim()) == nameB) {
							setNameSame(true)
							nameFlag = true
						}
					}
				} else if (RKForms.length > 1) {
					let nameArr = []
					for (let i = 0; i < RKForms.length; i++) {
						if (RKForms[i].moneyBackDetail && clientInfo.name.trim()) {
							let nameB = getPureName(RKForms[i].moneyBackDetail.name)

							if (getPureName(clientInfo.name.trim()) !== nameB) {
								nameArr.push(nameB)
							}
						}
					}
					if (nameArr.length) {
						setNameSame(false)
						nameFlag = false
					} else {
						setNameSame(true)
						nameFlag = true
					}
				}
				if (vals.payWay_0 == 3 || vals.payWay_0 == 4) {
					if (!nameFlag && !vals.relationshipId) {
						//ReactDOM.findDOMNode(relationGroupRef.current).scrollIntoView({behavior: 'smooth'})
						setRelationErrClass('has-err')
						message.error("请先选择打款方与客户关系")
						return
					} else {
						setRelationErrClass('')
					}
				} else {
					setRelationErrClass('')
				}
				// 上传附件
				let uploadList = []
				for (let i = 0, k = fileList.length; i < k; i++) {
					if (fileList[i].status !== 'removed') {
					uploadList.push({
						url: fileList[i].url,
						name: fileList[i].name
					})
					}
				}

				const submitVals = {
					...vals,
					id: detailDataId,
					refundType: 1,
					relationType: search.relationType ? search.relationType : refundData.relationType,
					relationId: search.relationId ? search.relationId : refundData.relationId,
					allowReplenish: allowReplenish,
					claimList: [],
					attachment: JSON.stringify(uploadList)
				}

				if (vals.relationshipId) {
					submitVals.relationshipId = vals.relationshipId
				}
				let moneyArr = []
				for (let i in vals) {
					if (/^account\_/.test(i)) {
						let ind = i.split('_')[1]
						submitVals.claimList.push({
							account: vals['account_' + ind],  // 账号/卡号/支票号/收据号
							payWay: vals['payWay_' + ind],   // 到款方式
							remark: vals['remark_' + ind],  //备注
							rkQuota: vals['rkQuota_' + ind],  //实认金额
							moneyBackId: [3, 4].includes(vals['payWay_' + ind]) ? RKForms[ind].moneyBackId : 0
						})
						moneyArr.push(RKForms[ind].SrJE)
					}
				}

				// let sumValue = moneyArr.map(Number).reduce((sum2, number2) => {
				// 	return sum2 + number2
				// }, 0)
				// if (+((Number(vals.money)).toPrecision(6)) > +((Number(sumValue)).toPrecision(6))) {
				// 	return message.error('!退款金额大于到款金额')
				// }

				if (!err) {
					setSaveLoading(true)
					setTimeout(() => {
						api.saveRefund(submitVals)
							.then(() => {
								message.success('操作成功')
								setTimeout(() => {
									history.push('/fund/refundManage')
									setSaveLoading(false)
								}, 1000);
							})
							.catch(() => {
								setSaveLoading(false)
							})
					}, 1000)
				}
				else {
					// console.log(err)
				}

			} else {
				// for (let i in err) {
				// 	message.error(err[i].errors[0].message)
				// }
			}
		})
	}

	const addRKForm = (data) => {
		data = data || {}
		setRKForms([...RKForms, data])
	}

	const deleteItem = (index) => {
		const delData = RKForms[index]
		RKForms.splice(index, 1)

		deleteDKIds(delData.moneyBackId)
		setRKForms([...RKForms])
	}

	const deleteDKIds = (id) => {
		setDKIds(DKIds.filter(item => {
			return id ? id !== item : true
		}))
	}

	const selectDaoKuan = (data) => {
		selectDaoKuanCB(data)
	}

	const showDaoKuan = (callback, canSelectId) => {
		setDaoKuanVisible(true)
		setCanSelectId(canSelectId)
		selectDaoKuanCB = callback
	}

	const setRenKuanInfo = (data) => {
		setRKForms(data)
		let total = 0
		data.map(item => {
			total += item.SrJE * 100
		})
		setFieldsValue({
			money: total / 100
		})
		setMoneyChinese(changeMoneyToChinese(total / 100 + ''))
	}

	const formItemLayout = {
		labelCol: {
			xs: { span: 24 },
			sm: { span: 8 },
		},
		wrapperCol: {
			xs: { span: 24 },
			sm: { span: 16 },
		}
	}

	return (
		<Spin spinning={detailLoading}>
			<Form onSubmit={handleSubmit}>
				<div className="pro-detail">
					<Descriptions title={drawerTitle} layout="horizontal " className="pro-detail-title">
						<dItem label={+refundData.relationType ? "QID" : "OAID"}>
							<>
								{refundData.relationId}
							</>
						</dItem>
						<dItem label="客户类型">
							<>
								{refundData.relationTypeCn}
							</>
						</dItem>
						{/* <dItem label={+refundData.relationType ? "代理商名称" : "客户名称"}>
							<>
								{refundData.relationName}
							</>
						</dItem> */}
						<DItem label={(() => {
							const label = +refundData.relationType ? "代理商名称" : "客户名称"
							return (
								<Tooltip title={<div>
									<span>1、当客户名称/代理商名称与付款方户名不一致时，判定为第三方认款。</span>
									<br />
									<span>2、第三方客户打款需要选择打款方与客户关系。</span>
									<br />
									<span>3、选择“近亲属”必须是个人客户</span>
								</div>}>
									<span>
										<Icon className="operate-icon" style={{ color: '#aaa' }} type="info-circle" /> {label}
									</span>
								</Tooltip>
							)
						})()}>{clientInfo.name}</DItem>
						<dItem label="真实性验证号码类型">
							<>
								{refundData.verifyTypeCn}
							</>
						</dItem>
						<dItem label="真实性验证号码">
							<>
								{refundData.verifyNumber}
							</>
						</dItem>
					</Descriptions>
					<>
						<div className="line-box"></div>
						{
							canEdit ?
								<Form.Item ref={relationGroupRef} className={relationErrClass} label={<span>打款方与客户关系</span>} style={{ margin: '20px 0' }}>
									{getFieldDecorator('relationshipId')(
										<Radio.Group>
											{
												relationShipList.map(item => <Radio disabled={!canEdit || nameSame} key={item.id} value={item.id}>{item.name}</Radio>)
											}
										</Radio.Group>
									)}
								</Form.Item>
								:
								detail.relationshipName && <Form.Item ref={relationGroupRef} className={relationErrClass} label={<span>打款方与客户关系</span>} style={{ margin: '20px 0' }}>
									{detail.relationshipName}
								</Form.Item>
						}
					</>
					<div className="line-box"></div>
					<Descriptions title="到款信息" className="pro-detail-smailtitle"></Descriptions>
					<div style={{ paddingBottom: '20px' }}>
						{
							(() => {
								return RKForms.map((data, index) => {
									return <RKInfo
										canEdit={canEdit} setDefaultSelectIDs={setDKIds}
										deleteDKIds={deleteDKIds} showDaoKuan={showDaoKuan}
										setRKForms={setRenKuanInfo} RKForms={RKForms} data={data} key={index}
										deleteItem={deleteItem} index={index} payList={payList}
										getFieldDecorator={getFieldDecorator} validateFields={validateFields}
										resetFields={resetFields} setFieldsValue={setFieldsValue}
										getFieldsValue={getFieldsValue} forbidden={forbidden}
										setNameSame={setNameSame}
										setAddFlag={setAddFlag}
										clientInfoName={clientInfo.name}
									/>
								})
							})()
						}
						{
							canEdit && addFlag &&
							<Button icon="plus" style={{ width: '330px', marginLeft: '10px', marginTop: 15 }} onClick={() => addRKForm()}>添加 </Button>
						}
					</div>
					{/* {logConList.length > 0 &&
						<>
							<div className="line-box"></div>
							<Table
								dataSource={logConList}
								rowKey="id"
								pagination={false}
								style={{ marginTop: 10 }}
							>
								<Table.Column title="申请人/审批人" dataIndex="userInfo.staffName" />
								<Table.Column title="审批时间" dataIndex="addTime" />
								<Table.Column title="状态" dataIndex="status" />
								<Table.Column title="备注" dataIndex="chargeMsg" />
							</Table>
						</>
					} */}
					<div className="line-box"></div>
					<Descriptions title="退款信息填写" className="pro-detail-smailtitle"></Descriptions>
					<Row gutter={24} className="refund-Money">
						<Col span={6}>
							<FormItem label="退款主体" {...formItemLayout}>
								{getFieldDecorator('companyId', {
									rules: [{ required: true, message: '请选择退款主体' }]
								})(<Select
									// placeholder="请选择"
									showSearch={true}
									optionFilterProp="children"
									dropdownMatchSelectWidth={false}
									disabled={search.id > 0 && userAuth && userAuth.allowReplenish ? true : false} 
								>
									{selectData.accountList.map(item => <Option key={item.companyId} value={item.companyId}>{item.companyName}</Option>)}
								</Select>)}
							</FormItem>
						</Col>
						<Col span={6}>
							<Form.Item label="退款金额" {...formItemLayout}>
								{getFieldDecorator('money', {
									rules: [{ required: true, message: '请输入退款金额' }]
								})(<Input
									onChange={() => setMoneyChinese('')}
									onBlur={evt => setMoneyChinese(changeMoneyToChinese(evt.target.value))}
									disabled={true}
								/>)}
							</Form.Item>
						</Col>
						<Col span={6}>
							<FormItem label="收款单位" {...formItemLayout}>
								{getFieldDecorator('payeeCompany', {
									rules: [{ required: true, message: '请输入收款单位' },
									{ pattern: /\S/, message: '收款单位不能为空' }]
								})(<Input />)}
							</FormItem>
						</Col>
						<Col span={6}>
							<FormItem label="开户行" {...formItemLayout}>
								{getFieldDecorator('bank', {
									rules: [{ required: true, message: '请输入开户行' },
									{ pattern: /\S/, message: '开户行不能为空' }]
								})(<Input />)}
							</FormItem>
						</Col>
					</Row>
					<Row gutter={24} className="refund-Money">
						<Col span={6}></Col>
						<Col span={6}>
							<FormItem {...formItemLayout} label={<span style={{ color: 'red' }}>大写金额</span>} >
								<span style={{ color: 'red' }}>{moneyChinese}</span>
							</FormItem>
						</Col>
					</Row>
					<Row gutter={24}>
						<Col span={6}>
							<FormItem label="银行账号" {...formItemLayout}>
								{getFieldDecorator('bankAccountNo', {
									rules: [{ required: true, message: '请输入银行账号' },
									{ pattern: /\S/, message: '银行账号不能为空' }]
								})(<Input />)}
							</FormItem>
						</Col>
						<Col span={6}>
							<FormItem label="地址" {...formItemLayout}>
								{getFieldDecorator('address', {
									rules: [{ required: false, message: '请输入地址' }]
								})(<Input />)}
							</FormItem>
						</Col>
						<Col span={6}>
							<FormItem label="联系人" {...formItemLayout}>
								{getFieldDecorator('contacts', {
									rules: [{ required: false, message: '请输入联系人' }]
								})(<Input />)}
							</FormItem>
						</Col>
						<Col span={6}>
							<FormItem label="联系电话" {...formItemLayout}>
								{getFieldDecorator('contactsTel', {
									rules: [{ required: false, message: '请输入联系电话' }]
								})(<Input />)}
							</FormItem>
						</Col>
					</Row>
					<Row gutter={24}>
						<Col span={7}>
							<FormItem label="退款原因" {...formItemLayout}>
								{getFieldDecorator('reasonType', {
									rules: [{ required: true, message: '请选择退款原因' }]
								})(
									<Select onChange={onChangeReasonType} style={{ width: 150 }} >
										{selectData.reasonList.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
									</Select>
								)}
							</FormItem>
						</Col>
						<Col span={6}>
							{
								editReasonShow ?
									<Form.Item label="具体原因" {...formItemLayout}>
										{getFieldDecorator('reason', {
											rules: [{ required: false, message: '请输入退款原因' }]
										})(<Input placeholder="请输入" />)}
									</Form.Item>
									: null
							}
						</Col>
					</Row>
					<Row>
						<Col>
							<FormItem label="附件" {...formItemLayout} labelCol={{ span: 2 }}>
								<div className="dragger-box" ref={draggerRef}>
									<Dragger
										{...uploadFiles}
									>
										<p className="ant-upload-drag-icon">
											<Icon type="inbox" />
										</p>
										<p className="ant-upload-text">点击或将文件拖拽到这里上传</p>
										<p className="ant-upload-hint">
											附件支持格式：PNG、JPG、PDF、zip、xls、xlsx 支持添加多个附件，单个附件大小不超过5M，文件数量不超过20个。
							</p>
									</Dragger>
								</div>
							</FormItem>
						</Col>
					</Row>
					<Row>
						<Col>
							<FormItem label="备注" {...formItemLayout} labelCol={{ xs: 2 }} >
								{getFieldDecorator('remark', {
									rules: [{ required: false, message: '请输入备注' }]
								})(<Input.TextArea />)}
							</FormItem>
						</Col>
					</Row>
					<BtnGroup onCancel={() => {
						history.goBack()
					}} loading={saveLoading} cancelDisabled={saveLoading} />
				</div>
			</Form>
			<DaoKuan canSelectId={canSelectId} selectedDKIds={DKIds} selectDaoKuan={selectDaoKuan}
				daoKuanVisible={daoKuanVisible} setDaoKuanVisible={setDaoKuanVisible} setForbidden={setForbidden} />
		</Spin>
	)
}

export default Form.create()(NotNewAdd);