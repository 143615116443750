/**
 * 模块名称: 公司制度列表
 * @author xuzhongyuan@372163.com
 */

import React, { useState, useEffect } from 'react'
import BtnGroup from '@/components/BtnGroup'
import { Table, Alert, Form, Input, DatePicker } from 'antd'
import api from '@/api'
import { parseSearch } from '@/utils'
import moment from 'moment'
import { Link } from 'react-router-dom'
import urls from '@/api/urls'
import CryptoJS from 'crypto-js'

const Column = Table.Column
const FormItem = Form.Item
const { RangePicker } = DatePicker

let pageSize = 10
let currentPage = 1
let filter = {
  title: '',
  name: '',
  sTime: '',
  eTime: ''
}

const Main = (props) => {
  const { match, location, history } = props
  const { getFieldDecorator, getFieldsValue, resetFields } = props.form
  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])
  const [count, setCount] = useState(0)
  const search = parseSearch(location.search)

  useEffect(() => {
    currentPage = search.page ? +search.page : 1
    pageSize = search.pageSize ? +search.pageSize : 10
    filter = {
      title: '',
      name: '',
      sTime: '',
      eTime: ''
    }
    getData()
  }, [])

  const getData = () => {
    setLoading(true)
    api.getIndexCorpRule({ limit: pageSize, page: currentPage, ...filter }).then(data => {
      setList(data.list)
      setCount(data.count)
      setLoading(false)
    }).catch(() => setLoading(false))
  }

  const onChangeTable = (pagination, filters, sorter) => {
    currentPage = pagination.current
    pageSize = pagination.pageSize
    history.replace(`${match.path}?page=${currentPage}&pageSize=${pageSize}`)
    getData()
  }

  const formSubmit = (e) => {
    e.preventDefault()
    currentPage = 1
    history.replace(match.path)
    const vals = getFieldsValue()
    filter.title = vals.title
    filter.name = vals.name
    getData()
  }

  const onChangePicker = (data) => {
    if (data.length === 0) {
      filter.sTime = ''
      filter.eTime = ''
    } else {
      filter.sTime = moment(data[0]).format('YYYY-MM-DD')
      filter.eTime = moment(data[1]).format('YYYY-MM-DD')
    }
  }

  const onResetFields = () => {
    resetFields()
    filter = {
      title: '',
      name: '',
      sTime: '',
      eTime: ''
    }
    currentPage = 1
    history.replace(match.path)
    getData()
  }

  return (
    <>
      <div>
        <Form layout="inline" onSubmit={formSubmit}>
          <FormItem label="标题">
            {getFieldDecorator('title', { initialValue: '' })(<Input placeholder="请输入" />)}
          </FormItem>
          <FormItem label="创建人">
            {getFieldDecorator('name', { initialValue: '' })(<Input placeholder="请输入" />)}
          </FormItem>
          <FormItem label="创建时间">
            {getFieldDecorator('range', { initialValue: ''})(<RangePicker onChange={onChangePicker} />)}
          </FormItem>
          <FormItem>
            <BtnGroup right style={{ marginTop: 4 }} cancelName="重置" confirmName="查询" onCancel={onResetFields} />
          </FormItem>
        </Form>
      </div>
      <Alert className="alert-ant" message={`总计：${count}条数据`} type="info" showIcon />
      <Table
        dataSource={list}
        rowKey="id"
        className="table-ant"
        loading={loading}
        pagination={{
          pageSize,
          total: count,
          current: currentPage,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
        }}
        scroll={{ x: 1000 }}
        onChange={onChangeTable}
      >
        <Column title="标题" key="title" render={text => <Link to={`${match.path}/details?id=${text.id}&time=${+new Date()}&sign=${CryptoJS.MD5(text.id+'ad629fddf8b8756d2e72e96ae035a5a4'+(+new Date())).toString()}`}>{text.title}</Link>} />
        <Column title="创建人" dataIndex="userName" />
        <Column title="创建时间" dataIndex="addTime" />
        <Column title="操作" key="set" render={(text) => <a href={`${urls.downloadIndexCorpRule.url}?id=${text.id}`} target="_blank" rel="noopener noreferrer">下载附件</a>} />
      </Table>
    </>
  )
}

export default Form.create()(Main)