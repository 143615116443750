/**
 * 模块名称: 获取邮件配置
 * @author zhuyan@372163.com
 */
import React, { useState, useEffect } from 'react'
import {
  Form,
  Alert,
  Input,
  Select,
  message
} from 'antd'
import api from '@/api'
import BtnGroup from '@/components/BtnGroup'
import Auth from '@/components/AuthMiddleware'

const FormItem = Form.Item
const Option = Select.Option

const formItemLayout = {
  labelCol: {
    xs: { span: 10},
    sm: { span: 3 },
  },
  wrapperCol: {
    xs: { span: 10 },
    sm: { span: 10 },
  }
}
const MailTemplate = (props) => {
  const { getFieldDecorator, validateFields, setFieldsValue, resetFields } = props.form
  const { TextArea } = Input;
  const [ options, setOptions ] = useState([])
  const [ what, setWhat] = useState([])
  const [ content, setContent] = useState('')//eslint-disable-line
  const [ title, setTitle ] = useState('')//eslint-disable-line
  const [authList, setAuthList] = useState([])

  useEffect(() => {
    getThirdPartyMailTpl()
    api.getPageAuth().then(list => setAuthList(list))
  }, [])

  const getThirdPartyMailTpl = () => {
    api.getThirdPartyMailTpl({},true)
    .then(data => {
      setOptions(data.options)
      setWhat(data.list)
    })
  }

  const onTitleChange = ({ target: { value } }) => {
    setTitle(value)
  }

  const onContentChange = ({ target: { value } }) => {
    setContent(value)
  };

  // 取消
  const onCancel = (e) => {
    e.preventDefault()
    resetFields()
  }
  
  const formSubmit = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      if (!err) {
        api.setThirdPartyMailTpl({
          tplid: vals.tplid,
          title: vals.title,
          content: vals.content
        }).then(() => {
          message.success('设置成功')
          getThirdPartyMailTpl()
        }).catch(() => { })
      }
    })
  }

  const onSelect = (key, option) => {
    for (let i = 0; i < what.length; i++) {
      if (i == key) { //eslint-disable-line
        setFieldsValue({
          title: what[i].title,
          content: what[i].content
        })
      }
    }
  }

  return (
    <div>
      <Alert
        message="操作提示"
        description="1、请先选择邮件模板
        2、请按照模板内提供的变量进行修改，以免出错"
        type="warning"
        showIcon
      />
      <div className="select-form">
        <Form onSubmit={formSubmit} {...formItemLayout}>
          <FormItem label="请选择邮件模板">
            {getFieldDecorator('tplid', {
              rules: [{ required: true, message: '请选择邮件模板' }]
            })(
              <Select placeholder="请选择邮件模板" onSelect = {onSelect}>
                {options.map((item, index) => (
                  <Option key={index}>{item}</Option>
                ))}
              </Select>
            )}
          </FormItem>
          <FormItem label="邮件主题">
            {getFieldDecorator('title', {
            })(
              <Input placeholder="邮件主题" onChange={onTitleChange} />
            )}
          </FormItem>
          <FormItem label="邮件内容">
            {getFieldDecorator('content', {
            })(
              <TextArea placeholder="请输入邮件内容" rows={6} onChange={onContentChange} />
            )}     
          </FormItem>
          <Auth auths={authList} code="operate">
            <BtnGroup onCancel={onCancel} />
          </Auth> 
        </Form>
      </div>
    </div>
  )
}

export default Form.create()(MailTemplate)
