/**
 * 模块名称: 帮助中心详情页
 * @author zhuyan@372163.com
 */

import React, { useState, useEffect } from 'react'
import { parseSearch } from '@/utils'
import DetailsComp from '@/components/Details'
import urls from '@/api/urls'
import api from '@/api'
import { message } from 'antd'
import CryptoJS from 'crypto-js'

const HelpCenterDetail = (props) => {
  const { history } = props

  const [data, setData] = useState({
    id: '',
    title: '',
    crateTime: '',
    createBy: '',
    content: '',
    number: '',
    file: [],
  })
  const search = parseSearch(props.location.search)

  useEffect(() => {
    let plaintext = search.sign
    let plaintextTwo = CryptoJS.MD5(search.id + 'ad629fddf8b8756d2e72e96ae035a5a4' + search.time).toString()
    
    if (plaintext == plaintextTwo && +new Date() - +search.time <= 21600000) {
      api.getSupportQuestionDetail({ id: search.id, manager: '1' }, true).then(data => {
        // setData(data)
        setData({ 
          ...data,
          crateTime: data.addTime,
          content: data.content,
          createBy: data.userName,
          number: data.readNumber
        })
      })
    } else {
      message.error('没有本页访问权限')
      setTimeout(() => {
        history.go(-2)
      }, 1000)
    }
  }, [])

  return (
    <DetailsComp
      data={data}
      onClose={history.goBack}
      url={data.file && data.file.length ? `${urls.downloadQuestionAttachment.url}?id=${data.id}` : null}
    />
  )
}

export default HelpCenterDetail
