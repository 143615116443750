/**
 * 公司制度列表
 */

import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Main from './Main'
import Details from './Details'

const Regulation = ({ match, history }) => {

  return (
    <Switch>
      <Route path={match.path + '/details'} component={Details} />
      <Route component={Main} />
    </Switch>
  )
}

export default Regulation