/**
 * 模块名称: 退款列表
 * @author xuxiaobo@372163.com
 */

import React, { useState, useEffect, useRef } from 'react'
import api from '@/api'
import {
  Table,
  Form,
  Alert,
  Button,
  Input,
  Select,
  message,
  Icon,
  Row,
  Col,
  Modal,
  Popconfirm,
  DatePicker,
  Tooltip,
  Switch
} from 'antd'
import { Link } from 'react-router-dom'
import { parseSearch } from '@/utils'
import Auth from '@/components/AuthMiddleware'
import CorpFilter from '@/components/CorpFilter'
import Export from '@/components/Export'
import moment from 'moment'
import CryptoJS from 'crypto-js'

const { Column } = Table
const FormItem = Form.Item
const Option = Select.Option
const { RangePicker } = DatePicker
let editId = '' //编辑使用
let pageSize = 10
let currentPage = 1

let filter = {
  companyId: '',
}

let selectedIds = ''
let params = {}

let refundTypeList = [{
  id: 1,
  name: '未认款退款'
}, {
  id: 0,
  name: '正常退款'
}]
let shouldMe = 0

const Refund = (props) => {
  const { match, location, history } = props
  const { getFieldDecorator, validateFields, resetFields, setFieldsValue } = props.form
  const productsRef = useRef()
  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])
  const [count, setCount] = useState(0)
  const [authList, setAuthList] = useState([])
  const [selectedCount, setSelectedCount] = useState(0)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [expand, setExpand] = useState(false)
  const [relationTypeList, setRelationTypeList] = useState([])
  const [refundStatusList, setRefundStatusList] = useState([])
  const [showExport, setShowExport] = useState(false)
  const search = parseSearch(location.search)
  const [statusVal, setStatusVal] = useState(undefined)
  const [filterContent, setFilterContent] = useState([])
  const [filterInfoValue, setFilterInfoValue] = useState('')
  const [visible, setVisible] = useState(false)
  const [statusValName, setStatusValName] = useState(undefined)
  const [isShow, setIsShow] = useState(false)
  // const [checked, setChecked] = useState(0)

  useEffect(() => {
    shouldMe = 0
    // console.log(history.location.state)
    if (!history.location.state) {
      currentPage = search.page ? +search.page : 1
      pageSize = search.pageSize ? +search.pageSize : 10
      filter.updateTimeMin = ''
      filter.updateTimeMax = ''
      filter.no = ''
      filter.refundStatus = ''
      filter.relationId = ''
      filter.oldRelationId = ''
      filter.relationName = ''
      filter.relationType = ''
      filter.refundType= ''
    } else {
      currentPage = search.page ? +search.page : currentPage
      pageSize = search.pageSize ? +search.pageSize : pageSize
      // console.log(filter)
      setTimeout(() => {
        setFieldsValue({
          relationType: filter.relationType || filter.relationType == 0 ? filter.relationType : undefined,
          relationName: filter.relationName ? filter.relationName : undefined,
          relationId: filter.relationId ? filter.relationId : undefined,
          oldRelationId: filter.oldRelationId ? filter.oldRelationId : undefined,
          refundStatus: filter.refundStatus || filter.refundStatus == 0 ? filter.refundStatus : undefined,
          refundType: filter.refundType || filter.refundType == 0 ? filter.refundType : undefined,
          no: filter.no ? filter.no : undefined,
          updateTime: filter.updateTimeMin ? [moment(filter.updateTimeMin, 'YYYY-MM-DD'), moment(filter.updateTimeMax, 'YYYY-MM-DD')] : undefined,
        })
      }, 1000)
    }
    getList()
    getRelationTypeList()
    getRefundStatusList()
    api.getPageAuth().then(list => setAuthList(list))
    // console.log(authList)
  }, [])

  const getList = () => {
    setLoading(true)
    // filter.relationId = search.relationId
    // filter.relationType = search.relationType
    api.getRefundList({ ...filter, limit: pageSize, page: currentPage, type: 2, shouldMe })
    .then(data => {
      setLoading(false)
      for(var i = 0; i < data.list.length; i++){
        //data.list[i].operateAuth = ['edit', 'delete', 'browse-detail']
      }
      setList(data.list)
      setCount(data.count)
    })
    .catch(() => setLoading(false))
  }

  const getRelationTypeList = () => {
    api.getSelectRelationTypeList({ type: "customer_type" }).then(data => {
      setRelationTypeList(data)
    })
  }

  const getRefundStatusList = () => {
    api.getSelectRefundStatusList({ type: "money_refund_status" }).then(data => {
      setRefundStatusList(data)
    })
  }

  // 查询
  const onSearch = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      if (!err) {
        if(vals.updateTime){
          delete vals.updateTime
        }
        filter = { ...filter, ...vals }
        currentPage = 1
        history.replace(match.path)
        getList()
      }
    })
  }
  
  const onChangeTable = (pagination, filters, sorter) => {
    currentPage = pagination.current
    pageSize = pagination.pageSize
    history.replace(`${match.path}?page=${currentPage}&pageSize=${pageSize}`)
    getList()
  }

  const onChangeCorp = (data) => {
    setSelectedCount(0)
    setSelectedRowKeys([])
    filter.companyId = data.map(item => item.id).join(',')
    history.replace(match.path)
    currentPage = 1
    getList()
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
      setSelectedCount(selectedRowKeys.length)
      setSelectedRowKeys(selectedRowKeys)
      selectedIds = selectedRowKeys
    },
    selectedRowKeys: selectedRowKeys
  }

  const onToggle = () => {
    setExpand(!expand)
  }

  const onReset = () => {
    resetFields()
    filter.updateTimeMin = ''
    filter.updateTimeMax = ''
    filter.no = ''
    filter.refundStatus = ''
    filter.relationId = ''
    filter.oldRelationId = ''
    filter.relationName = ''
    filter.relationType = ''
    filter.refundType = ''
    setStatusVal(undefined)
    setStatusValName(undefined)
    currentPage = 1
    history.replace(match.path)
    getList()
  }

  const onShowExportDialog = () => {
    if (filter.companyId && filter.companyId.length > 0) {
      if (!Array.isArray(filter.companyId)) {
        filter.companyId = filter.companyId.split(',')
      }
    } else {
      filter.companyId = []
    }
    setShowExport(true)
  }

  // 弹出框确定
  const HandleOk = () => {
    api.saveSearchStorage({
      name: filterInfoValue,
      key: location.pathname,
      content: params
    }).then(data => {
      setVisible(false)
      message.success('添加成功')
    })
  }

  const HandleCancel = () => {
    setVisible(false) //弹出框内取消
  }
  
  const FilterInfoChange = (e) => {
    setFilterInfoValue(e.target.value) //弹框input值
  }

  const onChangeGetFilter = (val) => {
    // 下拉框搜索内容
    api.getSearchStorage({ key: location.pathname }).then(data => {
      setFilterContent(data.list)
    })
  }

  const onChecked = (value, val2) => {
    setStatusVal(value)
    setStatusValName(val2.props.children.props.children[0])
    // let data = JSON.parse(value)
    let data = filterContent[value].content
    setFieldsValue({
      companyId: data.companyId ? data.companyId : undefined,
      relationName: data.relationName ? data.relationName : undefined,
      relationId: data.relationId ? data.relationId : undefined,
      oldRelationId: data.oldRelationId ? data.oldRelationId : undefined,
      relationType: data.relationType,
      refundStatus: data.refundStatus + '' ? data.refundStatus : undefined,
      refundType: data.refundType + '' ? data.refundType : undefined,
      updateTime: data.updateTime ? [moment(data.updateTime[0], 'YYYY-MM-DD'), moment(data.updateTime[1], 'YYYY-MM-DD')] : undefined
    })
  }
  
  const onSaveFilterTerm = () => {
    validateFields((err, vals) => {
      if (!err) {
        const values = Object.values(vals).map(item => item == undefined ? '' : item)
        if (values.some(item => item !== '')) {
          params = {}
          for (let k in vals) {//eslint-disable-line
            if (vals[k] !== undefined) {
              params[k] = vals[k]
            }
          }
          setVisible(true)
        } else {
          message.error('当前没有设置筛选条件')
        }
      }
    })
  }

  // 添加
  const onAdd = () => {
    editId = 0
    history.push('/fund/refundManage/add')
  }

  //编辑
  // const onEdit = (date) => {
  //   editId = date.id
  //   let userAuth = date.userAuth
  //   let allowReplenish = userAuth.allowReplenish ? 1 : 0
  //   history.push('/fund/refundManage/edit?id='+editId+'&allowReplenish='+allowReplenish)
  // }
  //编辑 权签需求
  const onEdit = (date) => {
    if (+date.refundType === 1) {
      editId = date.id
      let userAuth = date.userAuth
      let allowReplenish = userAuth.allowReplenish ? 1 : 0
      history.push('/fund/refundManage/editNot?id='+editId+'&allowReplenish='+allowReplenish)
    } else {
      editId = date.id
      let userAuth = date.userAuth
      let allowReplenish = userAuth.allowReplenish ? 1 : 0
      history.push('/fund/refundManage/edit?id='+editId+'&allowReplenish='+allowReplenish)
    }
  }

  const onDelete = (id) => {
    api.getRefundDelete({
      id: id
    }).then(res => {
      message.success('删除成功')
      for(let i = 0; i < selectedIds.length; i++){
        if(selectedIds[i] === id){
          selectedIds.splice(i, 1)
          setSelectedCount(selectedIds.length)
          i--
        }
      }
      getList()
    })
  }

  const onChangePicker = (data) => {
    if (data.length === 0) {
      filter.updateTimeMin = ''
      filter.updateTimeMax = ''
    } else {
      filter.updateTimeMin = moment(data[0]).format('YYYY-MM-DD')
      filter.updateTimeMax = moment(data[1]).format('YYYY-MM-DD')
    }
  }

  // 筛选删除
  const onSearchDelete = (id) => {
    api.searchStorageDelete({
      id: id
    }).then(() => {
      api.getSearchStorage({ key: location.pathname }).then(data => {
        setFilterContent(data.list)
      })
    })
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    }
  }

  const onSetShouldMe = (checked) => {
    currentPage = 1
    setIsShow(checked)
    if (checked) {
      shouldMe = 1
    } else {
      shouldMe = 0
    }
    setTimeout(() => {
      getList()
    }, 600)
  }

  return (
    <>
      <CorpFilter onChange={onChangeCorp} />
      <Form onSubmit={onSearch} {...formItemLayout} className="ant-advanced-search-formTwo">
        <div className="search-item-wrap">
          <div className="search-item">
            <Row gutter={24}>
              <Col span={8}>
                <FormItem label="退款编号" labelCol={{span: 8}}>
                  {getFieldDecorator('no', { initialValue: '' })(<Input placeholder="请输入" />)}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label="客户/代理商名称" labelCol={{span: 8}}>
                  {getFieldDecorator('relationName', { initialValue: '' })(<Input placeholder="请输入" />)}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label="XOAID/XQID" labelCol={{span: 8}}>
                  {getFieldDecorator('relationId', { initialValue: '' })(<Input placeholder="请输入" />)}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24} className={expand?'':'hide'}>
              <Col span={8}>
                <FormItem label="老ID" labelCol={{span: 8}}>
                  {getFieldDecorator('oldRelationId', { initialValue: '' })(<Input placeholder="请输入" />)}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label="客户类型" labelCol={{span: 8}}>
                  {getFieldDecorator('relationType')(
                    <Select placeholder="请选择">
                      {relationTypeList.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                    </Select>
                  )}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label="退款状态" labelCol={{span: 8}}>
                  {getFieldDecorator('refundStatus')(
                    <Select placeholder="请选择">
                      {refundStatusList.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                    </Select>
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24} className={expand?'':'hide'}>
              {/* 权签 */}
              <Col span={8}>
                <FormItem label="退款类型" labelCol={{span: 8}}>
                  {getFieldDecorator('refundType')(
                    <Select placeholder="请选择">
                      {refundTypeList.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                    </Select>
                  )}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label="退款日期">
                  {getFieldDecorator('updateTime', { initialValue: ''})(<RangePicker onChange={onChangePicker} />)}
                </FormItem>
              </Col>
            </Row>
          </div>
          <span className={`expand-btn${expand ? ' show-all' : ''}`} onClick={onToggle} style={{cursor: 'pointer', fontSize: '12px', color: '#1890ff'}}>
            <span>{expand ? '收起' : '展开'} <Icon type={expand ? 'up' : 'down'} /></span>
          </span>
        </div>
        <div className="search-btns" style={{paddingBottom: 10}}>

        <div className={`search-btns ${expand ? '' : 'hide'}`}>
            <h4 className="filter-title">已保存筛选方案：</h4>
            <div className="filter-select">
              <Select
                style={{ display: 'inline-block', width: '160px' }}
                placeholder="请选择"
                value={statusVal}
                onDropdownVisibleChange={bool => bool && onChangeGetFilter(bool)}
                onSelect={onChecked}
                dropdownMatchSelectWidth={false}
              >
                {
                  filterContent.map((item, index) => 
                    <Option value={index} key={index}>
                      <div className="closeStyBtn">
                        {item.name}
                        {statusValName !== item.name ? <span className="closeSpan"><Icon style={{ fontSize: '10px', color: '#1890ff' }} type="close" onClick={e => {
                          e.stopPropagation()
                          onSearchDelete(item.id)
                        }} /></span> : null}
                      </div>
                    </Option>
                  )
                }
              </Select>
            </div>
            <Button className="filter-savebtn" style={{ marginLeft: 8, marginRight: 8 }} onClick={onSaveFilterTerm}>保存筛选条件</Button>
          </div>
          <Switch
            checkedChildren="待审批"
            unCheckedChildren="待审批"
            checked={isShow}
            onClick={onSetShouldMe}
            style={{ marginRight: 8 }}
            className="shouldme-switch-btn"
          />
          <Button onClick={onReset}>重置</Button>
          <Button style={{ marginLeft: 8 }} type="primary" htmlType="submit">查询</Button>
          <Modal
            title="保存筛选条件"
            visible={visible}
            onOk={HandleOk}
            onCancel={HandleCancel}
          >
            <Input placeholder="请输入筛选名称" value={filterInfoValue} onChange={FilterInfoChange} />
          </Modal>
        </div>
      </Form>
      <div className="line"></div>
      <div className="operate-wrap">
        <Auth auths={authList} code="operate">
            <Button type="primary" icon="plus" onClick={onAdd}>添加</Button>
        </Auth>
        <Auth auths={authList} code="export">
            <Button type="primary" icon="upload" onClick={onShowExportDialog}>导出</Button>
        </Auth>
      </div>
      <Alert className="count-alert" message={
        <>
          <span>已选择 <span style={{color: '#168FFF'}}>{selectedCount}</span> 项</span>
          <span style={{ marginLeft: 20 }}>总共{count}条数据</span>
        </>
      } type="info" showIcon />
      <Table
        size="small"
        dataSource={list}
        rowKey="id"
        className="table-page"
        loading={loading}
        pagination={{
          pageSize,
          total: count,
          current: currentPage,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
        }}
        scroll={{x: 1900}}
        onChange={onChangeTable}
        rowSelection={rowSelection}
      >
        <Column title="序号" dataIndex="id" fixed="left" width={80} />
        <Column title="退款编号" dataIndex="no" fixed="left" width={140} render={(text, record) =>
          <>
            {
              <Link to={`${match.path}/details?id=${record.id}&new=1&time=${+new Date()}&sign=${CryptoJS.MD5(record.id+'ad629fddf8b8756d2e72e96ae035a5a4'+(+new Date())).toString()}`}>{text}</Link>
            }
          </>
        } />
        <Column title="XOAID/XQID" dataIndex="relationId" />
        <Column title="老ID" dataIndex="oldRelationId" />
        <Column title="客户/代理商名称" dataIndex="relationName" />
        <Column title="客户类型" dataIndex="relationTypeCn" />
        {/* 权签 */}
        <Column title="退款类型" dataIndex="refundTypeCn" /> 
        <Column title="主体" dataIndex="companyName" />
        <Column title="退款金额" dataIndex="money" />
        <Column title="申请人" dataIndex="applyStaffName" />
        <Column title="负责人" dataIndex="principalName" />
        <Column title="备注" dataIndex="remark" />
        <Column title="最后处理时间" dataIndex="lastApprovedTime" />
        <Column title="状态" dataIndex="refundStatusCn" />
        <Column title="操作"
          key="set" 
          fixed="right"
          width={100}
          render={(text, record) =>
            <>
              <Tooltip title="在新页面打开详情页" placement="bottom">
                <Link target='_blank' to={`${match.path}/details?id=${record.id}&time=${+new Date()}&sign=${CryptoJS.MD5(record.id+'ad629fddf8b8756d2e72e96ae035a5a4'+(+new Date())).toString()}`}>
                  <Icon type="folder-open" style={{ color: '#1890FF', fontSize: '16px', marginLeft: '8px' }} />
                </Link>
              </Tooltip>
              <Auth auths={text.operateAuth} code="edit">
                <Icon className="operate-icon" style={{ color: '#1890ff' }} type="edit" onClick={() => onEdit(record)} />
              </Auth>
              {' '}
              <Auth auths={text.operateAuth} code="delete">
                <Popconfirm title="请确认是否删除，删除后不可恢复。" cancelText="取消" okText="确认" onConfirm={() => onDelete(record.id)}>
                  <Icon className="operate-icon" style={{ color: 'red' }} type="delete" />
                </Popconfirm>
              </Auth>
            </>
          }
        />
      </Table>
      <Export
        show={showExport}
        onCancel={() => {
          setShowExport(false)
          productsRef.current.changeVal()
        }}
        tplUrl="getRefundExportTpl"
        fieldsUrl="getRefundTplItems"
        saveUrl="saveRefundTpl"
        exportUrl="exportRefund"
        method="export"
        parame={{ ExportTyp: 'refund', id: selectedIds, ...filter }}
        type={1}
        cRef={productsRef}
      />
    </>
  )
}

export default Form.create()(Refund)