/**
 * 模块名称: 帮助中心管理 => 反馈列表 => 回复
 * @author zhuyan@372163.com
 */
import React, { useState, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import api from '@/api'
import {
  Form,
  Upload,
  message,
  Icon,
  Radio
} from 'antd'
import BtnGroup from '@/components/BtnGroup'
import Editor from 'wangeditor'
import { parseSearch } from '@/utils'
import { upLoadModule } from '@/utils/common'

const FormItem = Form.Item
const { Dragger } = Upload
// let pageSize = 10
// let currentPage = 1
// let tabActiveKey = 2
let search = null

const WriteBack = (props) => {
  const { getFieldDecorator, validateFields, setFieldsValue } = props.form
  const { history } = props
  const [fileList, setFileList] = useState([])
  const [replyContent, setContent] = useState('')
  const [topContent, setTopContent] = useState('')
  const draggerRef = useRef(null)
  const [editorHtml, setEditorHtml] = useState('')//eslint-disable-line
  const [editorText, setEditorText] = useState('')//eslint-disable-line
  const contentRef = useRef(null)
  search = parseSearch(props.location.search)

  useEffect(() => {
    // console.log(search)
    // currentPage = search.page ? +search.page : 1
    // pageSize = search.limit ? +search.limit : 10
    api.getSupportFeedbackDetail({ id: search.id, manager:'1'}, true).then(data => {
      if (data.content) {
        let nodes = data.content
        let reg = new RegExp('<table border="0"', 'g')
        nodes = nodes.replace(reg, '<table style="border:1px solid #666" ')
        let reg2 = new RegExp('<td', 'g')
        nodes = nodes.replace(reg2, '<td style="border:1px solid #666" ')
        let reg3 = new RegExp('<th', 'g')
        nodes = nodes.replace(reg3, '<th style="border:1px solid #666" ')
        data.content = nodes
      }
      setTopContent(data)
    })
    onReplyDetail()
    initEditor()
  }, [])

  // 回复详情
  // const ReplyDetail = () => {
  //   api.getSupportFeedbackReplyDetail({ id: search.id }).then(data => {
  //     console.log (data)
  //   })
  // }

  //根据是否传值过来判断是回复还是修改
  const onReplyDetail = () => {
    if (search.editRowId) {
      api.getFeedbackReplyDetail({ id: search.editRowId, edit:1}).then(res => {
        setFieldsValue({
          isOpen: res.isOpen,
        })
        if (res.file.length) {
          res.file.map((item, index) => {
            fileList.push({
              status: 'done',
              uid: index,
              name: item.name,
              url: item.url
            })
          })
          setFileList(res.file.map((item, index) => ({...item, uid: index})))
        }
        setContent(res.replyContent)
        let editor = new Editor(ReactDOM.findDOMNode(contentRef.current))
        dealEditor(editor)
        editor.create()
        editor.txt.html(res.replyContent)
        //console.log(fileList)				
      }).catch(() => { })
    }
  }

  //富文本
  const initEditor = () => {
    let editor = new Editor(ReactDOM.findDOMNode(contentRef.current))
    dealEditor(editor)
    editor.create()
  }
  //富文本数据配置
  const dealEditor = (editor) => {
    editor.customConfig.zIndex = 100
    editor.customConfig.showLinkImg = false
    editor.customConfig.uploadImgServer = '/api/File/upload'
    editor.customConfig.uploadFileName = 'file'
    editor.customConfig.uploadImgHeaders = {
      token: localStorage.getItem('token')
    }
    // 自定义菜单配置
    editor.customConfig.menus = [
      'head',  // 标题
      'bold',  // 粗体
      'fontSize',  // 字号
      'fontName',  // 字体
      'italic',  // 斜体
      'underline',  // 下划线
      'strikeThrough',  // 删除线
      'foreColor',  // 文字颜色
      'backColor',  // 背景颜色
      'link',  // 插入链接
      'list',  // 列表
      'justify',  // 对齐方式
      'quote',  // 引用
      'image',  // 插入图片
      'table',  // 表格
      'code',  // 插入代码
      'undo',  // 撤销
      'redo'  // 重复
    ]
    //插入视频 : 'video'
    //'emoticon',  // 表情
    editor.customConfig.onchange = (html) => {
      //console.log(html)
      setEditorHtml(html)
      setEditorText(editor.txt.text())
      setFieldsValue({
        replyContent: html
      })
    }
    editor.customConfig.uploadImgHooks = {
      before(xhr, editor, files) {
        //console.log(xhr, editor, files)
      },
      success(xhr, editor, result) {
        //console.log(xhr, editor, result)
      },
      fail(xhr, editor, result) {
        // console.log(xhr, editor, result)
      },
      error(xhr, editor, result) {
        // console.log(xhr, editor, result)
      },
      timeout(xhr, editor) {
        // console.log(xhr, editor)
      },
      customInsert(insertImg, result, editor) {
        //console.log(insertImg, result, editor)
        var url = result.data.full
        insertImg(url)
      }
    }
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 2 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 22 },
    }
  }

  //提交表单
  const formSubmit = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      let params = Object.assign({}, vals)
      let uploadList = []
      for (let i = 0, k = fileList.length; i < k; i++) {
        if (fileList[i].status !== 'removed') {
          uploadList.push({
            url: fileList[i].url,
            name: fileList[i].name
          })
        }
      }
      params.file = uploadList
      if (search.id) {
        params.supportFeedbackId = search.id
        if (search.editRowId) {
          params.edit = 1
        }
      } 
      if (!search.id) params.supportFeedbackId = ''
      if (!err) {
        api.saveSupportFeedbackFeedback(params).then(res => {
          if (!search.editRowId) {
            message.success('回复成功')
          } else {
            message.success('修改成功')
          }
          history.goBack()
          // history.replace('/setSystem/helpCenterMgt/' + '?page=' + currentPage + '&limit=' + pageSize + '&activeKey=' + 2)
        })
      }
    })
  }

  //取消发起申请
  // const onCancel = () => {
  //   history.push({
  //     pathname: '/setSystem/helpCenterMgt',
  //     state: { 'tabActiveKey': '2' }
  //   })
  // }

  // 文件上传 
	const uploadFiles = upLoadModule({
		fileList: fileList,
		setFileList: setFileList,
		draggerRef: draggerRef,
		accept: '.gif, .png, .jpg, .jpeg, .PDF, .doc, .docx, .csv, .xlsx',
		allowSizeType: 2
	})

  return (
    <div className="write-detail">
      <Form onSubmit={formSubmit} {...formItemLayout} style={{ width: "70%" }}>
        <div className="write-con">
          <div className="write-con-zi">标题：</div>
          <div>{topContent.title}</div>
        </div>
        <div className="write-con">
          <div className="write-con-zi">内容：</div>
          <div className="write-topContent" dangerouslySetInnerHTML={{__html: topContent.content}} />
        </div>
        
        <FormItem label="回复">
          {getFieldDecorator('replyContent', {
            initialValue: replyContent,
            rules: [{ required: true, message: '请输入内容' }]
          })(<div className="wangEditor-container" ref={contentRef}></div>)}
        </FormItem>
        <Form.Item label="是否公开">
          {getFieldDecorator('isOpen')(
            <Radio.Group>
              <Radio value={0}>是</Radio>
              <Radio value={1}>否</Radio>
            </Radio.Group>
          )}
        </Form.Item>
        <FormItem label="附件">
          <div className="dragger-box" ref={draggerRef}>
            <Dragger
              {...uploadFiles}
            >
              <p className="ant-upload-drag-icon">
                <Icon type="inbox" />
              </p>
              <p className="ant-upload-text">点击或将文件拖拽到这里上传</p>
              <p className="ant-upload-hint">
                附件支持格式：GIF、PNG、JPG、PDF、doc、docx、csv、xlsx  支持添加多个附件，单个附件大小不超过5M，文件数量不超过1个。
							</p>
            </Dragger>
          </div>
        </FormItem>
        <div style={{marginTop: 30}}>
          <BtnGroup onCancel={history.goBack} />
        </div>
      </Form>
    </div>
  )
}

export default Form.create()(WriteBack)
