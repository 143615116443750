/**
 * 模块名称: 获取短信配置
 * @author liujingxue@372163.com
 */
import React, { useState, useEffect } from 'react'
import {
  Form,
  Alert,
  Input,
  Select,
  message
} from 'antd'
import api from '@/api'
import BtnGroup from '@/components/BtnGroup'
import Auth from '@/components/AuthMiddleware'

const FormItem = Form.Item
const Option = Select.Option

const formItemLayout = {
  labelCol: {
    xs: { span: 10},
    sm: { span: 3 },
  },
  wrapperCol: {
    xs: { span: 10 },
    sm: { span: 10 },
  }
}
const SmsTemplate = (props) => {
  const { getFieldDecorator, validateFields, setFieldsValue, resetFields } = props.form
  const { TextArea } = Input;
  // const [saveLoading, setSaveLoading] = useState(false)
  const [ options, setOptions ] = useState([])
  const [ what, setWhat] = useState([])
  const [ content, setContent] = useState('')//eslint-disable-line
  const [ title, setTitle ] = useState('')//eslint-disable-line
  const [authList, setAuthList] = useState([])

  useEffect(() => {
    getThirdPartySmsTpl()
    api.getPageAuth().then(list => setAuthList(list))
  }, [])

  const getThirdPartySmsTpl = () => {
    api.getThirdPartySmsTpl({},true)
    .then(data => {
      setOptions(data.options)
      setWhat(data.list)
    })
  }

  const onTitleChange = ({ target: { value } }) => {
    setTitle(value)
  }

  const onContentChange = ({ target: { value } }) => {
    setContent(value)
  };

  // 取消
  const onCancel = (e) => {
    e.preventDefault()
    resetFields()
  }
  
  const formSubmit = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      if (!err) {
        // console.log(vals)
        // setSaveLoading(true)
        api.setThirdPartySmsTpl({
          tplid: vals.tplid,
          aliTplId: vals.aliTplId,
          signName: vals.signName,
          content: vals.content
        }).then(() => {
          // setSaveLoading(false)
          message.success('设置成功')
          getThirdPartySmsTpl()
        }).catch(() => {
          //setSaveLoading(false)
        })
      }
    })
  }

  const onSelect = (key, option) => {
    //console.log(what)
    for (let i = 0; i < what.length; i++) {
      if (i == key) { //eslint-disable-line
        setFieldsValue({
          signName: what[i].signName,
          content: what[i].content,
          aliTplId: what[i].aliTplId
        })
      }
    }
  }

  return (
    <div>
      <Alert
        message="操作提示"
        description={
          <>
            <div>1、填写短信签名名称和模板ID请与阿里短信申请的保持一致</div>
            <div>2、编辑短信内容时请根据提供的模板进行修改，模板内的每个变量是固定的，且不可变位置</div>
            <div>3、标识 “*” 的选项为必填项，其余为选填项。</div>
          </>
        }
        type="warning"
        showIcon
      />
      <div className="select-form">
        <Form onSubmit={formSubmit} {...formItemLayout}>
          <FormItem label="请选择短信模板">
            {getFieldDecorator('tplid', {
              rules: [{ required: true, message: '请选择短信模板' }]
            })(
              <Select placeholder="请选择短信模板" onSelect = {onSelect}>
                {options.map((item, index) => (
                  <Option key={index}>{item}</Option>
                ))}
              </Select>
            )}
          </FormItem>
          <FormItem label="短信签名">
            {getFieldDecorator('signName', {
            })(
              <Input placeholder="短信签名" onChange={onTitleChange} />
            )}
          </FormItem>
          <FormItem label="模板ID">
            {getFieldDecorator('aliTplId', {
            })(
              <Input placeholder="模板ID" onChange={onTitleChange} />
            )}
          </FormItem>
          <FormItem label="短信内容">
            {getFieldDecorator('content', {
            })(
              <TextArea placeholder="请输入短信内容" rows={6} onChange={onContentChange} />
            )}     
          </FormItem>
          <Auth auths={authList} code="operate">
            <BtnGroup onCancel={onCancel} />
          </Auth> 
        </Form>
      </div>
    </div>
  )
}

export default Form.create()(SmsTemplate)
