/**
 * 模块名称: 退款详情页
 */

import React, { useState, useEffect, useRef } from 'react'
import {
  Descriptions,
  Steps,
  Button,
  Modal,
  Input,
  Table,
  Icon,
  Tooltip,
  message,
  Spin,
  Upload,
  Select,
  Row,
  Col
} from 'antd'
import api from '@/api'
import { parseSearch, debounce, downloadFile, changeMoneyToChinese, onUrge } from '@/utils'
import CryptoJS from 'crypto-js'
import { upLoadModule } from '@/utils/common'
import { upLoadModuleAli } from '@/utils/commonAli'
import { useSelector } from "react-redux";

const dItem = Descriptions.item
const { Step } = Steps
const { Column } = Table
const { Dragger } = Upload
const Option = Select.Option

let pageSize = 10
let currentPage = 1
let pageSizeOa = 10
let currentPageOa = 1
let pageSize2 = 10
let currentPage2 = 1
let pageSizeOa2 = 10
let currentPageOa2 = 1

const NewDetails = (props) => {
  const { match, location, history } = props
  const search = parseSearch(props.location.search)
  const [detailData, setDetailData] = useState({})
  const [extinfo, setExtinfo] = useState({})
  const [extInfoFlag, setExtInfoFlag] = useState(true)
  const [refundOrderInfo, setRefundOrderInfo] = useState({}) // 审批信息
  const [proof, setProof] = useState([])
  const [count, setCount] = useState(0)
  const [countOa, setCountOa] = useState(0) // 老oa未收款发票
  const [count2, setCount2] = useState(0)
  const [countOa2, setCountOa2] = useState(0)
  const [statusList, setStatusList] = useState([])
  const [logConList, setLogConList] = useState([])
  const [buttonAuthList, setButtonAuthList] = useState({})
  const [invoiceList, setInvoiceList] = useState([])  // 未收款发票
  const [invoiceListOa, setInvoiceListOa] = useState([])  // 老oa未收款发票
  const [accountDetailList, setAccountDetailList] = useState([]) // 易企信关联收款
  const [oaRechargeList, setOaRechargeList] = useState([]) // 老oa关联收款
  const [detailDataId, setdetailDataId] = useState('')
  const [passModalVisible, setModalVisible] = useState(false) //确认弹框
  const [turnModalVisible, setTurnModalVisible] = useState(false) //驳回弹框
  const [updateVisible, setUpdateVisible] = useState(false) //补充资料弹框
  const [textDisplay, setTextDisplay] = useState(true) //是否提示必选
  const [textAreaVal, setTextAreaVal] = useState('') //判断框是否为空
  const [pasDesc, setPasDesc] = useState('')  //通过意见
  const [updateVal, setUpdateVal] = useState('')  //补充资料意见
  const [infoLoading, setInfoLoading] = useState(true)//详情页刷新的loading
  const [moneyVal, setmoneyVal] = useState(null)
  const [orderList, setOrderList] = useState([])
  const [moneybackList, setMoneybackList] = useState([])
  const draggerRef = useRef(null)
  const proofRef = useRef(null)
  const [fileList, setFileList] = useState([])
  const [filesVisible, setFilesVisible] = useState(false)
  const [canDistribution, setCanDistribution] = useState(false)//设置分配按钮是否展示
  const [distributionVisible, setDistributionVisible] = useState(false)
  const [reviewLoading, setReviewLoading] = useState(false) // 审批确定loading
  const [distStaffList, setDistStaffList] = useState([])
  const [disStaffName, setDisStaffName] = useState('')
  const [fileId, setFileId] = useState(undefined)
  const [moneyChinese, setMoneyChinese] = useState('') // 大写金额
  const userInfo = useSelector(state => state.userInfo)
  const [downloadDis, setonDownloadDis] = useState(false)
  const [proofVisible, setProofVisible] = useState(false)
  const [proofList, setProofList] = useState([])
  const [OSSData, setOSSData] = useState({})
  const [approveLoading, setApproveLoading] = useState(false)//通过按钮的loading
  const [userAuthList, setUserAuthList]= useState({}) // 所属用户权限

  useEffect(() => {
    let plaintext = search.sign
    let plaintextTwo = CryptoJS.MD5(search.id + 'ad629fddf8b8756d2e72e96ae035a5a4' + search.time).toString()

    if (plaintext == plaintextTwo && +new Date() - +search.time <= 21600000) {
      // console.log(search.new)
      pageSize = 10; currentPage = 1
      pageSizeOa = 10; currentPageOa = 1
      pageSize2 = 10; currentPage2 = 1
      pageSizeOa2 = 10; currentPageOa2 = 1
      getDetail()
    } else {
      message.error('没有本页访问权限')
      setTimeout(() => {
        history.go(-2)
      }, 1000)
    }
  }, [])

  const getDetail = () => {
    setInfoLoading(true)
    api.getRefundDetail({ id: search.id }).then(data => {
      if (search.msgId) {
        api.setMessageReaded({ ids: [search.msgId] })
      }

      setDetailData(data)
      setMoneyChinese(changeMoneyToChinese(data.money))
      setExtinfo(data.extInfo)
      setExtInfoFlag(data.extInfoFlag)
      setRefundOrderInfo(data.refundOrderInfo ? data.refundOrderInfo : '')
      // 获取客户逾期未还的负利订单
      getOrderList(data.id, data.relationType, data.relationId)
      getRefundInvoiceList(data.id) // 易企信发票
      getOaUnReceiveInvoice(data.id) // 老oa发票
      // 凭证
      if (data.refundOrderInfo) {
        if (Array.isArray(data.refundOrderInfo.certificate)) {
          setProof(data.refundOrderInfo.certificate)
        } else {
          setProof(data.refundOrderInfo.certificate == '' ? null : JSON.parse(data.refundOrderInfo.certificate))
        }
      }
      setMoneybackList(JSON.parse(data.extInfo.moneyback))
      setdetailDataId(data.id)
      setStatusList(data.procedureStatusList ? data.procedureStatusList : []) // 审批进度
      setLogConList(data.logList) // 审批日志
      setButtonAuthList(data.buttonAuthList) // 审批权限
      setCanDistribution(data.canDistributive)
      setUserAuthList(data.userAuth)

      getRefundAccountDetailList(data.id) // 易企信关联收款
      getOaRecharge(data.id) // 老oa关联收款
      // api.getRefundAccountDetailList({ id: search.id, editFlag: 0, limit: pageSize2, page: currentPage2 }).then(datatmp => {
      //   setCount2(datatmp.count)
      //   setAccountDetailList(datatmp.list)
      //   let val = null  // 选中发票额度和
      //   datatmp.list.map((v) => {
      //     val = +val + (+v.invoice + +v.refundAmount)
      //   })
      //   setmoneyVal(+val)
      // })
      setInfoLoading(false)
    }).catch(() => setInfoLoading(false))
  }

  const getOrderList = (id, relationType, relationId) => {
    api.getUnRefundOrderList({ id, relationType, relationId }).then(data => {
      setOrderList(data)
    })
  }

  // 易企信未收款发票
  const getRefundInvoiceList = (id) => {
    api.getRefundInvoiceList({ id, limit: pageSize, page: currentPage }).then(datatmp => {
      setCount(datatmp.count)
      setInvoiceList(datatmp.list)
    })
  }

  // 老oa未收款发票
  const getOaUnReceiveInvoice = (refundId) => {
    api.getOaUnReceiveInvoice({ refundId, limit: pageSizeOa, page: currentPageOa }).then(res => {
      setCountOa(res.count)
      setInvoiceListOa(res.list)
    })
  }

  // 易企信未收款发票分页
  const onChangeTable1 = (pagination, filters, sorter) => {
    currentPage = pagination.current
    pageSize = pagination.pageSize
    getRefundInvoiceList(search.id)
  }

  // 老oa未收款发票分页
  const onChangeTableOa = (pagination, filters, sorter) => {
    currentPageOa = pagination.current
    pageSizeOa = pagination.pageSize
    getOaUnReceiveInvoice(search.id)
  }

  // 易企信关联收款
  const getRefundAccountDetailList = (id) => {
    api.getRefundAccountDetailList({ id, editFlag: 0, limit: pageSize2, page: currentPage2, }).then(res => {
      setCount2(res.count)
      setAccountDetailList(res.list)
    })
  }

  // 老oa关联收款
  const getOaRecharge = (refundId) => {
    api.getOaRecharge({ refundId, limit: pageSizeOa2, page: currentPageOa2, editFlag: 0 }).then(res => {
      setCountOa2(res.count)
      setOaRechargeList(res.list)
    })
  }

  // 易企信关联收款分页
  const onChangeTable2 = (pagination, filters, sorter) => {
    currentPage2 = pagination.current
    pageSize2 = pagination.pageSize
    getRefundAccountDetailList(search.id)
  }

  // 老oa关联收款分页
  const onTableOaRecharge = (pagination, filters, sorter) => {
    currentPageOa2 = pagination.current
    pageSizeOa2 = pagination.pageSize
    getOaRecharge(search.id)
  }

  //审批类型 0通过 1驳回 2转上级 3补充资料
  // 转上级
  const onTurnUp = () => {
    api.auditRefund({
      id: detailDataId,
      type: 2,
      remark: ''
    }).then(data => {
      history.push('/fund/refundManage')
    }).catch()
  }

  // 补充资料
  const onReplenish = () => {
    api.auditRefund({
      id: detailDataId,
      type: 3,
      remark: updateVal
    }).then(data => {
      getDetail()
    })
    setUpdateVisible(false)
  }

  // 驳回
  const onBtnTurnShow = () => {
    setTurnModalVisible(true)
    setTextDisplay(true)
  }

  const onTurnCancel = () => {
    setTurnModalVisible(false)
  }

  // 驳回样式
  const turnNode = () => {
    return <>
      确认驳回 <Icon type="close-circle" theme="twoTone" twoToneColor="#DC143C" />
    </>
  }

  // 同意样式
  const agreeNode = () => {
    return <>
      确认通过 <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" />
    </>
  }

  const onTurnApprove = () => {
    if (buttonAuthList.reject_msg_require) {
      if (textAreaVal.trim() === '') {
        setTextDisplay(false)
        return
      }
    }

    //审批类型 0通过 1驳回 2转上级 3补充资料
    api.auditRefund({
      id: detailDataId,
      type: 1,
      remark: textAreaVal
    }).then(data => {
      getDetail()
    })
    setTurnModalVisible(false)
  }  
  
  // 通过
  const onBtnPassShow = () => {
    setModalVisible(true)
  }

  const onPassCancel = () => {
    setModalVisible(false)
  }

  const onPassDescChange = (e) => {
    setPasDesc(e.target.value)
  }

  const onApprove = () => {
    //审批类型 0通过 1驳回 2转上级 3补充资料
    setApproveLoading(true)
    api.auditRefund({
      id: detailDataId,
      type: 0,
      remark: pasDesc
    }).then(data => {
      getDetail()
      setApproveLoading(false)
      setModalVisible(false)
    }).catch(() => setModalVisible(false))
    
  }

  const onDescChange = (e) => {
    setTextAreaVal(e.target.value)
    setTextDisplay(true)
  }

  //退回列表页
  const onGoBack = () => {
    history.push({ pathname: '/fund/refundManage', state: { goBack: 1 } })
  }

  // 文件上传 
  const uploadFiles = upLoadModuleAli({
    fileList: fileList,
    setFileList: setFileList,
    draggerRef: draggerRef,
    accept: '.xls, .xlsx, .png, .jpg, .jpeg, .pdf, .zip',
    allowSizeType: 7,
    limitNum: 5,
    oss: OSSData
  })

  const onFilesOk = () => {
    if (fileList.length == '' || fileList.length < 0) {
      return message.info('请上传附件')
    }
    let uploadList = []
    for (let i = 0, k = fileList.length; i < k; i++) {
      if (fileList[i].status !== 'removed') {
        uploadList.push({
          url: fileList[i].url,
          name: fileList[i].name
        })
      }
    }
    //上传传附件
    api.getUnRefundSaveAttachment({
      id: fileId && fileId,
      refundId: extinfo && extinfo.id,
      attachment: JSON.stringify(uploadList)
    }).then(data => {
      getDetail()
    })
    setFilesVisible(false)
  }

  const getUploadSign = () => {
    api.getFileAlisign({})
      .then(res => {
        setOSSData(res)
      })
  }

  const onOpenFiles = (id) => {  // 附件编辑
    getUploadSign()
    setFileId(id)
    setFilesVisible(true)
    setFileList([])
  }

  const onToupload = () => {
    getUploadSign()
    setFileId(undefined)
    setFilesVisible(true)
    setFileList([])
  }

  // 凭证修改上传 
  const uploadProofFiles = upLoadModule({
    fileList: proofList,
    setFileList: setProofList,
    draggerRef: proofRef,
    accept: '.xls, .xlsx, .png, .jpg, .jpeg, .pdf, .zip',
    allowSizeType: 2,
    limitNum: 20
  })

  const onProofFiles = () => {  // 凭证修改
    setProofVisible(true)
    setProofList([])
  }

  // 凭证上传 
  const onProofOk = () => {
    if (proofList.length == '' || proofList.length < 0) {
      return message.info('请上传附件')
    }
    let uploadproofList = []
    for (let i = 0, k = proofList.length; i < k; i++) {
      if (proofList[i].status !== 'removed') {
        uploadproofList.push({
          url: proofList[i].url,
          name: proofList[i].name
        })
      }
    }
    //修改凭证附件
    api.getUnRefundEditCertificate({
      id: refundOrderInfo.id,
      certificate: uploadproofList
    }).then(data => {
      getDetail()
    })
    setProofVisible(false)
  }

  // 下载资料
  const onDownload = () => {
    setonDownloadDis(true)
    const { relationName, applyStaffId, applyStaffName, relationType, relationId } = detailData
    api.getHost({}).then(res => {
      downloadFile([
        `${res.url}/api/fortune/fund/DownloadDetails?type=dcpaymentdetail&customName=${relationName}&userId=${applyStaffId}&staffName=${applyStaffName}&customType=${relationType}&customId=${relationId}`,
        `${res.url}/api/fortune/fund/DownloadDetails?type=dcpaymentsummary&customName=${relationName}&userId=${applyStaffId}&staffName=${applyStaffName}&customType=${relationType}&customId=${relationId}`,
        `${res.url}/api/fortune/fund/DownloadDetails?type=chanpaymentdetail&customName=${relationName}&userId=${applyStaffId}&staffName=${applyStaffName}&customType=${relationType}&customId=${relationId}`,
        `${res.url}/api/fortune/fund/DownloadDetails?type=chanpaymentsummary&customName=${relationName}&userId=${applyStaffId}&staffName=${applyStaffName}&customType=${relationType}&customId=${relationId}`,
        `${res.url}/api/fortune/fund/DownloadDetails?type=chanpaymentdetail2&customName=${relationName}&userId=${applyStaffId}&staffName=${applyStaffName}&customType=${relationType}&customId=${relationId}`,
        `${res.url}/api/fortune/fund/DownloadDetails?type=chanpaymentsummary2&customName=${relationName}&userId=${applyStaffId}&staffName=${applyStaffName}&customType=${relationType}&customId=${relationId}`,
        `${res.url}/api/fortune/fund/DownloadDetails?type=dcpaymentdetail2&customName=${relationName}&userId=${applyStaffId}&staffName=${applyStaffName}&customType=${relationType}&customId=${relationId}`,
        `${res.url}/api/fortune/fund/DownloadDetails?type=dcpaymentsummary2&customName=${relationName}&userId=${applyStaffId}&staffName=${applyStaffName}&customType=${relationType}&customId=${relationId}`,
        `${res.url}/api/fortune/fund/DownloadDetails?type=datacenter&customName=${relationName}&userId=${applyStaffId}&staffName=${applyStaffName}&customType=${relationType}&customId=${relationId}`,
        `${res.url}/api/fortune/fund/DownloadDetails?type=channelcenter&customName=${relationName}&userId=${applyStaffId}&staffName=${applyStaffName}&customType=${relationType}&customId=${relationId}`,
      ])
    })
    setTimeout(() => {
      setonDownloadDis(false)
    }, 10000)
  }

  //分配
  const onAllocationRole = () => {
    setDistributionVisible(true)
    setDisStaffName('')
    api.getStaffListWithRoleName({ limit: global.paramsLimit, name: '退款审批_数据部' }).then(res => {
      // console.log(res)
      setDistStaffList(res.list)
    })
  }

  //分配确认
  const oprateHandleOkDist = () => {
    if (disStaffName === '') return message.warning('请选择审批人')
    setReviewLoading(true)
    api.setRefundNextPerson({ id: detailDataId, staffId: disStaffName }).then(() => {
      message.info('分配成功')
      setReviewLoading(false)
      getDetail()
      setDisStaffName('')
      setDistributionVisible(false)
    }).catch(() => {
      setReviewLoading(false)
    })
  }

  //补充资料重提
  const onAllowEdit = () => {
    if (+detailData.refundType === 1) {
      let editId = detailData.id
      let userAuth = detailData.userAuth
      let allowReplenish = userAuth.allowReplenish ? 1 : 0
      history.push('/fund/refundManage/editNot?id='+editId+'&allowReplenish='+allowReplenish)
    } else {
      let editId = detailData.id
      let userAuth = detailData.userAuth
      let allowReplenish = userAuth.allowReplenish ? 1 : 0
      history.push('/fund/refundManage/edit?id='+editId+'&allowReplenish='+allowReplenish)
    }
  }

  return (
    <Spin spinning={infoLoading}>
      <div className="pro-detail">
        {
          search.new && search.new == 1 && <Button onClick={onGoBack} type="primary" style={{
            position: 'absolute',
            right: '20px',
            top: '12px'
          }}>返回上一页</Button>
        }
        <Descriptions title={`退款详情（${detailData.refundType === 0 ? '正常退款' : '未认款退款'}）`} layout="horizontal " className="pro-detail-title">
          <dItem label={detailData.relationType ? "QID" : "OAID"}>
            <>
              {detailData.relationId}
            </>
          </dItem>
          <dItem label="客户类型">
            <>
              {detailData.relationTypeCn}
            </>
          </dItem>
          <dItem label={detailData.relationType ? "代理商名称" : "客户名称"}>
            <>
              {detailData.relationName}
            </>
          </dItem>
          <dItem label="真实性验证号码类型">
            <>
              {detailData.verifyTypeCn}
            </>
          </dItem>
          <dItem label="真实性验证号码">
            <>
              {detailData.verifyNumber}
            </>
          </dItem>
        </Descriptions>
        <div className="line-box"></div>
        {/* 0已申请=》 审批中   1已通过=》带退款   3已完成=》 已退款 */}
        <Descriptions title={<>
          <span style={{ fontSize: '14px' }}>当前状态</span>
          <Button type="primary" style={{ marginLeft: 10, marginTop: 10 }}>{detailData.refundStatusCn}</Button>
        </>} className="top-detail" column={1}>
          {
            detailData.refundStatus == 5 ? //终止付款
              <>
                <div>终止原因：{refundOrderInfo.failedReason}</div>
                <div>操作人：{refundOrderInfo.operateName}</div>
              </> : null
          }
          {/* {
            detailData.refundStatus == 2 ? // 已驳回
              <>
                <div>审批意见：{logConList.length ? logConList[logConList.length - 1].chargeMsg : null}</div>
                <div>操作人：{logConList.length ? logConList[logConList.length - 1].userInfo.staffName : null}</div>
              </> : null
          } */}
          {
            detailData.refundStatus == 3 ?  // 已退款
              <>
                <div>付款凭证：{
                  Array.isArray(proof) && proof.length >= 1 ? proof.map((item, i) => {
                    return <span style={{ marginRight: 18 }} key={i}><a href={item.url} target="_blank" rel="noopener noreferrer">{item.name}</a></span>
                  }) : <span style={{ marginRight: 26 }}></span>
                } {+refundOrderInfo.canEdit === 1 && <a onClick={() => onProofFiles()}>修改</a>}</div>
                <div>操作人：{refundOrderInfo.operateName}</div>
              </> : null
          }
        </Descriptions>
        <div className="line-box"></div>
        <Descriptions 
            title={<>当前审批进度&nbsp;&nbsp;{buttonAuthList.urge && <Button type='primary' size="small" onClick={() => onUrge('urgeFundMoneyRefund', buttonAuthList.businessNo)}>催一催</Button>}</>} 
            className="pro-detail-smailtitle">
        </Descriptions>
        <div className="promotionProcess" >
          <Steps size="small">
            {
              statusList.map((item, index) => {
                let status = 'wait'
                if (item.time && item.time.length > 1) {
                  status = 'process'
                }
                return <Step status={status}
                  key={index}
                  value={item.time}
                  title={item.nodeName}
                  description={
                    <>
                      {item.persons && <div title={`${item.persons.map((item) => item)}`}>{`${item.persons.map((item) => item)}`}</div>}
                      <div title={item.statusName}>{item.statusName}</div>
                      <div title={item.time}>{item.time}</div>
                    </>
                  }
                />
              })
            }
          </Steps>
        </div>
        <div className="line-box"></div>
        { // 正常退款展示
          detailData.refundType === 0 ?
            <>
              <Descriptions title="" className="pro-detail-smailtitle">
                <dItem label={<> <span>未收款发票</span></>}>
                  <Tooltip title={<>这里显示的是该客户所有未收款的发票记录，没有则不显示</>} placement="right">
                    <Icon type="info-circle" theme="twoTone" />
                  </Tooltip>
                </dItem>
              </Descriptions>
              <h4>易企信：</h4>
              <Table
                size="small"
                dataSource={invoiceList}
                rowKey="id"
                pagination={{
                  pageSize,
                  total: count,
                  current: currentPage,
                  showQuickJumper: true,
                  showSizeChanger: true,
                  pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
                }}
                scroll={{ x: 1600 }}
                onChange={onChangeTable1}
                style={{ marginBottom: 10 }}
              >
                <Column title="序号" dataIndex="id" />
                <Column title="业务编号" dataIndex="invoiceNo" />
                <Column title="客户/代理商名称" dataIndex="customerName" />
                <Column title="客户类型" dataIndex="clientSource" />
                <Column title="主体" dataIndex="companyName" />
                <Column title="金额" dataIndex="money" />
                <Column title="发票类型" dataIndex="invtypeName" />
                <Column title="发票号" dataIndex="invoiceNumber" />
                <Column title="状态" dataIndex="collestatusName" />
                <Column title="最后处理时间" dataIndex="updateTime" />
                <Column title="负责人" dataIndex="applyName" />
              </Table>
              <h4>老OA:</h4>
              <Table
                size="small"
                dataSource={invoiceListOa}
                rowKey="apie_id"
                pagination={{
                  pageSize: pageSizeOa,
                  total: countOa,
                  current: currentPageOa,
                  showQuickJumper: true,
                  showSizeChanger: true,
                  pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
                }}
                scroll={{ x: 1200 }}
                onChange={onChangeTableOa}
                style={{ marginBottom: 14 }}
              >
                <Column title="序号" dataIndex="apie_id" />
                <Column title="业务编号" dataIndex="apie_invno" />
                <Column title="OA客户/渠道名称" dataIndex="apie_clientname" />
                <Column title="金额" dataIndex="apie_money" />
                <Column title="发票类型" dataIndex="apie_invtype_cn" />
                <Column title="发票号" dataIndex="apie_invnumber" />
                <Column title="状态" dataIndex="apie_collestatus_cn" />
                <Column title="负责人" dataIndex="apie_stfname" />
              </Table>

              <div className="line-box"></div>
              <Descriptions title="关联收款信息" className="pro-detail-smailtitle"></Descriptions>
              <h4>易企信：</h4>
              <Table
                size="small"
                dataSource={accountDetailList}
                rowKey="id"
                pagination={{
                  pageSize: pageSize2,
                  total: count2,
                  current: currentPage2,
                  showQuickJumper: true,
                  showSizeChanger: true,
                  pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
                }}
                scroll={{ x: 1700 }}
                onChange={onChangeTable2}
                style={{ marginBottom: 10 }}
              >
                <Column title="序号" dataIndex="id" fixed="left" />
                <Column title="OAID/QID" dataIndex="relationId" />
                <Column title="客户类型" dataIndex="relationTypeCn" />
                <Column title="客户/代理商名称" dataIndex="relationName" />
                <Column title="业务编号" dataIndex="businessNo" />
                <Column title="业务类型" dataIndex="businessTypeCn" />
                <Column title="主体" dataIndex="companyName" />
                <Column title="收入" dataIndex="income" />
                <Column title="可退转额度" dataIndex="remainMoney" />
                <Column title="发票额度支出" dataIndex="cost" />
                <Column title="发票余额" dataIndex="invoice" />
                <Column title="退款金额" dataIndex="refundAmount" render={(text, record) =>
                  <>{(+record.refundAmount).toFixed(2)}</>
                } />
                <Column title="备注" dataIndex="remark" />
                <Column title="申请人" dataIndex="operateStaffName" />
                <Column title="最后处理时间" dataIndex="updateTime" />
              </Table>
              <h4>老OA：</h4>
              <Table
                size="small"
                dataSource={oaRechargeList}
                rowKey="achrp_id"
                pagination={{
                  pageSize: pageSizeOa2,
                  total: countOa2,
                  current: currentPageOa2,
                  showQuickJumper: true,
                  showSizeChanger: true,
                  pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
                }}
                scroll={{ x: 1700 }}
                onChange={onTableOaRecharge}
                style={{ marginBottom: 14 }}
              >
                <Column title="记录ID" dataIndex="achrp_id" fixed="left" />
                <Column title="OA客户/渠道名称" dataIndex="achrp_clientname" render={(text, record) => (
                  <>
                    {record.achrp_channelname ? record.achrp_channelname : record.achrp_clientname}
                  </>
                )} />
                <Column title="OAID" dataIndex="achrp_clientid" render={(text, record) => (
                  <>
                    {record.achrp_channelid ? record.achrp_channelid : record.achrp_clientid}
                  </>
                )} />
                <Column title="账户" dataIndex="achrp_clatname" />
                <Column title="类型" dataIndex="achrp_type" />
                <Column title="业务编号" dataIndex="achrp_no" />
                <Column title="业务ID" dataIndex="achrp_oid" />
                <Column title="收款" dataIndex="achrp_cash" />
                <Column title="发票额度支出" dataIndex="achrp_cost" />
                <Column title="发票余额" dataIndex="achrp_invoice" />
                <Column title="退款金额" dataIndex="refundAmount" render={(text, record) =>
                  <>{(+record.refundAmount).toFixed(2)}</>
                } />
                <Column title="备注" dataIndex="achrp_remark" />
                <Column title="操作人" dataIndex="achrp_apyname" />
              </Table>
              {/* {
                +detailData.money > +moneyVal ?
                  <div style={{ fontWeight: 700, color: 'red', marginBottom: 8 }}>注意：勾选发票额度小于申请的退款额度</div> : null
              } */}
            </> : null
        }
        {
          // 未认款
          detailData.refundType === 1 ?
            <>
              <Descriptions title="到款信息" className="pro-detail-smailtitle"></Descriptions>
              <Table
                size="small"
                dataSource={moneybackList}
                rowKey={(record, i) => i}
                pagination={false}
                // scroll={{ x: 1400 }}
                style={{ marginBottom: 14 }}
              >
                <Column title="到款Id" dataIndex="moneyBackId" />
                <Column title="到款方式" dataIndex="payWayName" />
                <Column title="到款银行" dataIndex="bank" />
                <Column title="到款日期" dataIndex="date" />
                <Column title="付款方户名" dataIndex="name" />
                <Column title="付款方账号" dataIndex="account" />
                <Column title="金额" dataIndex="rkQuota" />
              </Table>
            </> : null
        }

        <div className="line-box"></div>
        <h4 style={{ marginTop: '10px', marginBottom: '10px', fontSize: '14px' }}>信用订单记录</h4>
        {
          orderList && orderList.length === 0 ? null :
            <div>
              <Icon type="exclamation-circle" theme="filled" style={{ color: "#d4001a", fontSize: 16, marginRight: 4 }} />
              <span style={{ color: '#d4001a' }}>该客户有逾期未还的负利订单</span>
            </div>
        }
        <Table
          size="small"
          dataSource={orderList}
          rowKey="id"
          pagination={false}
          //    scroll={{x: 1400}}
          style={{ marginBottom: 14 }}
        >
          <Column title="订单编号" dataIndex="number" />
          <Column title="项目" dataIndex="projectName" />
          <Column title="产品" dataIndex="productName" />
          <Column title="业务类型" dataIndex="businessName" />
          <Column title="市场价" dataIndex="colmarketprice" />
          <Column title="实收" dataIndex="colactualprice" />
          <Column title="预计收款" dataIndex="colpredictprice" />
          <Column title="提单人" dataIndex="userName" />
          <Column title="收款类型" dataIndex="coltypeCn" />
          <Column title="收款状态" dataIndex="colstatusCn" />
          <Column title="负利到期日" dataIndex="creditDate" />
          <Column title="公司主体" dataIndex="fundCompanyName" />
        </Table>
        <div className="line-box"></div>
        {
          // 正常
          detailData.refundType === 0 ?
            <>
              <Descriptions title="退款信息" className="pro-detail-smailtitle" size="middle">
                <dItem label="退款主体">{detailData.companyName}</dItem>
                <dItem label="退款项目">{extinfo && extinfo.projectName}</dItem>
                <dItem label="退款金额">{detailData.money}</dItem>
                <dItem label=""></dItem>
                <dItem label=""></dItem>
                <dItem label={<span style={{ color: 'red' }}>大写金额</span>}>
                  <span style={{ color: 'red' }}>{moneyChinese}</span>
                </dItem>
                {
                  extInfoFlag ? <>
                    <dItem label="是否扣税点">{extinfo && extinfo.istaxpoint == -1 ? '' : extinfo.istaxpoint == 1 ? '是' : '否'}</dItem>
                  </> : <>
                    <dItem label="是否扣税点"></dItem>
                  </>
                }
                {
                  extInfoFlag ? <>
                    <dItem label="是否扣除服务费">{extinfo && extinfo.isserviceFee == -1 ? '' : extinfo.isserviceFee == 1 ? '是' : '否'}</dItem>
                  </> : <>
                    <dItem label="是否扣除服务费"></dItem>
                  </>
                }
                <dItem label=""></dItem>
                <dItem label="税点">{extinfo && extinfo.taxpoint}</dItem>
                <dItem label="服务费">{extinfo && extinfo.serviceFee}</dItem>
                <dItem label=""></dItem>
                <dItem label="收款单位">{extinfo && extinfo.payeeCompany}</dItem>
                <dItem label="开户行">{extinfo && extinfo.bank}</dItem>
                <dItem label="银行账号">{extinfo && extinfo.bankAccountNo}</dItem>
                <dItem label="地址">{extinfo && extinfo.address}</dItem>
                <dItem label="联系人">{extinfo && extinfo.contacts}</dItem>
                <dItem label="联系电话">{extinfo && extinfo.contactsTel}</dItem>
                <dItem label="退款原因" span={3}>{detailData.reasonTypeCn}</dItem>
                <dItem label="附件" span={4}>{
                  Array.isArray(detailData.attachment) ?
                    detailData.attachment.length > 0 && detailData.attachment.map((v, index) =>
                      <div key={index}>
                        <a href={v.url} target="_blank" rel="noopener noreferrer" style={{ marginRight: 10 }}>{v.name}</a>
                      </div>
                    ) : Array.isArray(JSON.parse(detailData.attachment)) && JSON.parse(detailData.attachment).map((v, index) =>
                      <div key={index}>
                        <a href={v.url} target="_blank" rel="noopener noreferrer" style={{ marginRight: 10 }}>{v.name}</a>
                      </div>
                    )
                }</dItem>
                <dItem label="备注" span={3}> {detailData.remark ? detailData.remark : '无'}</dItem>
              </Descriptions>
              <div className="line-box"></div>
            </> : null
        }
        {
          // 未收款
          detailData.refundType === 1 ?
            <>
              <Descriptions title="退款信息" className="pro-detail-smailtitle" size="middle" column={4}>
                <dItem label="退款主体">{detailData.companyName}</dItem>
                <dItem label="退款金额">{detailData.money}</dItem>
                <dItem label="收款单位">{extinfo && extinfo.payeeCompany}</dItem>
                <dItem label="开户行">{extinfo && extinfo.bank}</dItem>
                <dItem label=""></dItem>
                <dItem span={3} label={<span style={{ color: 'red' }}>大写金额</span>}>
                  <span style={{ color: 'red' }}>{moneyChinese}</span>
                </dItem>
                <dItem label="银行账号">{extinfo && extinfo.bankAccountNo}</dItem>
                <dItem label="地址">{extinfo && extinfo.address}</dItem>
                <dItem label="联系人">{extinfo && extinfo.contacts}</dItem>
                <dItem label="联系电话">{extinfo && extinfo.contactsTel}</dItem>
                <dItem label="退款原因" span={4}>{detailData.reasonTypeCn}</dItem>
                <dItem label="附件" span={4}>{
                  Array.isArray(detailData.attachment) ?
                    detailData.attachment.length > 0 && detailData.attachment.map((v, index) =>
                      <div key={index}>
                        <a href={v.url} target="_blank" rel="noopener noreferrer" style={{ marginRight: 10 }}>{v.name}</a>
                      </div>
                    ) : Array.isArray(JSON.parse(detailData.attachment)) && JSON.parse(detailData.attachment).map((v, index) =>
                      <div key={index}>
                        <a href={v.url} target="_blank" rel="noopener noreferrer" style={{ marginRight: 10 }}>{v.name}</a>
                      </div>
                    )
                }</dItem>
                <dItem label="备注" span={3}> {detailData.remark ? detailData.remark : '无'}</dItem>
              </Descriptions>
              <div className="line-box"></div>
            </> : null
        }
        {
          Array.isArray(detailData.attachmentList) && detailData.attachmentList.length > 0 ?
            <>
              <Descriptions title="补充资料" className="pro-detail-smailtitle" size="middle" column={10}> </Descriptions>
              {
                detailData.attachmentList.map((item, index) => {
                  return <Descriptions layout="horizontal" column={10} key={index} style={{ marginBottom: "4px" }}>
                    <dItem label="上传人" span={2}>{item.stfName}</dItem>
                    <dItem label="上传时间" span={3}>{item.addTime}</dItem>
                    <dItem label="附件" span={4}>{
                      Array.isArray(item.attachment) ?
                        item.attachment.length > 0 && item.attachment.map((v, index) =>
                          <div key={index}>
                            <a href={v.url} target="_blank" rel="noopener noreferrer" style={{ marginRight: 10 }}>{v.name}</a>
                          </div>
                        ) : Array.isArray(JSON.parse(item.attachment)) && JSON.parse(item.attachment).map((v, index) =>
                          <div key={index}>
                            <a href={v.url} target="_blank" rel="noopener noreferrer" style={{ marginRight: 10 }}>{v.name}</a>
                          </div>
                        )
                    }</dItem>
                    <dItem span={1}> {+item.canEdit === 1 && <a onClick={() => onOpenFiles(item.id)}>修改</a>}</dItem>
                  </Descriptions>
                })
              }
              <div className="line-box"></div>
            </>
            : null
        }
        <Descriptions
          title="审批记录"
          layout="horizontal"
          className="pro-detail-smailtitle"
        >
        </Descriptions>
        <Table
          dataSource={logConList}
          rowKey="id"
          pagination={false}
        >
          <Column title="申请人/审批人" dataIndex="userInfo.staffName" />
          <Column title="审批时间" dataIndex="addTime" />
          <Column title="状态" dataIndex="status" />
          <Column title="备注" dataIndex="chargeMsg" />
        </Table>

        <div className="btn-setting">
          <Button hidden={!userAuthList?.allowReplenish} onClick={() => onAllowEdit()}>补充资料重提</Button>
          <Button hidden={!buttonAuthList.replenish} onClick={() => setUpdateVisible(true)} >补充资料</Button>
          <Button hidden={!buttonAuthList.return_up} onClick={debounce(() => { onTurnUp() }, 3000)}>转上级</Button>
          <Button hidden={!buttonAuthList.upload_attachment} onClick={debounce(() => { onToupload() }, 3000)}>上传附件</Button>
          <Button hidden={!buttonAuthList.downloadSzmx} disabled={downloadDis} onClick={() => {onDownload()}}>下载资料</Button>
          <Button hidden={!buttonAuthList.hasChargerPower} onClick={onBtnTurnShow}>驳回</Button>
          {+canDistribution === 1 && <Button onClick={debounce(() => { onAllocationRole() }, 3000)}>分配</Button>}
          <Modal
            title={turnNode()}
            // title="确认驳回"
            visible={turnModalVisible}
            onOk={debounce(() => { onTurnApprove() }, 3000)}
            onCancel={onTurnCancel}
            footer={[
              <Button key="back" onClick={onTurnCancel}>取消</Button>,
              <Button key="submit" type="primary" onClick={debounce(() => { onTurnApprove() }, 3000)}>确定</Button>
            ]}
          >
            <div>
              <span>审批意见:</span>
              <Input.TextArea placeholder="请输入驳回审批意见" value={textAreaVal} onChange={onDescChange} />
            </div>
            <span className="poine-text" hidden={textDisplay}>请填写审批意见</span>
          </Modal>
          {
            buttonAuthList.hasChargerPower && !buttonAuthList.hidePassButton && <Button type="primary" onClick={onBtnPassShow}>通过</Button>
          }
          <Modal
            // title="确认通过"
            title={agreeNode()}
            visible={passModalVisible}
            onOk={debounce(() => { onApprove() }, 3000)}
            onCancel={onPassCancel}
            footer={[
              <Button key="back" onClick={onPassCancel}>取消</Button>,
              <Button key="submit" type="primary" loading={approveLoading} onClick={debounce(() => { onApprove() }, 3000)}>确定</Button>
            ]}
          >
            <div>
              <span>审批意见:</span>
              <Input.TextArea placeholder="请输入通过审批意见" value={pasDesc} onChange={onPassDescChange} />
            </div>
          </Modal>

          {/* 补充资料 */}
          <Modal
            title="补充资料确认"
            visible={updateVisible}
            onOk={debounce(() => { onReplenish() }, 3000)}
            onCancel={() => setUpdateVisible(false)}
            footer={[
              <Button key="back" onClick={() => setUpdateVisible(false)}>取消</Button>,
              <Button key="submit" type="primary" onClick={debounce(() => { onReplenish() }, 3000)}>确定</Button>
            ]}
          >
            <div>
              <span>审批意见:</span>
              <Input.TextArea placeholder="请输入驳回审批意见" value={updateVal} onChange={(e) => setUpdateVal(e.target.value)} />
            </div>
          </Modal>

          {/* 附件 */}
          <Modal
            title={'上传附件'}
            visible={filesVisible}
            onOk={debounce(() => { onFilesOk() }, 3000)}
            onCancel={() => setFilesVisible(false)}
            destroyOnClose={true}
            footer={[
              <Button key="back" onClick={() => setFilesVisible(false)}>取消</Button>,
              <Button key="submit" type="primary" onClick={debounce(() => { onFilesOk() }, 3000)}>确定</Button>
            ]}
          >

            <div className="dragger-box" ref={draggerRef}>
              <Dragger
                {...uploadFiles}
              >
                <p className="ant-upload-drag-icon">
                  <Icon type="inbox" />
                </p>
                <p className="ant-upload-text">点击或将文件拖拽到这里上传</p>
                <p className="ant-upload-hint">
                  附件支持格式：xls、xlsx、png、jpg、pdf、zip 单个附件大小不超过200M，不超过5个附件。
                </p>
              </Dragger>
            </div>
          </Modal>
          <Modal
            title="分配"
            visible={distributionVisible}
            onOk={debounce(() => { oprateHandleOkDist() }, 3000)}
            onCancel={() => setDistributionVisible(false)}
            destroyOnClose={true}
            footer={[
              <Button key="back" onClick={() => setDistributionVisible(false)}>取消</Button>,
              <Button key="submit" type="primary" loading={reviewLoading} onClick={debounce(() => { oprateHandleOkDist() }, 3000)}>确定</Button>
            ]}
          >
            <div className="officework-modal-content">
              <span style={{ display: 'inline-block', width: '15%', height: '30px', lineHeight: '30px' }}><span style={{ color: 'red' }}>*</span> 审批人</span>
              <Select
                placeholder="请选择"
                style={{ width: '80%' }}
                onChange={val => setDisStaffName(val)}
              >
                {
                  distStaffList && distStaffList.map((v, i) => {
                    return <Option value={v.id} key={v.id}>{v.staffName}</Option>
                  })
                }
              </Select>
            </div>
          </Modal>
          <Modal
            title={'修改'}
            visible={proofVisible}
            onOk={debounce(() => { onProofOk() }, 3000)}
            onCancel={() => setProofVisible(false)}
            destroyOnClose={true}
            footer={[
              <Button key="back" onClick={() => setProofVisible(false)}>取消</Button>,
              <Button key="submit" type="primary" onClick={debounce(() => { onProofOk() }, 3000)}>确定</Button>
            ]}
          >

            <div className="dragger-box" ref={proofRef}>
              <Dragger
                {...uploadProofFiles}
              >
                <p className="ant-upload-drag-icon">
                  <Icon type="inbox" />
                </p>
                <p className="ant-upload-text">点击或将文件拖拽到这里上传</p>
                <p className="ant-upload-hint">
                  附件支持格式：xls、xlsx、png、jpg、pdf、zip 单个附件大小不超过5M，不超过20个附件。
                </p>
              </Dragger>
            </div>
          </Modal>
        </div>
      </div>
    </Spin>
  )
}

export default NewDetails;