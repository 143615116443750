/**
 * 模块名称: 系统配置管理模块
 * @author zhuyan@372163.com
 */

import React from 'react'
import { Switch, Route } from 'react-router-dom'
import MailTemplate from './MailTemplate'
import SmsTemplate from './SmsTemplate'
import ThirdParty from './ThirdParty'
import SystemBulletin from './SystemBulletin'
import SystemSetup from './SystemSetup'
import RecycleBin from './RecycleBin'
import HelpCenterMgt from './HelpCenterMgt'
import MenuControl from './MenuControl'
import './assets/style.scss'

const SetSystemIndex = ({ match, history }) => {

  return (
    <div>
      <Switch>
        <Route path={match.path + '/mailTemplate'} component={MailTemplate} />
        <Route path={match.path + '/smsTemplate'} component={SmsTemplate} />
        <Route path={match.path + '/thirdParty'} component={ThirdParty} />
        <Route path={match.path + '/systemBulletin'} component={SystemBulletin} />
        <Route path={match.path + '/systemSetup'} component={SystemSetup} /> 
        <Route path={match.path + '/recycleBin'} component={RecycleBin} />
        <Route path={match.path + '/helpCenterMgt'} component={HelpCenterMgt} />  
        <Route path={match.path + '/menuControl'} component={MenuControl} />    
        <Route component={MailTemplate} />
      </Switch>
    </div>
  )
}

export default SetSystemIndex