/**
 * 模块名称: 系统公告管理
 * @author zhuyan@372163.com
 */
import React, { useState, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import api from '@/api'
import {
  Table,
  Drawer,
  Form,
  Alert,
  Button,
  Input,
  Select,
  message,
  Icon,
  Popconfirm,
  Spin,
  DatePicker,
  Upload,
  Tooltip
} from 'antd'
import BtnGroup from '@/components/BtnGroup'
import CorpFilter from '@/components/CorpFilter'
import { parseSearch } from '@/utils'
import { upLoadModule } from '@/utils/common'
import { Link } from 'react-router-dom'
import Auth from '@/components/AuthMiddleware'
import Editor from 'wangeditor'
import CryptoJS from 'crypto-js'

const { Dragger } = Upload;
const { Column } = Table
const FormItem = Form.Item
const Option = Select.Option
const { RangePicker } = DatePicker
let editId = '' //编辑使用
let pageSize = 10
let currentPage = 1

let boardData = {
  title: '',
  companyIds: '',
  createById: '',
  createBy: '',
  startTime: '',
  endTime: '',
}

const Department = (props) => {
  const { match, history } = props
  const { getFieldDecorator, validateFields, resetFields, setFieldsValue } = props.form
  // const { getOfficeTemplateGetList, editRowId, setAddModuleLoading } = props
  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])
  const [count, setCount] = useState(0)
  const [visible, setVisible] = useState(false)
  const [saveLoading, setSaveLoading] = useState(false)
  const [formLoading, setformLoading] = useState(false)
  const [choice, setChoice] = useState([])
  const [startTime, setStartTime] = useState('')
  const [endTime, setEndTime] = useState('')
  const [timeValue, setTimeValue] = useState([])
  const [fileList, setFileList] = useState([])
  const [authList, setAuthList] = useState([])

  // const [name, setName] = useState('')
  // const [auditList, setAuditList] = useState([])
  // const [copyToList, setCopyToList] = useState([])
  // const [audit, setAudit] = useState(undefined)
  // const [copyTo, setCopyTo] = useState(undefined)

  const [content, setContent] = useState('')//eslint-disable-line
  const [editorHtml, setEditorHtml] = useState('')//eslint-disable-line
  const [editorText, setEditorText] = useState('')//eslint-disable-line
  const contentRef = useRef(null)
  const draggerRef = useRef(null)
  const search = parseSearch(props.location.search)

  useEffect(() => {
    currentPage = search.page ? +search.page : 1
    pageSize = search.limit ? +search.limit : 10
    boardData = {
      title: '',
      companyIds: '',
      createById: '',
      createBy: '',
      startTime: '',
      endTime: '',
    }
    getSysNoticeIndex()
    api.getPageAuth().then(list => setAuthList(list))
  }, [])


  //富文本
  const initEditor = () => {
    let editor = new Editor(ReactDOM.findDOMNode(contentRef.current))
    dealEditor(editor)
    editor.create()
  }
  //富文本数据配置
  const dealEditor = (editor) => {
    editor.customConfig.showLinkImg = false
    editor.customConfig.uploadImgServer = '/api/File/upload'
    editor.customConfig.uploadFileName = 'file'
    editor.customConfig.uploadImgHeaders = {
      token: localStorage.getItem('token')
    }
    // 自定义菜单配置
    editor.customConfig.menus = [
      'head',  // 标题
      'bold',  // 粗体
      'fontSize',  // 字号
      'fontName',  // 字体
      'italic',  // 斜体
      'underline',  // 下划线
      'strikeThrough',  // 删除线
      'foreColor',  // 文字颜色
      'backColor',  // 背景颜色
      'link',  // 插入链接
      'list',  // 列表
      'justify',  // 对齐方式
      'quote',  // 引用
      'image',  // 插入图片
      'table',  // 表格
      'code',  // 插入代码
      'undo',  // 撤销
      'redo'  // 重复
    ]
    //插入视频 : 'video'
    editor.customConfig.onchange = (html) => {
      //console.log(html)
      setEditorHtml(html)
      setEditorText(editor.txt.text())
      setFieldsValue({
        content: html
      })
    }
    editor.customConfig.uploadImgHooks = {
      before(xhr, editor, files) {
        //console.log(xhr, editor, files)
      },
      success(xhr, editor, result) {
        // console.log(xhr, editor, result)
      },
      fail(xhr, editor, result) {
        // console.log(xhr, editor, result)
      },
      error(xhr, editor, result) {
        // console.log(xhr, editor, result)
      },
      timeout(xhr, editor) {
        // console.log(xhr, editor)
      },
      customInsert(insertImg, result, editor) {
        //console.log(insertImg, result, editor)
        var url = result.data.full
        insertImg(url)
      }
    }
  }

  const getSysNoticeIndex = () => {
    setLoading(true)
    api.getSysNoticeIndex({
      title: boardData.title,
      companyIds: boardData.companyIds,
      createById: boardData.createById,
      createBy: boardData.createBy,
      startTime: boardData.startTime,
      endTime: boardData.endTime,
      limit: pageSize,
      page: currentPage
    }).then(data => {
      setList(data.list)
      setCount(data.count)
      setLoading(false)
    }).catch(() => setLoading(false))
  }

  // 发布公告
  const formSubmit = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      let uploadList = []
      for (let i = 0, k = fileList.length; i < k; i++) {
        if (fileList[i].status !== 'removed') {
          uploadList.push(fileList[i].url)
        }
      }
      if (!err) {
        if (editId) {
          vals.id = editId
        }
        setSaveLoading(true)
        api.setSysNoticeRelease({
          id: vals.id,
          title: vals.title,
          content: vals.content,
          companyIds: vals.companyIds,
          accessories: uploadList
        })
          .then(() => {
            setSaveLoading(false)
            setVisible(false)
            message.success('添加成功')
            getSysNoticeIndex()
          })
          .catch(() => setSaveLoading(false))
      }
    })
  }

  const onEdit = (data) => {
    resetFields()
    if (data.accessories) {
      data.accessories.forEach((item, index) => {
        fileList.push({
          status: 'done',
          uid: index,
          name: item.name,
          url: item.url
        })
      })
      setFileList(data.accessories.map((item, i) => ({ status: 'done', uid: i, name: item.name, url: item.url })))
    } else {
      setFileList([])
    }
    setVisible(true)
    editId = data.id
    getGrop().then(() => {
      setFieldsValue({
        title: data.title,
        content: data.content,
        companyIds: data.companyIds
      })
      setTimeout(() => {
        let editor = new Editor(ReactDOM.findDOMNode(contentRef.current))
        dealEditor(editor)
        editor.create()
        editor.txt.html(data.content)
      }, 50)
    })

  }

  // 推送范围
  const getGrop = () => {
    return api.getSelectCompanyList({
      limit: global.paramsLimit,
    })
      .then(data => {
        setChoice(data.list)
        return data.list
      }).catch(() => setformLoading(false))
  }

  // 发布公告
  const onAddCorp = () => {
    editId = ''
    setVisible(true)
    setFileList([])
    resetFields()
    getGrop()
    setTimeout(initEditor, 100)
  }

  const onChangeTable = (pagination, filters, sorter) => {
    currentPage = pagination.current
    pageSize = pagination.pageSize
    history.replace(match.path + '?page=' + currentPage + '&limit=' + pageSize)
    getSysNoticeIndex()
  }

  const onChangeBulleTin = (data) => {
    boardData.companyIds = data.map(item => item.id).join(',')
    history.replace(match.path)
    currentPage = 1
    getSysNoticeIndex()
  }

  const onChangeTime = (e) => {
    setStartTime(e[0].format('YYYY-MM-DD'))
    setEndTime(e[1].format('YYYY-MM-DD'))
    setTimeValue(e)
  }

  const departitle = useRef(null)
  const deparcreateBy = useRef(null)

  const onSearch = () => {
    boardData.title = departitle.current.value
    boardData.createBy = deparcreateBy.current.value
    boardData.startTime = startTime
    boardData.endTime = endTime
    history.replace(match.path)
    currentPage = 1
    getSysNoticeIndex()
  }

  const onKeyup = (e) => {
    if (e.keyCode === 13) {
      onSearch()
    }
  }

  const onReset = () => {
    boardData.title = ''
    departitle.current.value = ''
    boardData.createBy = ''
    deparcreateBy.current.value = ''
    boardData.startTime = ''
    boardData.endTime = ''
    setStartTime('')
    setEndTime('')
    setTimeValue([])
    currentPage = 1
    history.replace(match.path)
    getSysNoticeIndex()
  }

  // 删除
  const onDelete = (id) => {
    api.delSysNoticeDel({ id }, true).then(() => {
      message.success('删除成功')
      getSysNoticeIndex()
    })
  }

  // const onUserinfo = (record) => {
  //   api.getSysNoticeDetail({ id: record.id }).then(data => {
  //     sessionStorage.setItem("key", JSON.stringify(data))
  //     history.push('/setSystem/BulletinDetail')
  //   })
  // }

  // 文件上传 
	const uploadFiles = upLoadModule({
		fileList: fileList,
		setFileList: setFileList,
		draggerRef: draggerRef,
		accept: '.gif, .png, .jpg, .jepg, .PDF, .doc, .docx, .csv, .xlsx, .zip',
		allowSizeType: 4
	})

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 3 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 21 },
    }
  }

  return (
    <>
      <CorpFilter onChange={onChangeBulleTin} />
      <div className="bulle-wrap">
        <h4 className="bulle-text">其它选项:</h4>
        <div className="filter-item">
          <label htmlFor="">标题：</label>
          <input className="ant-input" placeholder="请输入标题" ref={departitle} type="title" onKeyUp={onKeyup} />
        </div>
        <div className="filter-item">
          <label htmlFor="">创建人：</label>
          <input className="ant-input" placeholder="请输入创建人" ref={deparcreateBy} type="createBy" onKeyUp={onKeyup} />
        </div>
        <div className="filter-item">
          <label htmlFor="">创建时间：</label>
          <RangePicker allowClear={false} type="crateTime" onChange={onChangeTime} value={timeValue} />
        </div>
        <div className="bulle-wrap-btn">
          <BtnGroup cancelName="重置" confirmName="筛选" onConfirm={onSearch} onCancel={onReset} />
        </div>
      </div>
      <div className="line"></div>
      <div className="bulle-wrap-add">
        <Auth auths={authList} code="operate">
          <Button type="primary" icon="plus" onClick={onAddCorp}>发布公告</Button>
        </Auth>
      </div>
      <Alert className="corp-count" message={`总计：${count}条数据`} type="info" showIcon />
      <Table
        dataSource={list}
        rowKey="id"
        loading={loading}
        pagination={{
          pageSize,
          total: count,
          current: currentPage,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
        }}
        // scroll={{x: 1400}}
        onChange={onChangeTable}
      >
        <Column title="标题"
          dataIndex="title"
          ellipsis={true}
          width="30%"
          key="setTitle"
          // render={(companyName, record) => (<a onClick={() => onUserinfo(record)} style={{ cursor: 'pointer' }}>{companyName}</a>)}
          render={(companyName, record) => <Link to={`${match.path}/detail?id=${record.id}&time=${+new Date()}&sign=${CryptoJS.MD5(record.id+'ad629fddf8b8756d2e72e96ae035a5a4'+(+new Date())).toString()}`}>{companyName}</Link>}
        />
        <Column title="推送范围" dataIndex="companyName" />
        <Column title="创建人" dataIndex="createBy" />
        <Column title="创建日期" dataIndex="crateTime" />
        <Column title="操作" key="set" fixed="right" width={100} render={(text, record) => (
          <>
            <Auth auths={text.operateAuth} code="operate">
              <Tooltip title="编辑" placement="bottom">
                <Icon type="edit" style={{ color: '#1890ff' }} className="operate-icon" onClick={() => onEdit(record)} />
              </Tooltip>
            </Auth>
            {' '}
            <Auth auths={text.operateAuth} code="operate">
              <Popconfirm title="确认删除吗？" cancelText="取消" okText="确认" onConfirm={() => onDelete(record.id)}>
                <Tooltip title="删除" placement="bottom">
                  <Icon className="operate-icon" style={{ color: 'red' }} type="delete" />
                </Tooltip>
              </Popconfirm>
            </Auth>
          </>
        )} />
      </Table>
      <Drawer
        title="发布公告"
        width={950}
        onClose={() => setVisible(false)}
        visible={visible}
        destroyOnClose={true}
      >
        <Spin spinning={formLoading}>
          <Form onSubmit={formSubmit} {...formItemLayout}>
            <FormItem label="公告标题">
              {getFieldDecorator('title', {
                rules: [{ required: true, message: '请输入公告标题' }]
              })(
                <Input placeholder="请输入公告标题" maxLength={50} />
              )}
            </FormItem>
            {/* <FormItem label="内容">
              {getFieldDecorator('content', {
                rules: [{ required: true, message: '请输入内容' }]
              })(
                <Input.TextArea placeholder="请输入内容" />
              )}
            </FormItem> */}
            <FormItem label="内容">
              {getFieldDecorator('content', {
                initialValue: content,
                rules: [{ required: true, message: '请输入内容' }]
              })(<div className="wangEditor-container" ref={contentRef}></div>)}
            </FormItem>
            <FormItem label="附件">
              <div className="dragger-box" ref={draggerRef}>
                <Dragger
                  {...uploadFiles}
                >
                  <p className="ant-upload-drag-icon">
                    <Icon type="inbox" />
                  </p>
                  <p className="ant-upload-text">点击或将文件拖拽到这里上传</p>
                  <p className="ant-upload-hint">
                    支持扩展名：PDF、doc、docx、csv、.xlsx、JPG 、ZIP、JEPG、GIF、PNG  单个文件大小不超过15M，不超过5个附件。
                  </p>
                </Dragger>
              </div>
            </FormItem>
            <FormItem label="推送范围">
              {getFieldDecorator('companyIds', {
                rules: [{ required: true, message: '请选推送范围' }]
              })(
                <Select
                  placeholder="请选择推送范围"
                  mode="multiple"
                  style={{ width: '100%' }}
                >
                  {choice.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                </Select>
              )}
            </FormItem>
            <BtnGroup onCancel={() => setVisible(false)} loading={saveLoading} />
          </Form>
        </Spin>
      </Drawer>
    </>
  )
}

export default Form.create()(Department)