/**
 * 模块名称: 新退款申请页（正常退款）
 * 
 */

import React, { useState, useEffect, useRef } from 'react'
import {
  Table,
  Form,
  Input,
  Select,
  message,
  Checkbox,
  Radio,
  Steps,
  Descriptions,
  Icon,
  Spin,
  Row,
  Col,
  InputNumber,
  Upload
} from 'antd'
import api from '@/api'
import { parseSearch, changeMoneyToChinese } from '@/utils'
import BtnGroup from '@/components/BtnGroup'
import { upLoadModule } from '@/utils/common'

const dItem = Descriptions.item
const { Step } = Steps;
const { Column } = Table
const FormItem = Form.Item
const Option = Select.Option
const { Dragger } = Upload

let pageSize = 10
let currentPage1 = 1
let pageSize2 = 10
let currentPage2 = 1
let pageSizeOa = 10  // 老oa未收款发票
let currentPageOa = 1
let pageSizeOa2 = 10
let currentPageOa2 = 1
let selectedIds = []
let selectedIdsOA = [] // 老oa关联收款
let xuanzhongedu = 0 // 易企信选中额度
let OAxuanzhong = 0 // 老oa选中额度
var ArrObj = {}
var ArrObjOA = {}

const NewAdd = (props) => {
  const { match, location, history } = props
  const { getFieldDecorator, validateFields, resetFields, setFieldsValue, getFieldValue } = props.form

  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [selectedRowKeysOA, setSelectedRowKeysOA] = useState([])
  const search = parseSearch(props.location.search)
  const [refundData, setRefundData] = useState({})
  const [statusList, setStatusList] = useState([])
  const [invoiceList, setInvoiceList] = useState([])
  const [invoiceCount, setInvoiceCount] = useState(0)
  const [invoiceListOa, setInvoiceListOa] = useState([])  // 老oa未收款发票
  const [countOa, setCountOa] = useState(0) // 老oa未收款发票
  const [accountDetailList, setAccountDetailList] = useState([])
  const [accountDetaiCount, setAccountDetailCount] = useState(0)
  const [countOa2, setCountOa2] = useState(0) // 老oa关联收款
  const [claimList, setClaimList] = useState([]) // 易企信发票额度
  const [claimListOA, setClaimListOA] = useState([]) // 易企信发票额度
  const [fundAccountList, setFundAccountList] = useState([])
  const [detailDataId, setdetailDataId] = useState(0)
  const [allowReplenish, setAllowReplenish] = useState(0)
  const [accountId, setAccountId] = useState(0)
  const [drawerTitle, setDrawerTitle] = useState('退款申请')
  const [saveLoading, setSaveLoading] = useState(false)
  const [processShow, setProcessShow] = useState(false)
  const [editReasonShow, setEditReasonShow] = useState(false)
  const [keyVal, setKeyVal] = useState({}) // 易企信退款金额
  const [OAkeyVal, setOAKeyVal] = useState({}) // 易企信退款金额
  const [invoiceMin, setinvoiceMin] = useState(null)
  const [detailLoading, setDetailLoading] = useState(false)
  const [selectData, setSelectData] = useState({
    reasonList: [],
    projectList: [],
  })
  const [taxpoint, setTaxpoint] = useState(undefined)
  const [serviceFee, setserviceFee] = useState(undefined)
  const [taxpointFlag, setTaxpointFlag] = useState(true)
  const departInp = useRef(null)
  const departInpOA = useRef(null)
  const draggerRef = useRef(null)
  const [oaRechargeList, setOaRechargeList] = useState([]) // 老oa关联收款列表
  const [loading2, setLoading2] = useState(false)
  const [loadingOa2, setloadingOa2] = useState(false)
  const [moneyChinese, setMoneyChinese] = useState('')
  const [fileList, setFileList] = useState([])
  const [userAuth, setUserAuth] = useState({})

  useEffect(() => {
    OAXuanzEDu()
  }, [claimListOA])

  useEffect(() => {
    changeXuanzEDu()
  }, [claimList])

  useEffect(() => {
    document.documentElement.scrollTop = 0
    pageSize = 10; currentPage1 = 1
    pageSize2 = 10; currentPage2 = 1
    pageSizeOa = 10; currentPageOa = 1
    pageSizeOa2 = 10; currentPageOa2 = 1
    if (search.id > 0) {
      setDetailLoading(true)
      setDrawerTitle('退款驳回重提')
      setProcessShow(true)
      setEditReasonShow(false)
      setAllowReplenish(search.allowReplenish ? search.allowReplenish : 0)
      api.getRefundDetail({ id: search.id }).then(data => {
        if (data.reasonType == "5") {
          setEditReasonShow(true)
        }
        else {
          setEditReasonShow(false)
        }
        setRefundData(data)
        setUserAuth(data.userAuth)
        setdetailDataId(data.id)
        const { projectId, istaxpoint, taxpoint, isserviceFee, serviceFee, payeeCompany, bank, bankAccountNo, address, contacts, contactsTel, } = data.extInfo
        const { reasonType, reason, money, remark, attachment } = data
        getSelectData(data.accountId).then(() => {
          setFieldsValue({
            projectId,
            money,
            istaxpoint: [0, 1].includes(istaxpoint) ? istaxpoint : -1,
            isserviceFee: [0, 1].includes(isserviceFee) ? isserviceFee : -1,
            payeeCompany,
            bank,
            bankAccountNo,
            address,
            contacts,
            contactsTel,
            reasonType,
            reason,
            remark
          })
        })
        setTaxpoint(taxpoint)
        setserviceFee(serviceFee)
        setMoneyChinese(changeMoneyToChinese(money))
        if (attachment.length) {
          attachment.forEach((item, index) => {
            fileList.push({
              status: 'done',
              uid: index,
              name: item.name,
              url: item.url
            })
          })
          setFileList(attachment.map((item, index) => ({ ...item, uid: index })))
        }
        if (+data.extInfo.istaxpoint !== 1) {
          setTaxpointFlag(false)
          setTaxpoint(undefined)
        } else {
          setTaxpointFlag(true)
        }

        let obj = {}
        data.cashKeyIds && data.cashKeyIds.forEach(item => {
          obj[Object.keys(item)[0]] = Object.values(item)[0]
        })
        setKeyVal(obj)

        let objOA = {}
        data.oldcashKeyIds && data.oldcashKeyIds.forEach(item => {
          objOA[Object.keys(item)[0]] = Object.values(item)[0]
        })
        setOAKeyVal(objOA)

        setStatusList(data.procedureStatusList ? data.procedureStatusList : []) // 审批进度
        getRefundInvoiceList(search.id, data.relationType, data.relationId)  // 易企信未收款发票
        getOaUnReceiveInvoice(data.relationId, data.relationType) // 老oa未收款发票

        // 易企信关联收款返显
        api.getRefundAccountDetailList({ relationType: data.relationType, relationId: data.relationId, editFlag: 1, limit: pageSize2, page: currentPage2, invoiceMin: invoiceMin, accountId: data.accountId }).then(datatmp => {
          setClaimList(datatmp.allClaimIdList)
          selectedIds = data.cashIds.split(',').map(item => +item).filter((num) => {
            return num > 0;
          })
          setSelectedRowKeys(selectedIds)
        })
        // 老oa关联收款返显
        api.getOaRecharge({ relationId: data.relationId, relationType: data.relationType, accountId: data.accountId, invoiceMin: invoiceMin, limit: pageSizeOa2, page: currentPageOa2, editFlag: 1 }).then(res => {
          setClaimListOA(res.allClaimIdList)
          selectedIdsOA = data.oldcashIds.split(',').map(item => item).filter((num) => {
            return num;
          })
          setSelectedRowKeysOA(selectedIdsOA)
        })
        api.getFundAccountList({ relationType: data.relationType, customerId: data.relationId, type: 1 }).then(datatmp => {
          setFundAccountList(datatmp.list)
          selectZhuTi1(data.accountId, datatmp.list, data.relationId, data.relationType)
        })
      })
    }
    else {
      setDetailLoading(true)
      setDrawerTitle('退款申请')
      setProcessShow(false)
      setEditReasonShow(false)
      getSelectData()
      // search.relationType = search.relationType ? search.relationType : 0;
      // search.relationId = search.relationId ? search.relationId : 1;
      selectedIds = []
      selectedIdsOA = []
      xuanzhongedu = 0
      OAxuanzhong = 0
      api.getCusTomDetail({ relationType: search.relationType, relationId: search.relationId }).then(data => {
        setRefundData(data)
        setdetailDataId(0)
        setStatusList(data.procedureStatusList ? data.procedureStatusList : []) // 审批进度
        getRefundInvoiceList('', data.relationType, data.relationId) // 易企信未收款发票
        getOaUnReceiveInvoice(data.relationId, data.relationType) // 老oa未收款发票
        getOaRecharge(data.relationId, data.relationType, '', invoiceMin) // 老oa关联收款
        api.getRefundAccountDetailList({ relationType: search.relationType, relationId: search.relationId, editFlag: 1, limit: pageSize2, page: currentPage2, invoiceMin: invoiceMin }).then(datatmp => {
          setAccountDetailList(datatmp.list)
          setAccountDetailCount(datatmp.count)
          setClaimList(datatmp.allClaimIdList)
          setDetailLoading(false)
        })
        api.getFundAccountList({ relationType: data.relationType, customerId: data.relationId, type: 1 }).then(data => {
          setFundAccountList(data.list)
        })
      }).catch(() => {
        setDetailLoading(false)
      })
    }
  }, [])

  // 老oa
  const OAXuanzEDu = () => {
    OAxuanzhong = 0
    for (var i in claimListOA) {
      for (var j in selectedIdsOA) {
        if (claimListOA[i].id + '' == selectedIdsOA[j] + '') {
          OAxuanzhong += parseFloat(claimListOA[i].invoice)
        }
      }
    }
    OAxuanzhong = OAxuanzhong
  }

  const changeXuanzEDu = () => {
    xuanzhongedu = 0
    for (var i in claimList) {
      for (var j in selectedIds) {
        if (claimList[i].id + '' == selectedIds[j] + '') {
          xuanzhongedu += parseFloat(claimList[i].invoice)
        }
      }
    }

    xuanzhongedu = xuanzhongedu
  }

  // 获取数据
  const getSelectData = (accountId) => {
    return Promise.all([
      api.getSelectRefundReasonList({ type: "refund_reason_type" }),  // 退款原因
      api.getTopProjectList({ accountId: accountId ? accountId : '', isOpen: 1, limit: 999999 }) // 项目
    ]).then(list => {
      setSelectData({
        reasonList: list[0],
        projectList: list[1].list
      })
      return list
    })
  }

  // 易企信未收款发票
  const getRefundInvoiceList = (id, relationType, relationId) => {
    api.getRefundInvoiceList({ id, relationType, relationId, page: currentPage1, limit: pageSize }).then(datatmp => {
      setInvoiceList(datatmp.list)
      setInvoiceCount(datatmp.count)
    })
  }

  // 老oa未收款发票
  const getOaUnReceiveInvoice = (relationId, relationType) => {
    api.getOaUnReceiveInvoice({ relationId, relationType, limit: pageSizeOa, page: currentPageOa }).then(res => {
      setCountOa(res.count)
      setInvoiceListOa(res.list)
    })
  }

  // 老oa未收款发票分页
  const onChangeTableOa = (pagination, filters, sorter) => {
    currentPageOa = pagination.current
    pageSizeOa = pagination.pageSize
    getOaUnReceiveInvoice(refundData.relationId + '' ? refundData.relationId : '', refundData.relationType + '' ? refundData.relationType : '')
  }

  // 易企信发票分页
  const onChangeTable1 = (pagination, filters, sorter) => {
    currentPage1 = pagination.current
    pageSize = pagination.pageSize
    getRefundInvoiceList(search.id ? search.id : '', refundData.relationType + '' ? refundData.relationType : '', refundData.relationId + '' ? refundData.relationId : '')
  }

  // 易企信关联收款分页
  const onChangeTable2 = (pagination, filters, sorter) => {
    currentPage2 = pagination.current
    pageSize2 = pagination.pageSize
    setLoading2(true)
    api.getRefundAccountDetailList({ id: search.id ? search.id : '', editFlag: 1, page: currentPage2, limit: pageSize2, invoiceMin: invoiceMin, relationType: refundData.relationType + '' ? refundData.relationType : '', relationId: refundData.relationId + '' ? refundData.relationId : '', accountId: accountId }).then(datatmp => {
      setLoading2(false)
      setAccountDetailList(datatmp.list)
      setAccountDetailCount(datatmp.count)
      setClaimList(datatmp.allClaimIdList)
    })
  }

  // 老oa关联收款接口
  const getOaRecharge = (relationId, relationType, accountId, invoiceMin) => {
    api.getOaRecharge({ relationId, relationType, accountId, invoiceMin, limit: pageSizeOa2, page: currentPageOa2, editFlag: 1 }).then(res => {
      setloadingOa2(false)
      setCountOa2(res.count)
      setOaRechargeList(res.list)
      setClaimListOA(res.allClaimIdList)
    })
  }

  // 老oa关联的收款分页
  const onTableOaRecharge = (pagination, filters, sorter) => {
    currentPageOa2 = pagination.current
    pageSizeOa2 = pagination.pageSize
    setloadingOa2(true)
    getOaRecharge(refundData.relationId + '' ? refundData.relationId : '', refundData.relationType + '' ? refundData.relationType : '', accountId, invoiceMin)
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
      // console.log('keyVal=>', keyVal)
      selectedIds = selectedRowKeys
      setSelectedRowKeys(selectedIds)
      changeXuanzEDu()
    },
    getCheckboxProps: record => ({
      disabled: accountId === 0 ? true : false
    }),
    onSelect: (record, selected, selectedRows, nativeEvent) => {
      if (!selected) {
        let vals = Object.keys(keyVal), len = Object.keys(keyVal).length
        for (let i = 0; i < len; i++) {
          if (vals[i] == record.id) {
            delete keyVal[vals[i]]
            delete ArrObj[record.id]
          }
        }
        setKeyVal(keyVal)
      }
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      if (!selected) {
        let vals = Object.keys(keyVal)
        changeRows.forEach(item => {
          vals.forEach((j, i )=> {
            if (item.id == j) {
              delete keyVal[vals[i]]
              delete ArrObj[item.id]
            }
          })
        })
        setKeyVal(keyVal)
      }
    },
    selectedRowKeys
  }

  // 老oa
  const oArowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      selectedIdsOA = selectedRowKeys
      setSelectedRowKeysOA(selectedIdsOA)
      OAXuanzEDu()
    },
    getCheckboxProps: record => ({
      disabled: accountId === 0 ? true : false
    }),
    onSelect: (record, selected, selectedRows, nativeEvent) => {
      if (!selected) {
        let vals = Object.keys(OAkeyVal), len = Object.keys(OAkeyVal).length
        for (let i = 0; i < len; i++) {
          if (vals[i] == record.achrp_id) {
            delete OAkeyVal[vals[i]]
            delete ArrObjOA[record.achrp_id]
          }
        }
        setOAKeyVal(OAkeyVal)
      }
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      if (!selected) {
        let vals = Object.keys(OAkeyVal)
        changeRows.forEach(item => {
          vals.forEach((j, i )=> {
            if (item.achrp_id == j) {
              delete OAkeyVal[vals[i]]
              delete ArrObjOA[item.achrp_id]
            }
          })
        })
        setOAKeyVal(OAkeyVal)
      }
    },
    selectedRowKeys: selectedRowKeysOA
  }

  const onChangeReasonType = (data) => {
    if (data == "5") {
      setEditReasonShow(true)
    }
    else {
      setEditReasonShow(false)
    }
  }

  //只显示大于零
  const OnClickXuanzhongZero = (e) => {
    setinvoiceMin(e.target.checked === true ? 0 : null)
    setLoading2(true)
    api.getRefundAccountDetailList({ editFlag: 1, invoiceMin: e.target.checked === true ? 0 : null, limit: pageSize2, page: currentPage2, relationType: refundData.relationType + '' ? refundData.relationType : '', relationId: refundData.relationId + '' ? refundData.relationId : '', accountId: accountId }).then(datatmp => {
      setLoading2(false)
      setAccountDetailList(datatmp.list)
      setAccountDetailCount(datatmp.count)
      setClaimList(datatmp.allClaimIdList)
    })
    // 老oa只显示大于0
    setloadingOa2(true)
    getOaRecharge(refundData.relationId + '' ? refundData.relationId : '', refundData.relationType + '' ? refundData.relationType : '', accountId, e.target.checked === true ? 0 : null)
  }

  // 文件上传 
  const uploadFiles = upLoadModule({
    fileList: fileList,
    setFileList: setFileList,
    draggerRef: draggerRef,
    accept: '.png, .jpg, .jpeg, .PDF, .zip, .xls, .xlsx',
    allowSizeType: 2,
    limitNum: 20
  })

  // 保存
  const formSubmits = (e) => {
    e.preventDefault()
    let cashKeyIds = []
    let oldcashKeyIds = []
    validateFields((err, vals) => {
      if (selectedIds.length <= 0 && selectedIdsOA.length <= 0) {
        return message.warning("抱歉，至少勾选一笔收款记录", 2)
      }
      let selectedLength = selectedRowKeys.length + selectedRowKeysOA.length
      let keyValLen = Object.values(keyVal).filter(item => item)
      let OAkeyValLen = Object.values(OAkeyVal).filter(item => item)
      let valLength = keyValLen.length + OAkeyValLen.length
      if (selectedLength > 1) { // 总共选中2条以上
        if (+selectedLength !== +valLength) {
          message.warning("请输入对应的退款金额")
          return
        }
        for (let key in keyVal) {
          let temp = {}
          temp[key] = keyVal[key]
          if (selectedRowKeys.includes(+key)) {
            cashKeyIds.push(temp)
          }
        }
        for (let key in OAkeyVal) {
          let tempOA = {}
          tempOA[key] = OAkeyVal[key]
          if (selectedRowKeysOA.includes(key)) {
            oldcashKeyIds.push(tempOA)
          }
        }
      } else if (selectedLength === 1) {  // 总共选中1条
        if (selectedRowKeys.length === 1) {
          keyVal[selectedIds[0]] = vals.money
          for (let key in keyVal) {
            let temp = {}
            temp[key] = keyVal[key]
            if (selectedRowKeys.includes(+key)) {
              cashKeyIds.push(temp)
            }
          }
        } else if (selectedRowKeysOA.length === 1) {
          OAkeyVal[selectedIdsOA[0]] = vals.money
          for (let key in OAkeyVal) {
            let temp = {}
            temp[key] = OAkeyVal[key]
            if (selectedRowKeysOA.includes(key)) {
              oldcashKeyIds.push(temp)
            }
          }
        }
      } else {
        console.log("selectedLength.length", selectedLength.length)
      }
      // 上传附件
      let uploadList = []
      for (let i = 0, k = fileList.length; i < k; i++) {
        if (fileList[i].status !== 'removed') {
          uploadList.push({
            url: fileList[i].url,
            name: fileList[i].name
          })
        }
      }

      selectedIds = selectedIds ? selectedIds : []
      selectedIdsOA = selectedIdsOA ? selectedIdsOA : []
      let refundSaveData = {
        ...vals,
        istaxpoint: [0, 1].includes(vals.istaxpoint) ? vals.istaxpoint : -1,
        isserviceFee: [0, 1].includes(vals.isserviceFee) ? vals.isserviceFee : -1,
        id: detailDataId,
        accountId: accountId,
        refundType: 0,
        taxpoint,
        serviceFee,
        cashIds: selectedIds.join(','),
        cashKeyIds: cashKeyIds,
        oldcashIds: selectedIdsOA.join(','),
        oldcashKeyIds: oldcashKeyIds,
        relationType: search.relationType ? search.relationType : refundData.relationType,
        relationId: search.relationId ? search.relationId : refundData.relationId,
        allowReplenish: allowReplenish,
        attachment: JSON.stringify(uploadList)
      }

      if (!err) {
        // if ((+xuanzhongedu + +OAxuanzhong).toFixed(2) < +vals.money) {
        //   message.warning("发票余额不足", 1)
        //   return
        // }
        if (vals.istaxpoint === 1 && (!taxpoint || taxpoint.trim() === '')) {
          return message.warning("请输入税点")
        }
        setSaveLoading(true)
        setTimeout(() => {
          api.saveRefund(refundSaveData)
            .then(() => {
              message.success('操作成功')
              setTimeout(() => {
                history.push('/fund/refundManage')
                setSaveLoading(false)
              }, 1000);
            })
            .catch(() => {
              setSaveLoading(false)
            })
        }, 1000)
      }
      else {
        // console.log(err)
      }
    })

    return false;
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    }
  }

  const selectZhuTi1 = (id, list = [], relationId, relationType) => {
    list = list.length > 0 ? list : fundAccountList
    let newList = list.map(item => mapZhuti(item, id));
    setFundAccountList(newList)
    // 易企信关联收款返显
    setLoading2(true)
    api.getRefundAccountDetailList({ relationType, relationId, editFlag: 1, limit: pageSize2, page: currentPage2, invoiceMin: invoiceMin, accountId: id }).then(datatmp => {
      setAccountDetailCount(datatmp.count)
      setAccountDetailList(datatmp.list)
      setClaimList(datatmp.allClaimIdList)
      setLoading2(false)
    })

    setloadingOa2(true)
    // 老oa关联收款返显
    api.getOaRecharge({ relationId, relationType, accountId: id, invoiceMin: invoiceMin, limit: pageSizeOa2, page: currentPageOa2, editFlag: 1 }).then(data => {
      setloadingOa2(false)
      setCountOa2(data.count)
      setOaRechargeList(data.list)
      setClaimListOA(data.allClaimIdList)
    })
    setDetailLoading(false)
  }

  const mapZhuti = (item, id) => {
    let checked = false;
    if (item.id === id) {
      checked = true;
      setAccountId(id)
    }
    return { ...item, checked }
  }

  const selectZhuTi = (id, list = []) => {
    xuanzhongedu = 0
    OAxuanzhong = 0

    selectedIds = []
    selectedIdsOA = []

    pageSize2 = 10
    currentPage2 = 1
    pageSizeOa2 = 10
    currentPageOa2 = 1

    // setAccountId(id)
    list = list.length > 0 ? list : fundAccountList

    let newList = list.map(item => mapZhuti(item, id));
    setFundAccountList(newList)

    // 主体项目联动
    setFieldsValue({
      projectId: undefined
    })
    api.getTopProjectList({ accountId: id, isOpen: 1, limit: 999999 }).then(list => {
      setSelectData((current) => ({
        ...current,
        projectList: list.list
      }))
    })
    setloadingOa2(true)
    getOaRecharge(refundData.relationId + '' ? refundData.relationId : '', refundData.relationType + '' ? refundData.relationType : '', id, invoiceMin)
    setLoading2(true)
    api.getRefundAccountDetailList({ relationType: search.relationType, relationId: search.relationId, editFlag: 1, limit: pageSize2, page: currentPage2, invoiceMin: invoiceMin, accountId: id }).then(datatmp => {
      setLoading2(false)
      setAccountDetailList(datatmp.list)
      setAccountDetailCount(datatmp.count)
      setClaimList(datatmp.allClaimIdList)
    })

    ArrObj = {}
    ArrObjOA = {}
    setKeyVal({})
    setOAKeyVal({})
    setSelectedRowKeys([])
    setSelectedRowKeysOA([])
  }
  // src={data.logo}
  const MyRadio = (props) => {
    const { data } = props
    return <><Radio.Button className="radio-container" value={data.id}>
      <img className="mainNameBodySelLogo" alt="" />{data.companyName}<Icon className="check" type="check" />
    </Radio.Button>{data.checked ? <span style={{ marginRight: '10px' }}>余额：{data.balance}</span> : <span>&nbsp;</span>}</>
  }

  const onChangeTicketVal = (e, val) => {
    ArrObj[val.id] = e
    setKeyVal({ ...keyVal, ...ArrObj })
  }

  const onChangeTicketValOA = (e, val) => {
    ArrObjOA[val.achrp_id] = e
    setOAKeyVal({ ...OAkeyVal, ...ArrObjOA })
  }

  const onIstaxpoint = (e) => {
    if (e.target.value === 0) {
      setTaxpointFlag(false)
      setTaxpoint(undefined)
    } else {
      setTaxpointFlag(true)
    }
  }

  return (
    <Spin spinning={detailLoading}>
      <div className="pro-detail">
        <Descriptions title={drawerTitle} layout="horizontal " className="pro-detail-title">
          <dItem label={+refundData.relationType ? "QID" : "OAID"}>
            <>
              {refundData.relationId}
            </>
          </dItem>
          <dItem label="客户类型">
            <>
              {refundData.relationTypeCn}
            </>
          </dItem>
          <dItem label={+refundData.relationType ? "代理商名称" : "客户名称"}>
            <>
              {refundData.relationName}
            </>
          </dItem>
          <dItem label="真实性验证号码类型">
            <>
              {refundData.verifyTypeCn}
            </>
          </dItem>
          <dItem label="真实性验证号码">
            <>
              {refundData.verifyNumber}
            </>
          </dItem>
        </Descriptions>
        <div className="line-box"></div>

        {processShow ?
          <Descriptions title="当前审批进度" className="pro-detail-smailtitle"></Descriptions>
          : null}
        {processShow ?
          <div className="promotionProcess" >
            <Steps>
              {
                statusList.map((item, index) => {
                  let status = 'wait'
                  if (item.time && item.time.length > 1) {
                    status = 'process'
                  }
                  return <Step status={status}
                    key={index}
                    value={item.time}
                    title={item.nodeName}
                    description={
                      <>
                        {item.persons && <div title={`${item.persons.map((item) => item)}`}>{`${item.persons.map((item) => item)}`}</div>}
                        <div title={item.statusName}>{item.statusName}</div>
                        <div title={item.time}>{item.time}</div>
                      </>
                    }
                  />
                })
              }
            </Steps>
          </div>
          : null}

        {processShow ?
          <div className="line-box"></div>
          : null}

        <Descriptions title="未收款发票" className="pro-detail-smailtitle"></Descriptions>
        <h4>易企信：</h4>
        <Table
          size="small"
          dataSource={invoiceList}
          rowKey="id"
          pagination={{
            pageSize,
            total: invoiceCount,
            current: currentPage1,
            showQuickJumper: true,
            showSizeChanger: true,
            pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
          }}
          scroll={{ x: 1400 }}
          onChange={onChangeTable1}
          style={{ marginBottom: 10 }}
        >
          <Column title="序号" dataIndex="id" />
          <Column title="业务编号" dataIndex="invoiceNo" />
          <Column title="客户/代理商名称" dataIndex="customerName" />
          <Column title="客户类型" dataIndex="clientSource" />
          <Column title="主体" dataIndex="companyName" />
          <Column title="金额" dataIndex="money" />
          <Column title="发票类型" dataIndex="invtypeName" />
          <Column title="发票号" dataIndex="invoiceNumber" />
          <Column title="状态" dataIndex="collestatusName" />
          <Column title="最后处理时间" dataIndex="updateTime" />
          <Column title="负责人" dataIndex="applyName" />
        </Table>
        <h4>老OA:</h4>
        <Table
          size="small"
          dataSource={invoiceListOa}
          rowKey="apie_id"
          pagination={{
            pageSize: pageSizeOa,
            total: countOa,
            current: currentPageOa,
            showQuickJumper: true,
            showSizeChanger: true,
            pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
          }}
          scroll={{ x: 1200 }}
          onChange={onChangeTableOa}
          style={{ marginBottom: 14 }}
        >
          <Column title="序号" dataIndex="apie_id" />
          <Column title="业务编号" dataIndex="apie_invno" />
          <Column title="OA客户/渠道名称" dataIndex="apie_clientname" />
          <Column title="金额" dataIndex="apie_money" />
          <Column title="发票类型" dataIndex="apie_invtype_cn" />
          <Column title="发票号" dataIndex="apie_invnumber" />
          <Column title="状态" dataIndex="apie_collestatus_cn" />
          <Column title="负责人" dataIndex="apie_stfname" />
        </Table>

        <div className="line-box"></div>
        <Descriptions title="退款主体（请选择）" className="pro-detail-smailtitle mainNameBodyRadio">
          <Form.Item label="" name="layout">
            {getFieldDecorator('accountId', { "initialValue": accountId })(
              <Radio.Group disabled={search.id > 0 && userAuth && userAuth.allowReplenish ? true : false} onChange={(e) => selectZhuTi(e.target.value, fundAccountList)}>
                {fundAccountList.map(item => <MyRadio key={item.id} data={item} />)}
              </Radio.Group>
            )}
          </Form.Item>
        </Descriptions>
        <div className="line-box"></div>
        <span style={{ position: 'relative', left: '-10px', top: '32px', color: 'red' }}>*</span>
        <Descriptions title="关联的收款信息" className="pro-detail-smailtitle"></Descriptions>
        <h4>易企信：</h4>
        <Table
          size="small"
          dataSource={accountDetailList}
          rowKey="id"
          loading={loading2}
          pagination={{
            pageSize: pageSize2,
            total: accountDetaiCount,
            current: currentPage2,
            showQuickJumper: true,
            showSizeChanger: true,
            pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
          }}
          scroll={{ x: 1800 }}
          onChange={onChangeTable2}
          rowSelection={rowSelection}
        >
          <Column title="序号" dataIndex="id" fixed="left" />
          <Column title="OAID/QID" dataIndex="relationId" />
          <Column title="客户类型" dataIndex="relationTypeCn" />
          <Column title="客户/代理商名称" dataIndex="relationName" />
          <Column title="业务编号" dataIndex="businessNo" />
          <Column title="业务类型" dataIndex="businessTypeCn" />
          <Column title="主体" dataIndex="companyName" />
          <Column title="收入" dataIndex="income" />
          <Column title="可退转额度" dataIndex="remainMoney" />
          <Column title="发票额度支出" dataIndex="cost" />
          <Column title="发票余额" dataIndex="invoice" />
          {
            <Column title="退款金额"
              render={(text, record) =>
                (selectedRowKeys.length + selectedRowKeysOA.length) > 1 && selectedRowKeys.includes(record.id) ?
                  <>
                    <InputNumber
                      defaultValue={
                        record.refundAmount !== 0 ? record.refundAmount : ''
                      }
                      value={keyVal[record.id]}
                      ref={departInp}
                      onChange={(e) => onChangeTicketVal(e, record)}
                      precision={2}
                    // onPressEnter={() => {return}}
                    />
                    {/* {
                      +keyVal[record.id] > +record.invoice ?
                        <p style={{ color: 'red' }}>退款金额大于发票余额，请重新输入</p>
                        : null
                    } */}
                  </> : null
              }
            />
          }
          <Column title="备注" dataIndex="remark" />
          <Column title="负责人" dataIndex="operateStaffName" />
          <Column title="最后处理时间" dataIndex="updateTime" />
        </Table>

        {/* 老oa收款信息 */}
        <h4>老OA：</h4>
        <Table
          size="small"
          dataSource={oaRechargeList}
          loading={loadingOa2}
          rowKey="achrp_id"
          pagination={{
            pageSize: pageSizeOa2,
            total: countOa2,
            current: currentPageOa2,
            showQuickJumper: true,
            showSizeChanger: true,
            pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
          }}
          scroll={{ x: 1700 }}
          onChange={onTableOaRecharge}
          style={{ marginBottom: 14 }}
          rowSelection={oArowSelection}
        >
          <Column title="记录ID" dataIndex="achrp_id" fixed="left" />
          <Column title="OA客户/渠道名称" dataIndex="achrp_clientname" render={(text, record) => (
            <>
              {record.achrp_channelname ? record.achrp_channelname : record.achrp_clientname}
            </>
          )} />
          <Column title="OAID" dataIndex="achrp_clientid" render={(text, record) => (
            <>
              {record.achrp_channelid ? record.achrp_channelid : record.achrp_clientid}
            </>
          )} />
          <Column title="账户" dataIndex="achrp_clatname" />
          <Column title="类型" dataIndex="achrp_type" />
          <Column title="业务编号" dataIndex="achrp_no" />
          <Column title="业务ID" dataIndex="achrp_oid" />
          <Column title="收款" dataIndex="achrp_cash" />
          <Column title="发票额度支出" dataIndex="achrp_cost" />
          <Column title="发票余额" dataIndex="achrp_invoice" />
          {
            <Column title="退款金额"
              render={(text, record) =>
                (selectedRowKeys.length + selectedRowKeysOA.length) > 1 && selectedRowKeysOA.includes(record.achrp_id) ?
                  <>
                    <InputNumber
                      defaultValue={
                        record.achrp_invoice !== 0 ? record.achrp_invoice : ''
                      }
                      value={OAkeyVal[record.achrp_id]}
                      ref={departInpOA}
                      onChange={(e) => onChangeTicketValOA(e, record)}
                      precision={2}
                    // onPressEnter={() => {return}}
                    />
                    {/* {
                      +OAkeyVal[record.achrp_id] > +record.achrp_invoice ?
                        <p style={{ color: 'red' }}>退款金额大于发票余额，请重新输入</p>
                        : null
                    } */}
                  </> : null
              }
            />
          }
          <Column title="备注" dataIndex="achrp_remark" />
          <Column title="操作人" dataIndex="achrp_apyname" />
        </Table>
        <Descriptions title="" className="pro-detail-smailtitle">
          <dItem label="已选中发票额度">
            <div style={{ color: 'red' }}>
              {(+xuanzhongedu + +OAxuanzhong).toFixed(2)}
            </div>
          </dItem>
        </Descriptions>

        <Descriptions title="" className="pro-detail-smailtitle">
          <dItem label="仅显示发票余额大于0的收款记录">
            <div>
              <Checkbox onClick={OnClickXuanzhongZero} defaultValue={false} />
            </div>
          </dItem>
        </Descriptions>
        <div className="line-box"></div>

        <Descriptions title="退款信息填写" className="pro-detail-smailtitle"></Descriptions>
        <Form onSubmit={formSubmits} {...formItemLayout}>
          <Row gutter={20}>
            <Col span={8}>
              <FormItem label="退款项目">
                {getFieldDecorator('projectId', {
                  rules: [{ required: true, message: '请选择退款项目' }]
                })(<Select
                  // placeholder="请选择"
                  showSearch={true}
                  optionFilterProp="children"
                  dropdownMatchSelectWidth={false}
                  disabled={search.id > 0 && userAuth && userAuth.allowReplenish ? true : false}
                >
                  {selectData.projectList.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                </Select>)}
              </FormItem>
            </Col>
            <Col span={8}>
              <Form.Item label="退款金额">
                {getFieldDecorator('money', {
                  rules: [{ required: true, message: '请输入退款金额' }]
                })(<InputNumber
                  precision={2}
                  style={{ width: '200px' }}
                  onChange={() => setMoneyChinese('')}
                  onBlur={evt => setMoneyChinese(changeMoneyToChinese(evt.target.value))}
                  disabled={search.id > 0 && userAuth && userAuth.allowReplenish ? true : false}
                />)}
              </Form.Item>
            </Col>
            <Col span={8}>
              <FormItem label={<span style={{ color: 'red' }}>大写金额</span>} labelCol={{ span: 4 }}>
                <span style={{ color: 'red' }}>{moneyChinese}</span>
              </FormItem>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={10}>
              <div>
                <FormItem label="是否扣除税点">
                  <div style={{ display: 'flex', alignItems: 'center', height: '40px' }}>
                    {getFieldDecorator('istaxpoint', {
                      rules: [{ required: true, message: '请选择是否扣除税点' }]
                    })(
                      <Radio.Group style={{ display: 'flex' }} onChange={onIstaxpoint} disabled={search.id > 0 && userAuth && userAuth.allowReplenish ? true : false}>
                        <Radio value={1}>是</Radio>
                        <Radio value={0}>否</Radio>
                      </Radio.Group>
                    )}
                    {
                      taxpointFlag ?
                        <Input placeholder="税点, 例6%" disabled={search.id > 0 && userAuth && userAuth.allowReplenish ? true : false} value={taxpoint} onChange={(e) => {
                          setTaxpoint(e.target.value)
                        }} /> : <div style={{ fontSize: 0 }}></div>
                    }
                  </div>
                </FormItem>
              </div>
            </Col>
            <Col span={10}>
              <FormItem label="是否扣除服务费">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {getFieldDecorator('isserviceFee', {
                    rules: [{ required: false, message: '请选择是否扣除服务费' }]
                  })(
                    <Radio.Group style={{ display: 'flex' }} disabled={search.id > 0 && userAuth && userAuth.allowReplenish ? true : false}>
                      <Radio value={1}>是</Radio>
                      <Radio value={0}>否</Radio>
                    </Radio.Group>

                  )}
                  <Input placeholder="服务费, 例600" disabled={search.id > 0 && userAuth && userAuth.allowReplenish ? true : false} value={serviceFee} onChange={(e) => {
                    setserviceFee(e.target.value)
                  }} />
                </div>
              </FormItem>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={7}>
              <FormItem label="收款单位">
                {getFieldDecorator('payeeCompany', {
                  rules: [{ required: true, message: '请输入收款单位' },
                  { pattern: /\S/, message: '收款单位不能为空' }
                  ]
                })(<Input />)}
              </FormItem>
            </Col>
            <Col span={7}>
              <FormItem label="开户行">
                {getFieldDecorator('bank', {
                  rules: [{ required: true, message: '请输入开户行' },
                  { pattern: /\S/, message: '开户行不能为空' }],
                })(<Input />)}
              </FormItem>
            </Col>
            <Col span={7}>
              <FormItem label="银行账号">
                {getFieldDecorator('bankAccountNo', {
                  rules: [{ required: true, message: '请输入银行账号' },
                  { pattern: /\S/, message: '银行账号不能为空' }]
                })(<Input />)}
              </FormItem>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={7}>
              <FormItem label="地址">
                {getFieldDecorator('address', {
                  rules: [{ required: false, message: '请输入地址' }]
                })(<Input />)}
              </FormItem>
            </Col>
            <Col span={7}>
              <FormItem label="联系人">
                {getFieldDecorator('contacts', {
                  rules: [{ required: false, message: '请输入联系人' }]
                })(<Input />)}
              </FormItem>
            </Col>
            <Col span={7}>
              <FormItem label="联系电话">
                {getFieldDecorator('contactsTel', {
                  rules: [{ required: false, message: '请输入联系电话' }]
                })(<Input />)}
              </FormItem>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={7}>
              <FormItem label="退款原因">
                {getFieldDecorator('reasonType', {
                  rules: [{ required: true, message: '请选择退款原因' }]
                })(
                  <Select onChange={onChangeReasonType} style={{ width: 150 }} >
                    {selectData.reasonList.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col span={7}>
              {
                editReasonShow ?
                  <Form.Item label="具体原因">
                    {getFieldDecorator('reason', {
                      rules: [{ required: false, message: '请输入退款原因' }]
                    })(<Input />)}
                  </Form.Item>
                  : null
              }
            </Col>
          </Row>
          <Row>
            <FormItem label="附件" labelCol={{ span: 2 }}>
              <div className="dragger-box" ref={draggerRef}>
                <Dragger
                  {...uploadFiles}
                >
                  <p className="ant-upload-drag-icon">
                    <Icon type="inbox" />
                  </p>
                  <p className="ant-upload-text">点击或将文件拖拽到这里上传</p>
                  <p className="ant-upload-hint">
                    附件支持格式：PNG、JPG、PDF、zip、xls、xlsx 支持添加多个附件，单个附件大小不超过5M，文件数量不超过20个。
                  </p>
                </Dragger>
              </div>
            </FormItem>
          </Row>
          <Row>
            <Col>
              <FormItem label="备注" labelCol={{ xs: 2 }} >
                {getFieldDecorator('remark', {
                  rules: [{ required: false, message: '请输入备注' }]
                })(<Input.TextArea />)}
              </FormItem>
            </Col>
          </Row>
          <BtnGroup onCancel={() => {
            history.goBack()
          }} loading={saveLoading} cancelDisabled={saveLoading} />
        </Form>
      </div>
    </Spin>
  )
}

export default Form.create()(NewAdd)