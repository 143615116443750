/**
 * 模块名称: 帮助中心管理
 * @author zhuyan@372163.com
 */
import React, { useEffect } from 'react'
import { Tabs } from 'antd'
import HelpCenter from './HelpCenter'
import  FeedbackList from './FeedbackList'
import { parseSearch } from '@/utils'

let pageSize = 10
// let currentPage = 1
let tabActiveKey = "1"

const ThirdPartyMgt = (props) => {
  const { history, match } = props
  const { TabPane } = Tabs
  // const [tabActiveKey, setTabActiveKey] = useState('1')
  const search = parseSearch(props.location.search)

  useEffect(() => {
    // if(props.location.state && props.location.state.tabActiveKey){
    //   setTabActiveKey(props.location.state.tabActiveKey)
    // }
    // currentPage = search.page ? +search.page : 1
    pageSize = search.limit ? +search.limit : 10
    if(search.activeKey){
      tabActiveKey = search.activeKey
      onTabClick(tabActiveKey)
    }else{
      tabActiveKey = '1'
    }
  }, [])

  const onTabClick = (e) => {
    tabActiveKey = e
    history.replace(match.path + '?page=' + 1 + '&limit=' + pageSize + '&activeKey=' + tabActiveKey)
  }
  // const onTabClick = (e) => {
  //   history.push({
  //     pathname: '/setSystem/helpCenterMgt',
  //     state: { 'tabActiveKey': e }
  //   })
  //   setTabActiveKey(e)
  // }
  return (
    <div>
      <div className="one-main">
        {/* defaultActiveKey="1"  */}
        <Tabs activeKey={tabActiveKey} onTabClick={onTabClick}>
          <TabPane tab="帮助中心" key="1">
            <HelpCenter props={props}/>
          </TabPane>
          <TabPane tab="反馈列表" key="2">
            <FeedbackList props={props}/>
          </TabPane>
        </Tabs>
      </div>
    </div>
  )
}

export default ThirdPartyMgt;
