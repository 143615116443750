/**
 * 模块名称: 菜单管理
 * @author lids@372163.com
 */
import React, { useState, useEffect } from 'react'
import { Modal, Input, message, Switch, Select, Icon } from 'antd'
import { useDispatch } from 'react-redux'
import { setMenuList } from '@/global/reducer/menuList'
import api from '@/api'

const { Option } = Select

const MenuControl = (props) => {
  const [menu, setMenu] = useState([])
  const [menuDetault, setMenuDefault] = useState([])
  const [pMenuIds, setPMenuIds] = useState([])
  const dispatch = useDispatch()
  const [openId, setOpenId] = useState(0)
  const [editId, setEditId] = useState('')
  const [editTitle, setEditTitle] = useState('')
  const [editModelShow, setEditModelShow] = useState(false)
  const [isShow, setIsShow] = useState(false)
  const [searchName, setSearchName] = useState([])//eslint-disable-line
  const [menuData, setMenuData] = useState([])

  useEffect(() => {
    getMenu()
  }, [])

  const getOpenId = (id) => {
    openId == id ? setOpenId(0) : setOpenId(id)//eslint-disable-line
  }

  const onMouseDown = e => {
    e.currentTarget.classList.add('catching')
  }

  const onMouseUp = e => {
    e.currentTarget.classList.remove('catching')
  }

  const onDragStart = (e) => {
    let place = e.target.getAttribute('place')//判断拖动的是父级还是子级
    if(place == 'parent')setOpenId(0)//eslint-disable-line
    e.dataTransfer.setData('id', e.target.getAttribute('data-id'))
    e.dataTransfer.setData('sort', e.target.getAttribute('data-sort'))
  }

  const onDragOver = (e) => {
    e.preventDefault()
    const target = e.currentTarget
    clearCatching()
    if (target.className.indexOf('catching') === -1) {
      target.classList.add('catching')
    }
  }

  const clearCatching = () => {
    const modulesWrap = document.querySelector('.menu-con-wrap')
    const modules = modulesWrap.querySelectorAll('.drag-item')
    for (let i = 0; i < modules.length; i++) {
      modules[i].classList.remove('catching')
    }
  }

  const onDrop = (e) => {
    e.preventDefault()
    clearCatching()
    const index1 = e.dataTransfer.getData('sort')
    const index2 = e.currentTarget.getAttribute('data-sort')

    if (index1 === index2) return

    let ids = e.currentTarget.getAttribute('data-ids').split(',')
    const index1Id = e.dataTransfer.getData('id')
    ids.splice(index1 - 1, 1)
    ids.splice(index2 - 1, 0, index1Id)
    let params = ids.join(',')
    //console.log('重排后', params, ids.length)
    api.onSysMenuEditSort({sortData: params}).then(res => {
      getMenu()
      dispGetMenu()
    })
  }

  const dispGetMenu = () => {
    api.getMenu().then(menu => {
      dispatch(setMenuList(menu))
    })
  }

  const getMenu = () => {
    return api.getMenu({type: 'all'}).then(menu => {
      let arr = [].concat(menu), pArr = []
      for(let i = 0; i<arr.length; i++){
        let a = []
        pArr.push(arr[i].id)
        for(let j=0; j<arr[i].children.length; j++){
          a.push(arr[i].children[j].id)
        }
        arr[i].ids = a
      }
      setPMenuIds(pArr)
      setMenu(arr)
      getMenuData(menu)
    })
  }

  const getMenuData = data => {
    let arrMenu = []
    data.forEach((item) => {
      arrMenu.push({
        id: item.id,
        pid: item.pid,
        name: item.title
      })
      if(item.children.length){
        item.children.forEach(itemChild => {
            arrMenu.push({
            id: itemChild.id,
            pid: itemChild.pid,
            name: itemChild.title
          })
        })
      }
    })
    setMenuData([...new Set(arrMenu)])
    setMenuDefault([...new Set(arrMenu)])
  }

  const editMenuName = (id, title, isShow) => {
    setEditId(id)
    setEditTitle(title)
    setEditModelShow(true)
    setIsShow(isShow)
  }

  const editHandleOk = () => {
    let Reg = /[\ |\s*(.*?)\s+$|\~|\`|\!|\@|\#|\$|\%|\^|\&|\*|\(|\)|\-|\_|\+|\=|\||\\|\[|\]|\{|\}|\;|\:|\"|\'|\,|\，|\。|\“|\”|\‘|\’|\¥|\？|\（|\）|\；|\：|\、|\！|\<|\.|\>|\/|\?]/  //eslint-disable-line

    if(Reg.test(editTitle)){
      message.warning('不允许出现字符串、空格，请修改后提交')
      return
    }
    api.onSysMenuEdit({
      id: editId,
      title: editTitle,
      isShow: isShow
    }).then(res => {
      message.success('修改成功')
      setEditModelShow(false)
      dispGetMenu()
      getMenu()
    })
  }

  const onEditMenuNameChange = (e) => {
    //console.log(e.target.value)
    setEditTitle(e.target.value)
  }

  const onChangeStatus = (status) => {
    //console.log(status)
    status ? setIsShow(1) : setIsShow(0)
  }

  const handleMenuSearch = value => {
    //console.log(value)
    if(value === ''){
      setMenuData(menuDetault)
      return
    }
    let arr = []
    menuDetault.forEach(item => {
      if(item.name.indexOf(value) >= 0){
        arr.push(item)
      }
    })
    setMenuData(arr)
  }

  const handleMenuChange = data => {
    //console.log(data)
    //setSearchName(data)
    menuDetault.forEach(item => {
      if(item.pid == data.key){//eslint-disable-line
        setOpenId(Number(item.pid))
        return
      }
      if(item.id == data.key){//eslint-disable-line
        setOpenId(Number(item.pid))
        return
      }
    })
  }

  const onSelectMenuName = e => { 
    setMenuData(menuDetault)
  }

  return (
    <>
      <div className="menu-search">
        <Icon type="search" />
        <Select
          showSearch
          maxTagCount={1}
          labelInValue
          value={searchName}
          onSelect={onSelectMenuName}
          placeholder="请输入菜单名"
          style={{width: '100%'}}
          defaultActiveFirstOption={false}
          showArrow={false}
          filterOption={false}
          notFoundContent={null}
          onSearch={handleMenuSearch}
          onChange={handleMenuChange}
        >
          {
            menuData.map(d => <Option key={d.id}>{d.name}</Option>)
          }
        </Select>
      </div>
      <div className="menu-con-wrap">
        {
          menu.map((item, index) => 
            <div key={index} className="container">
              <div 
                className={item.isShow ? "p-menu drag-item" : "p-menu drag-item gray"}
                key={item.id} 
                place="parent"
                draggable={item.isShow ? true : false}
                onDragStart={onDragStart}
                onDragOver={onDragOver}
                onDrop={onDrop}
                onMouseDown={onMouseDown}
                onMouseUp={onMouseUp}
                data-ids={pMenuIds}
                data-id={item.id} 
                data-sort={item.sort}
                onClick={() => getOpenId(item.id)}
              >
                {openId === item.id ? <div className="collapse collapse-open">-</div> : <div className="collapse collapse-close">+</div>}
                <div className="block">
                  {item.title}
                  &nbsp;&nbsp;&nbsp;{item.isShow === 1 && <div className="sort" title="按钮拖动可进行排序">排序</div>}&nbsp;&nbsp;&nbsp;<div className="edit" onClick={() => editMenuName(item.id, item.title, item.isShow)}>编辑</div>
                </div>
              </div>
              <div 
                className="s-menu"
                data-id={item.id}
                style={item.id === openId ? {maxHeight: '500px'}: {maxHeight: '0px'}}
              >
              {
                item.children.map((i, d, c) => {
                  return <div
                    className={i.isShow ? "s-menu-item drag-item" : "s-menu-item drag-item gray"}
                    key={i.id}
                    place="child"
                    draggable={i.isShow ? true : false}
                    onDragStart={onDragStart}
                    onDragOver={onDragOver}
                    onDrop={onDrop}
                    onMouseDown={onMouseDown}
                    onMouseUp={onMouseUp}
                    data-ids={item.ids}
                    data-id={i.id}
                    data-sort={i.sort}
                  >
                    <div className="block">
                      {i.title}
                      &nbsp;&nbsp;&nbsp;{i.isShow === 1 && <div className="sort" title="按钮拖动可进行排序">排序</div>}&nbsp;&nbsp;&nbsp;<div className="edit" onClick={() => editMenuName(i.id, i.title, i.isShow)}>编辑</div>
                    </div>
                  </div>
                })
              }
              </div>
            </div>
          )
        }
        <Modal
          title="修改菜单名称"
          visible={editModelShow}
          onOk={editHandleOk}
          onCancel={() => setEditModelShow(false)}
          destroyOnClose={true}
          className="menu-con-modal"
        >
          <Input placeholder="请输入修改后的菜单名称" defaultValue={editTitle} onChange={onEditMenuNameChange} onPressEnter={editHandleOk} />
          <div className="switch-isShow">
            是否显示该菜单：<Switch size="small" defaultChecked={isShow === 1 ? true : false} onChange={onChangeStatus} />
          </div>
        </Modal>
      </div>
    </>
  )
}

export default MenuControl