/**
 * 模块名称: 第三方配置(邮箱)
 * @author zhuyan@372163.com
 */
import React, { useState, useEffect } from 'react'
import {
  Input,
  Form,
  Radio,
  Button,
  message
} from 'antd'
import BtnGroup from '@/components/BtnGroup'
import api from '@/api'
import Auth from '@/components/AuthMiddleware'

const FormItem = Form.Item

const MailConfig = (props) => {
  const { getFieldDecorator, setFieldsValue, validateFields } = props.form
  const [emailVal, setEmailVal] = useState('')
  const [timeValue, setTimeValue] = useState('发送测试邮件')
  const [codeDisabled, setCodeDisabled] = useState(false)
  const [authList, setAuthList] = useState([])


  useEffect(() => {
    getThirdPartyMailConfig()
    api.getPageAuth().then(list => setAuthList(list))
  }, [])

  // 获取初始值
  const getThirdPartyMailConfig = () => {
    api.getThirdPartyMailConfig({}, true)
      .then(data => {
        let initData = data.default
        setFieldsValue({
          host: initData.host,
          encryption: initData.encryption,
          port: initData.port,
          from: Object.keys(initData.from)[0],
          username: initData.username,
          password: initData.password,
          isopen: 1
        })
      })
  }

  const onRedioChange = (e) => {
    if (e.target.value === 'tls') {
      setFieldsValue({
        port: '25',
      })
    } else {
      setFieldsValue({
        port: '465',
      })
    }
  }

  const emailChange = (e) => {
    setEmailVal(e.target.value)
  }

  // 倒计时
  const timeoutInterval = () => {
    setCodeDisabled(true)
    let sumTime = 60
    let interval = setInterval(() => {
      if (sumTime > 0) {
        sumTime--
        setTimeValue("发送测试邮件(" + sumTime + "S)")
      } else {
        clearInterval(interval)
        setCodeDisabled(false)
        setTimeValue("发送测试邮件")
      }
    }, 1000)
  }

  //发送短信测试
  const onTestMail = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      if (!err) {
        api.setThirdPartyMailConfig({
          encryption: vals.encryption,
          host: vals.host,
          port: vals.port,
          username: vals.username,
          password: vals.password,
          isopen: vals.isopen,
          from: vals.from
        }).then((data) => {
          if (emailVal.trim() !== '') {
            timeoutInterval()
            api.testThirdPartyMail({
              to: emailVal
            })
          } else {
            message.error("请输入测试邮箱")
          }
        }).catch()
      }
    })
  }

  // 提交
  const formSubmit = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      if (!err) {
        api.setThirdPartyMailConfig({
          encryption: vals.encryption,
          host: vals.host,
          port: vals.port,
          username: vals.username,
          password: vals.password,
          isopen: vals.isopen,
          from: vals.from
        }).then((data) => {
          message.success('设置成功')
        }).catch()
      }
    })
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 5 },
      sm: { span: 5 },
    },
    wrapperCol: {
      xs: { span: 10 },
      sm: { span: 10 },
    }
  }

  return (
    <div className="email-con">
      <Form onSubmit={formSubmit} {...formItemLayout}>
        <Form.Item label="邮箱服务是否需要加密链接（ssl）">
          {getFieldDecorator('encryption')(
            <Radio.Group onChange={onRedioChange}>
              <Radio value={"ssl"}>是</Radio>
              <Radio value={"tls"}>否</Radio>
            </Radio.Group>
          )}
        </Form.Item>
        <FormItem label="发送邮件服务器地址" className="email-con-form">
          {getFieldDecorator('host', {
            rules: [{ required: true, message: '请输入发送邮件服务器地址' }]
          })(
            <Input placeholder="请输入发送邮件服务器地址" />
          )}
          <span className="email-con-text"> 邮件服务器主机地址。</span>
        </FormItem>
        <FormItem label="服务器端口">
          {getFieldDecorator('port', {
            rules: [{ required: true, message: '请输入服务器端口' }]
          })(
            <Input disabled placeholder="请输入服务器端口" />
          )}
        </FormItem>
        <FormItem label="邮件发送账号">
          {getFieldDecorator('username', {
            rules: [{ required: true, message: '请输入邮件发送账号' }]
          })(
            <Input placeholder="请输入邮件发送账号" />
          )}
        </FormItem>
        <FormItem label="账号密码">
          {getFieldDecorator('password', {
            rules: [{ required: true, message: '请输入账号密码' }]
          })(
            <Input placeholder="请输入账号密码" />
          )}
        </FormItem>
        <FormItem label="邮件回复地址">
          {getFieldDecorator('from', {
            rules: [{ required: true, message: '请输入邮件回复地址' }]
          })(
            <Input placeholder="请输入邮件回复地址" />
          )}
        </FormItem>
        <FormItem label="测试邮件地址" className="testSite">
          {getFieldDecorator('testemail')(
            <Input placeholder="请输入测试邮件地址" onChange={emailChange} />
          )}
          <Auth auths={authList} code="operate">
            <Button type="primary" onClick={onTestMail} disabled={codeDisabled}>{timeValue}</Button>
          </Auth>
        </FormItem>
        <Form.Item label="是否启用">
          {getFieldDecorator('isopen', {
          })(
            <Radio.Group>
              <Radio value={1} >是</Radio>
              <Radio value={0} >否</Radio>
            </Radio.Group>
          )}
        </Form.Item>
        <Auth auths={authList} code="operate">
          <BtnGroup onCancel={() => getThirdPartyMailConfig()} />
        </Auth>
      </Form>
    </div>
  )
}

export default Form.create()(MailConfig)

