/**
 * 模块名称: 系统设置
 * @author zhuyan@372163.com
 */
import React, { useState, useEffect } from 'react'
import {
  Input,
  Form,
  Radio,
  Button,
  message,
  Checkbox,
  Row,
  Col,
  Popconfirm
} from 'antd'
import Upload from '@/components/Upload'
import api from '@/api'
import Auth from '@/components/AuthMiddleware'
import { useDispatch } from 'react-redux'
import { updateConfig } from '@/global/reducer/config'

const FormItem = Form.Item
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  }
}

const SystemSetup = (props) => {
  const { getFieldDecorator, validateFields, setFieldsValue } = props.form
  const [visit, setVisit] = useState('')
  const [desc, setDesc] = useState('')
  const [textDisplay, setTextDisplay] = useState(true)
  const [popconfirmVisible, setPopconfirmVisible] = useState(false)
  const [authList, setAuthList] = useState([])
  const dispatch = useDispatch()

  useEffect(() => {
    getAccess()
    getinfoAccess()
    api.getPageAuth().then(list => setAuthList(list))
  }, [])

  // 获取系统信息设置
  const getinfoAccess = () => {
    api.getSysConfigInfo({}, true)
      .then(data => {
        let infoData = data
        setFieldsValue({
          sysname: infoData.sysname,
          qrcode: infoData.qrcode,
          wxexit: infoData.wxexit,
          passtype: infoData.passtype,
          syslogo: infoData.syslogo,
          qrcodeimg: infoData.qrcodeimg
        })
      })
  }

  // 保存系统信息
  const formSubmit = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      if (!err) {
        api.saveSysConfigInfo({
          sysname: vals.sysname,
          syslogo: vals.syslogo,
          qrcode: vals.qrcode,
          wxexit: vals.wxexit,
          qrcodeimg: vals.qrcodeimg,
          passtype: vals.passtype
        }).then((data) => {
          message.success('设置成功')
          api.getSysConfigInfo().then(data => {
            dispatch(updateConfig(data))
          })
        })
      }
    })
  }

  // 获取权限初始值
  const getAccess = () => {
    api.setSysConfigAccess({}, true)
      .then(data => {
        let initData = data
        setFieldsValue({
          text: initData.desc
        })
        setVisit(initData.isaccess)
        setDesc(initData.desc)
      })
  }

  // 保存权限设置
  const topSave = () => {
    if (visit === "0") {
      if (desc.trim() === '') {
        setTextDisplay(false)
        return
      }
    }
    api.setSysConfigPostAccess({
      isaccess: visit,
      desc: desc
    }).then(() => {
      message.success('保存成功')
    })
  }
  const onDescChange = (e) => {
    setDesc(e.target.value)
    setTextDisplay(true)
  }

  const onChangeVisitRadio = (e) => {
    const val = e.target.value
    if (val === '0') {
      setPopconfirmVisible(true)
    } else {
      setVisit(val)
    }
  }

  const onCancelChangeRadio = () => {
    setPopconfirmVisible(false)
  }

  const onConfirmRadio = () => {
    setPopconfirmVisible(false)
    setVisit('0')
  }

  return (
    <div className="system-con">
      <div className="system-con-top" >
        <div className="visit-title">访问权限设置</div>
        <div className="radio-con">
          <Radio.Group value={visit} onChange={onChangeVisitRadio}>
            <Radio value={"1"}>开启访问</Radio>
            <Popconfirm
              visible={popconfirmVisible}
              title="确认关闭访问吗？"
              cancelText="取消"
              okText="确认"
              onCancel={onCancelChangeRadio}
              onConfirm={onConfirmRadio}
            >
              <Radio value={"0"}>关闭访问</Radio>
            </Popconfirm>
          </Radio.Group>
        </div>
        {getFieldDecorator('text', {
          rules: [{ required: true, message: '说明' }]
        })(
          <Input.TextArea placeholder="请在此处填写说明" onChange={onDescChange} maxLength="100" />
        )}
        <span className="poine-text" hidden={textDisplay}>请填写说明</span>
        <div className="visit-btn">
          <Auth auths={authList} code="operate">
            <Button type="primary" onClick={topSave}>保存</Button>
          </Auth>
        </div>
      </div>
      <div className="line-con"></div>
      <div className="system-bootom">
        <div className="system-bootom-title">系统设置</div>
        <Form onSubmit={formSubmit} {...formItemLayout}>
          <div className="form-con">
            <div className="form-con-one">
              <FormItem label="系统名称">
                {getFieldDecorator('sysname', {
                  rules: [{ required: true, message: '系统名称' }]
                })(
                  <Input placeholder="请输入系统名称" />
                )}
              </FormItem>
              <Form.Item label="找回密码方式">
                {getFieldDecorator('passtype', {
                  rules: [{ required: true, message: '请选择找回密码方式' }]
                  , initialValue: ['0']
                })(
                  <Checkbox.Group>
                    <Row>
                      <Col span={24}>
                        <Checkbox value="0">邮箱</Checkbox>
                      </Col>
                      <Col span={24}>
                        <Checkbox value="1">手机号码</Checkbox>
                      </Col>
                    </Row>
                  </Checkbox.Group>
                )}
              </Form.Item>
              <FormItem label="系统LOGO">
                {getFieldDecorator('syslogo', {
                  rules: [{ required: true, message: '请选择系统LOGO' }]
                })(<Upload data={{ allowExts: 'png,jpg,jepg' }} />)}
                <div>
                  <div>支持扩展名：JPG、JPEG、PNG</div>
                  <div className="img-hint">大小不超过3M</div>
                </div>
              </FormItem>
            </div>
            <div className="program-con">
              <Form.Item label="小程序退出">
                {getFieldDecorator('wxexit')(
                  <Radio.Group>
                    <Radio value={1}>显示</Radio>
                    <Radio value={0}>隐藏</Radio>
                  </Radio.Group>
                )}
              </Form.Item>
              <Form.Item label="小程序二维码">
                {getFieldDecorator('qrcode')(
                  <Radio.Group>
                    <Radio value={"1"}>显示</Radio>
                    <Radio value={"0"}>隐藏</Radio>
                  </Radio.Group>
                )}
              </Form.Item>
              <FormItem label="小程序二维码">
                {getFieldDecorator('qrcodeimg', {
                  rules: [{ required: true, message: '请选择小程序二维码' }]
                })(<Upload data={{ allowExts: 'png,jpg,jepg' }} />)}
                <div>
                  <div>支持扩展名：JPG、JPEG、PNG</div>
                  <div className="img-hint">大小不超过3M</div>
                </div>
              </FormItem>
            </div>
          </div>
          <div className="system-bootom-btn">
            <Auth auths={authList} code="operate">
              <Button type="primary" htmlType="submit">保存</Button>
            </Auth>
          </div>
        </Form>
      </div>
    </div>
  )
}

export default Form.create()(SystemSetup)


