/**
 * 模块名称: 公司制度详情
 * @author xuzhongyuan@372163.com
 */

import React, { useState, useEffect } from 'react'
import api from '@/api'
import { parseSearch } from '@/utils'
import './style.scss'
import DetailsComp from '@/components/Details'
import urls from '@/api/urls'
import { message } from 'antd'
import CryptoJS from 'crypto-js'

const Details = (props) => {
  const { history } = props
  const [data, setData] = useState({
    id: '',
    title: '',
    crateTime: '',
    createBy: '',
    content: '',
    affix: []
  })
  const search = parseSearch(props.location.search)

  useEffect(() => {
    let plaintext = search.sign
    let plaintextTwo = CryptoJS.MD5(search.id + 'ad629fddf8b8756d2e72e96ae035a5a4' + search.time).toString()

    if (plaintext == plaintextTwo && +new Date() - +search.time <= 21600000) {
      api.getIndexCorpRuleDetails({ id: search.id }, true).then(data => {
        //api.setMessageReaded({ ids: [search.id] })
        setData({
          id: data.id,
          title: data.title,
          crateTime: data.addTime,
          createBy: data.userName,
          content: data.content,
          affix: data.affix
        })
      })
    } else {
      message.error('没有本页访问权限')
      setTimeout(() => {
        history.go(-2)
      }, 1000)
    }
  }, [])

  return (
    <DetailsComp
      data={data}
      onClose={history.goBack}
      url={data.affix.length ? `${urls.downloadIndexCorpRule.url}?id=${data.id}` : null}
    />
  )
}

export default Details