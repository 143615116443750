/**
 * 模块名称: 系统配置管理模块
 * @author zhuyan@372163.com
 */

// import '../assets/style.scss'
import React from 'react'
import { Switch, Route } from 'react-router-dom'
import SystemBulletin from './SystemBulletin'
import BulletinDetail from './BulletinDetail'

const SystemBulletinIndex = ({ match, history }) => {

  return (
    <div>
      <Switch>
        <Route path={match.path + '/detail'} component={BulletinDetail} />
        <Route component={SystemBulletin} />
      </Switch>
    </div>
  )
}

export default SystemBulletinIndex