/**
 * 模块名称: 回收站
 * @author liujingxue@372163.com
 */

import React, { useState, useEffect, useRef } from 'react'
import api from '@/api'
import {
  Table,
  Form,
  DatePicker,
  Input,
  Button,
  Alert,
  Icon,
  Popconfirm,
  message,
  Select,
  Tooltip
} from 'antd'
import Auth from '@/components/AuthMiddleware'
import { parseSearch } from '@/utils'

const { Column } = Table
const FormItem = Form.Item
const { RangePicker } = DatePicker
const Option = Select.Option

let pageSize = 10
let currentPage = 1
let searchParamsTmp = {
  limit: pageSize,
  page: currentPage
}

const RecycleBin = props => {

  const { getFieldDecorator, validateFields, resetFields } = props.form

  const { history, match } = props

  const [branchIds, setBranchIds] = useState([])//eslint-disable-line

  const [count, setCount] = useState(0)
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(true)
  const [recycleType, setRecycleType] = useState([])
  const [filterType, setFilterType] = useState([])
  const intervalRef = useRef(null)

  const [authList, setAuthList] = useState([])//按钮权限
  const search = parseSearch(props.location.search)

  useEffect(() => {
    currentPage = search.page ? +search.page : 1
    pageSize = search.limit ? +search.limit : 10
    searchParamsTmp = {
      limit: pageSize,
      page: currentPage
    }
    getSysRecycleList()
    getPageAuth()
    api.getSysRecycleType().then((data) => {
      setRecycleType(data)
      let dataType = data.filter((v) => {
        return v.name !== ''
      })
      setFilterType(dataType)
    })
    return () => clearTimeout(intervalRef.current)
  }, [])

  //按钮权限
  const getPageAuth = () => {
    api.getPageAuth().then(list => {
      setAuthList(list)
    })
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 },
    }
  }

  //获取回收站列表
  const getSysRecycleList = () => {
    setLoading(true)
    let id = setTimeout(() => {
      api.getSysRecycleList(searchParamsTmp).then(res => {
        setLoading(false)
        setCount(res.count)
        setList(res.list)
      }).catch(() => { setLoading(false) })
    }, 500)
    intervalRef.current = id
  }

  //恢复
  const onRecovery = (id) => {
    api.getSysRecyclerecover({ id }, true).then(() => {
      message.success('恢复成功')
      getSysRecycleList()
    })
  }
  //分页点击
  const onChangeTable = (pagination, filters, sorter) => {
    currentPage = pagination.current
    pageSize = pagination.pageSize
    history.replace(match.path + '?page=' + currentPage + '&limit=' + pageSize)
    searchParamsTmp = {
      limit: pageSize,
      page: currentPage
    }
    getSysRecycleList()
  }
  //表单提交
  const formSubmit = (e) => {
    e.preventDefault()
    //currentPage = 1
    validateFields((err, vals) => {
      //console.log(vals)
      if (vals !== undefined) {
        if (vals.type !== undefined) searchParamsTmp.type = vals.type
        if (vals.userName !== undefined) searchParamsTmp.userName = vals.userName
        if (vals.date !== undefined) {
          searchParamsTmp.dateBegin = vals.date ? vals.date[0].format('YYYY-MM-DD') : ''
          searchParamsTmp.dateEnd = vals.date ? vals.date[1].format('YYYY-MM-DD') : ''
        }
      }
      if (!err) {
        searchParamsTmp.page = currentPage = 1
        history.replace(match.path)
        getSysRecycleList()
      }
    })
  }
  //重置
  const onReset = () => {
    resetFields()
    currentPage = 1
    history.replace(match.path)
    searchParamsTmp = {
      limit: pageSize,
      page: currentPage
    }
    if (branchIds.length) searchParamsTmp.corpId = branchIds.join(',')
    getSysRecycleList()
  }

  return (
    <>
      <div className="search-wrap">
        <Form onSubmit={formSubmit}  {...formItemLayout}>
          <FormItem label="类型">
            {getFieldDecorator('type', {
            })(<Select placeholder="请选择" >
              {
                filterType.map((v, i) => {
                  return <Option value={v.id} key={i}>{v.name}</Option>
                })
              }
            </Select>)}
          </FormItem>
          <FormItem label="操作人">
            {getFieldDecorator('userName', {
            })(<Input placeholder="请输入" />)}
          </FormItem>
          <FormItem label="删除日期">
            {getFieldDecorator('date', {
            })(<RangePicker allowClear={false} />)}
          </FormItem>
          <FormItem className="btns" label=" ">
            <Button type="primary" htmlType="submit">查询</Button>
            <Button onClick={onReset}>重置</Button>
          </FormItem>
        </Form>
      </div>
      <div className="line"></div>
      <div className="white-line"></div>
      <div>
        <Alert className="corp-count" message={`总计：${count}条数据`} type="info" showIcon style={{ marginBottom: 30 }} />
        <Table
          size="middle"
          dataSource={list}
          rowKey="id"
          className="search-table"
          loading={loading}
          pagination={{
            pageSize,
            total: count,
            current: currentPage,
            showQuickJumper: true,
            showSizeChanger: true,
            pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
          }}
          onChange={onChangeTable}
        >
          <Column title="类型 " dataIndex="type" render={(text, record) => (
            recycleType.map((v) => {
              if (text == v.id) return v.name //eslint-disable-line
            })
          )} />
          <Column title="详情" dataIndex="desc" />
          <Column title="操作人" dataIndex="userName" />
          <Column title="删除时间" dataIndex="date" />
          <Column title="操作"
            key="set"
            fixed="right"
            width={100}
            render={(text, record) =>
              <>
                <Auth auths={authList} code="operate">
                  <Tooltip title="恢复本条记录" placement="top">
                    <Popconfirm title="请确认是否恢复本条记录。" cancelText="取消" okText="确认" onConfirm={() => onRecovery(record.id)}>
                      <Icon className="operate-icon" style={{ color: '#1890ff' }} type="redo" />
                    </Popconfirm>
                  </Tooltip>
                </Auth>
              </>
            }
          />
        </Table>
      </div>
    </>
  )
}

export default Form.create()(RecycleBin)
