/**
 * 模块名称: 帮助中心管理 -> 添加分类
 * @author zhuyan@372163.com
 */
import React, { useState, useEffect } from 'react'
import api from '@/api'
import {
  Form,
  Input,
  message
} from 'antd'
import BtnGroup from '@/components/BtnGroup'

const FormItem = Form.Item

const AddHelpModule = (props) => {
  const { getFieldDecorator, validateFields, setFieldsValue } = props.form
  const { setVisible, editRowId, setAddModuleLoading, onSuccess } = props
  const [saveLoading, setSaveLoading] = useState(false)
  const [name, setName] = useState('')//eslint-disable-line


  useEffect(() => {
    setAddModuleLoading(false)
    onEdit()
  }, [])

  // 编辑框
  const onEdit = () => {
    if (editRowId) {
      api.saveQuestionClassDetail({ id: editRowId }).then(res => {
        setFieldsValue({
          title: res.title,
        })
      })
    }
  }

  // 添加确认
  const formSubmit = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      if (!err) {
        if (editRowId) {
          vals.id = editRowId
        }
        setSaveLoading(true)
        api.saveQuestionClassUpdate({
          id: vals.id,
          title: vals.title
        })
          .then(() => {
            setSaveLoading(false)
            setVisible(false)
            onSuccess(vals.title)
            if (editRowId) {
              message.success('编辑成功')
            } else {
              message.success('添加成功')
            }
          })
          .catch(() => setSaveLoading(false))
      }
    })
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    }
  }

  return (
    <>
      <Form onSubmit={formSubmit} {...formItemLayout}>
        <FormItem label="分类名称">
          {getFieldDecorator('title', {
            initialValue: name,
            rules: [{ required: true, message: '请输入分类名称' }]
          })(<Input autoComplete="off" placeholder="请输入分类名称" maxLength={20} />)}
        </FormItem>
        <BtnGroup onCancel={() => setVisible(false)} loading={saveLoading} />
      </Form>
    </>
  )
}
export default Form.create()(AddHelpModule)