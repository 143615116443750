/**
 * 模块名称: 帮助中心管理 -> 帮助中心
 * @author zhuyan@372163.com
 */
import React, { useState, useEffect } from 'react'
import api from '@/api'
// import urls from '@/api/urls'
import {
  Table,
  Drawer,
  Form,
  Alert,
  Button,
  Input,
  Select,
  message,
  Icon,
  Popconfirm,
  Tooltip,
  Row,
  Col,
  DatePicker,
  Spin
} from 'antd'
import { parseSearch } from '@/utils'
import Auth from '@/components/AuthMiddleware'
import AddHelpModule from './AddHelpModule'
import { Link } from 'react-router-dom'
import CryptoJS from 'crypto-js'

const { Column } = Table
const { RangePicker } = DatePicker
const FormItem = Form.Item
const Option = Select.Option
let pageSize = 10
let currentPage = 1
let Key = ''

//分类管理页码参数
let modulePageSize = 10
let moduleCurrentPage = 1

let filter = {
  title: '',
  class: '',
  userName: '',
  searchCreationTimeBegin: '',
  searchCreationTimeEnd: '',
  sort: '',
  sortMethod: '' //desc
}

const HelpCenter = (props) => {
  const { match, location, history } = props.props
  const { getFieldDecorator, validateFields, resetFields } = props.form
  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])
  const [count, setCount] = useState(0)
  const [visible, setVisible] = useState(false)
  const [moduleList, setModuleList] = useState([])
  const [moduleLoading, setModuleLoading] = useState(true)
  const [editRowId, setEditRowId] = useState('')
  const [moduleCount, setModuleCount] = useState(0)
  const [moduleName, setModuleName] = useState('添加分类')
  const [moduleAddVisible, setModuleAddVisible] = useState(false)
  const [addModuleLoading, setAddModuleLoading] = useState(true)
  const [questionClass, setQuestionClass] = useState([])
  const [authList, setAuthList] = useState([])
  const search = parseSearch(location.search)
  Key = search.activeKey ? +search.activeKey : '1'

  useEffect(() => {
    currentPage = search.page ? +search.page : 1
    pageSize = search.limit ? +search.limit : 10

    filter = {
      title: '',
      class: '',
      userName: '',
      searchCreationTimeBegin: '',
      searchCreationTimeEnd: '',
      sort: '',
      sortMethod: '' //desc
    }
    api.getPageAuth().then(list => setAuthList(list))
    getList()
    onStionClass()
  }, [Key])

  const getList = () => {
    setLoading(true)
    api.getSupportquestionList({ ...filter, limit: pageSize, page: currentPage })
      .then(data => {
        setLoading(false)
        setList(data.list)
        setCount(data.count)
      })
      .catch(() => setLoading(false))
  }

  const onChangeTable = (pagination, filters, sorter) => {
    if (sorter.columnKey) {
      filter.sort = sorter.columnKey
      filter.sortMethod = sorter.order === 'ascend' ? 'asc' : 'desc'
    } else {
      filter.sort = ''
      filter.sortMethod = ''
    }
    currentPage = pagination.current
    pageSize = pagination.pageSize
    history.replace(match.path + '?page=' + currentPage + '&limit=' + pageSize + '&activeKey=' + 1)
    getList()
  }

  // 查询
  const onSearch = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      if (!err) {
        currentPage = 1
        history.replace(match.path + '?activeKey=1')
        history.replace(match.path + '?page=' + 1 + '&limit=' + pageSize + '&activeKey=1')
        filter = {
          ...filter,
          ...vals,
          searchCreationTimeBegin: vals.searchCreationTime ? vals.searchCreationTime[0].format('YYYY-MM-DD') : '',
          searchCreationTimeEnd: vals.searchCreationTime ? vals.searchCreationTime[1].format('YYYY-MM-DD') : ''
        }
        getList()
      }
    })
  }

  // 重置
  const onReset = () => {
    resetFields()
    filter = {
      title: '',
      class: '',
      userName: '',
      searchCreationTimeBegin: '',
      searchCreationTimeEnd: ''
    }
    currentPage = 1
    history.replace(match.path + '?page=' + 1 + '&limit=' + pageSize + '&activeKey=1')
    getList(currentPage)
  }

  // 类型
  const onStionClass = () => {
    api.getSupportquestionClass({ limit: global.paramsLimit }).then(data => {
      setQuestionClass(data.list)
    })
  }

  // 删除
  const onDelete = (id) => {
    api.delSupportQuestion({ id }, true).then(() => {
      message.success('删除成功')
      getList()
    })
  }

  //打开分类管理列表
  const onManage = () => {
    setVisible(true)
    getQuestionClassList()
  }

  //获取分类列表
  const getQuestionClassList = () => {
    let params = {
      page: moduleCurrentPage,
      limit: modulePageSize
    }
    api.getQuestionClassList(params).then(res => {
      setModuleList(res.list)
      setModuleCount(res.count)
      setModuleLoading(false)
    }).catch(() => { setModuleLoading(false) })
  }

  //分类管理点击分页
  const onChangeModuleTable = (pagination, filters, sorter) => {
    moduleCurrentPage = pagination.current
    modulePageSize = pagination.pageSize
    getQuestionClassList()
  }

  //打开添加分类抽屉
  const addModule = () => {
    setEditRowId('')
    setModuleName('添加分类')
    setModuleAddVisible(true)
  }

  //分类管理编辑
  const onEditModule = (record) => {
    setEditRowId(record.id)
    setModuleName('修改分类')
    setModuleAddVisible(true)
  }

  //删除分类
  const onDelModule = (id) => {
    api.delQuestionClassdel({ id }, true).then(() => {
      message.success('删除成功')
      getList()
      getQuestionClassList()
    })
  }

  // 排序
  const onSort = (id, move) => {
    api.supportQuestionClassSort({ id, move }).then(getQuestionClassList)
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    }
  }

  return (
    <div className="helpCenter-con">
      <Form onSubmit={onSearch} {...formItemLayout} className="ant-advanced-search-formTwo helpCenter-form">
        <div>
          <div>
            <Row gutter={24}>
              <Col span={6}>
                <FormItem label="标题" labelCol={{ span: 4 }}>
                  {getFieldDecorator('title')(<Input placeholder="请输入标题" />)}
                </FormItem>
              </Col>
              <Col span={5}>
                <FormItem label="类型" labelCol={{ span: 4 }}>
                  {getFieldDecorator('class')(
                    <Select placeholder="全部">
                      {questionClass && questionClass.map(item => <Option key={item.id} value={item.id}>{item.title}</Option>)}
                    </Select>
                  )}
                </FormItem>
              </Col>
              <Col span={5}>
                <FormItem label="创建人" labelCol={{ span: 6 }}>
                  {getFieldDecorator('userName')(<Input placeholder="请输入创建人" />)}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label="创建日期" labelCol={{ span: 6 }}>
                  {getFieldDecorator('searchCreationTime')(<RangePicker allowClear={false} />)}
                </FormItem>
              </Col>
            </Row>
          </div>
        </div>
        <div className="recruit-search-btns">
          <Button onClick={onReset}>重置</Button>
          <Button style={{ marginLeft: 8 }} type="primary" htmlType="submit">查询</Button>
        </div>
      </Form>
      <div className="line"></div>
      <div className="add-wrap">
        <Auth auths={authList} code="operate">
          <Button type="primary" icon="appstore" title="分类管理" onClick={onManage}>分类管理</Button>
        </Auth>
        <Auth auths={authList} code="operate">
          <Link to={`${match.path}/helpdetail`}>
            <Button type="primary" icon="plus" title="发布" style={{ marginLeft: '15px' }}>发布</Button>
          </Link>
        </Auth>
      </div>
      <Alert className="corp-count" message={`总计：${count}条数据`} type="info" showIcon />
      <Table
        size="middle"
        dataSource={list}
        rowKey="id"
        className="table-page"
        loading={loading}
        pagination={{
          pageSize,
          total: count,
          current: currentPage,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
        }}
        //  scroll={{ x: 2200 }}
        onChange={onChangeTable}
      >
        <Column title="标题"
          dataIndex="title"
          ellipsis={true}
          width="30%"
          render={(companyName, record) => <Link to={`${match.path}/detail?id=${record.id}&time=${+new Date()}&sign=${CryptoJS.MD5(record.id+'ad629fddf8b8756d2e72e96ae035a5a4'+(+new Date())).toString()}`}>{companyName}</Link>}
        />
        <Column title="类型" dataIndex="supportQuestionClassName" />
        <Column title="创建人" dataIndex="userName" />
        <Column title="浏览次数" dataIndex="readNumber" sorter />
        <Column title="有帮助" dataIndex="useFul" sorter />
        <Column title="没帮助" dataIndex="useLess" sorter />
        <Column title="创建时间" dataIndex="addTime" sorter />
        <Column title="操作" key="set" width={100} render={(text, record) => (
          <>
            <Auth auths={text.operateAuth} code="operate">
              <Tooltip title="编辑" placement="bottom">
                <Link to={`${match.path}/helpdetail?id=${record.id}`}>
                  <Icon className="operate-icon" type="edit" />
                </Link>
              </Tooltip>
            </Auth>
            {' '}
            <Auth auths={text.operateAuth} code="operate">
              <Popconfirm title="确认删除吗？" cancelText="取消" okText="确认" onConfirm={() => onDelete(record.id)}>
                <Tooltip title="删除" placement="bottom">
                  <Icon className="operate-icon" style={{ color: '#EE4943FF' }} type="delete" />
                </Tooltip>
              </Popconfirm>
            </Auth>
          </>
        )} />
      </Table>
      <Drawer
        title={
          <div style={{ position: "relative" }}>
            分类管理
          <Auth auths={authList} code="operate">
              <Tooltip title="添加分类" placement="bottom">
                <Icon onClick={addModule} type="plus-circle" style={{ position: "absolute", right: "40px", top: "5px", cursor: "pointer", color: '#168FFF' }} />
              </Tooltip>
            </Auth>
          </div>
        }
        width={800}
        onClose={() => setVisible(false)}
        visible={visible}
        destroyOnClose={true}
      >
        <Table
          dataSource={moduleList}
          rowKey="id"
          className="sort-table"
          loading={moduleLoading}
          pagination={{
            pageSize: modulePageSize,
            total: moduleCount,
            current: moduleCurrentPage,
            showQuickJumper: true,
            showSizeChanger: true,
            pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
          }}
          onChange={onChangeModuleTable}
        >
          <Column title="名称" dataIndex="title" />
          <Column title="创建人" dataIndex="userName" />
          <Column title="创建时间" dataIndex="addTime" />
          <Column title="排序" key="sort" render={(text, record, index) => (
            <span className="caret-wrap">
              <Icon type="caret-up" onClick={() => onSort(text.id, 'up')} />
              <Icon type="caret-down" onClick={() => onSort(text.id, 'down')} />
            </span>
          )} />
          <Column title="操作"
            key="set"
            render={(text, record) =>
              <>
                <Auth auths={text.operateAuth} code="operate">
                  <Icon className="operate-icon" type="edit" style={{ color: '#168FFF' }} onClick={() => onEditModule(record)} />
                </Auth>
                {' '}
                <Auth auths={text.operateAuth} code="operate">
                  <Popconfirm title="请确认是否删除，删除后不可恢复" cancelText="取消" okText="确认" onConfirm={() => onDelModule(record.id)}>
                    <Icon className="operate-icon" style={{ color: '#EE4943FF' }} type="delete" />
                  </Popconfirm>
                </Auth>
              </>
            }
          />
        </Table>
        <Drawer
          title={moduleName}
          width={540}
          destroyOnClose={true}
          onClose={() => setModuleAddVisible(false)}
          visible={moduleAddVisible}
          className="add-module-drawer"
        >
          <Spin spinning={addModuleLoading}>
            <AddHelpModule
              setVisible={setModuleAddVisible}
              onSuccess={getQuestionClassList}
              editRowId={editRowId}
              setAddModuleLoading={setAddModuleLoading}
            />
          </Spin>
        </Drawer>
        {/* <BtnGroup onCancel={() => setVisible(false)} loading={saveLoading} /> */}
      </Drawer>
    </div>
  );
}

export default Form.create()(HelpCenter)
