/**
 * 模块名称: 帮助中心管理
 * @author zhuyan@372163.com
 */

// import '../assets/style.scss'
import React from 'react'
import { Switch, Route } from 'react-router-dom'
import ThirdPartyMgt from './ThirdPartyMgt'
import WriteBack from './module/WriteBack'
import AddHelpCenter from './module/AddHelpCenter'
import HelpCenterDetail from './module/HelpCenterDetail'

const HelpCenterMgtIndex = ({ match, history }) => {

  return (
    <div>
      <Switch>
        <Route path={match.path + '/ThirdPartyMgt'} component={ThirdPartyMgt} />
        <Route path={match.path + '/replydetail'} component={WriteBack} />
        <Route path={match.path + '/helpdetail'} component={AddHelpCenter} />
        <Route path={match.path + '/detail'} component={HelpCenterDetail} />
        <Route component={ThirdPartyMgt} />
      </Switch>
    </div>
  )
}

export default HelpCenterMgtIndex;
