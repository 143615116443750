/**
 * 模块名称: 第三方配置
 * @author zhuyan@372163.com
 */
import React from 'react'
import { Tabs } from 'antd'
import MailConfig from './MailConfig'
import  NoteConfig from './NoteConfig'

const ThirdParty = () => {
  const { TabPane } = Tabs
  return (
    <div>
      <div className="one-main">
        <Tabs defaultActiveKey="1" >
          <TabPane tab="邮箱配置" key="1">
            <MailConfig />
          </TabPane>
          <TabPane tab="短信配置" key="2">
            <NoteConfig />
          </TabPane>
        </Tabs>
      </div>
    </div>
  )
}

export default ThirdParty;
