/**
 * 模块名称: 待办事项
 * @author xuzhongyuan@372163.com
 */

import React, { useState, useEffect } from 'react'
import BtnGroup from '@/components/BtnGroup'
import { Table, Alert, Form, Input, Select, DatePicker, Button, Drawer, message, Modal } from 'antd'
import api from '@/api'
import { Link } from 'react-router-dom'
import { parseSearch } from '@/utils'
import moment from 'moment'
import './style.scss'
import CryptoJS from 'crypto-js'

const Column = Table.Column
const FormItem = Form.Item
const Option = Select.Option
const { RangePicker } = DatePicker

let pageSize = 10
let currentPage = 1
let timeSort = ''
let filter = {
  type: '',
  title: '',
  status: '',
  startTime: '',
  endTime: ''
}

// 待办事项
const urlObj = {
  44: '/performance/adjustment/detail?id=',
  10: '/hr/promotion/detail?id=',
  18: '/office/procedure/officeworkdetail?id=',
  19: '/hr/jobTransfer/detail?id=',
  9: '/hr/recruit/detail?id=',
  12: '/protocol/invoice/detail?id=',
  13: '/hr/dimission/detail?id=',
  8: '/attendance/appeal/details?id=',
  15: '/attendance/askforleave/addaskdetail?id=',
  20: '/setSystem/helpCenterMgt/replydetail?id=',
  31: '/csr/vendorIdAudit/detail?id=',
  38: '/orderDirect/directDetail?id=',
  39: '/orderAgent/agentDetail?id=',
  28: '/csr/addAudit/detail?id=',
  29: '/csr/nameChangeAudit/detail?id=',
  33: '/protocol/replenish/details?id=',
  34: '/protocol/contract/details?id=',
  35: '/protocol/quittance/details?id=',
  30: '/account/creditapply/details?id=',
  23: '/fund/refundManage/details?id=',
  17: '/fund/moneyTransmit/details?id=',
  22: '/fund/moneyClaim/detail?id=',
  24: '/agent/agentNew/agentNewDetail?id=', // 代理商新增
  244: '/agent/nameManage/details?id=', // 代理商变更
  26: '/agent/manufacturer/details?id=',
  32: '/csr/turnAudit/detail?id=',
  25: '/agent/integralManage/details?id=',
  36: '/protocol/invoice/detail?id=', // 发票清理
  37: '/protocol/invoice/detail?id=',  // 发票冲红
  27: '/agent/agentTransfer/detail?id=',
  40: '/orderDirect/refundDetail?id=', //直销退单
  41: '/orderAgent/refundDetail?id=', //代理商退单
  42: '/orderDirect/paybackDetail?id=', //直销负利还款
  43: '/orderAgent/paybackDetail?id=', //直销负利还款
  45: '/protocol/replenish?id=', //补充协议管理执行
  46: '/supplie/topUp/detail?id=', //系统充值
  47: '/office/costManagement/details?id=', //费用审批
  48: '/asset/purchase/details?id=', // 采购
  49: '/attendance/askforleave/addaskdetails?id=', //育儿假资格申请
  51: '/corpAccount/InternalAccount/details?id=', //内部往来款
  50: '/supplie/supplierContract/details?id=', //合同内容确认
  52: '/supplie/supplierContractSeal/details?id=' //合同盖章申请
}

const Schedule = (props) => {
  const { match, location, history } = props
  const { getFieldDecorator, getFieldsValue, resetFields } = props.form
  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])
  const [count, setCount] = useState(0)
  const [typeList, setTyeList] = useState([])
  const [showConfig, setShowConfig] = useState(false)
  const [configList, setConfigList] = useState([])
  const [selectedKeys, setSelectedkeys] = useState([])
  const search = parseSearch(location.search)

  useEffect(() => {
    currentPage = search.page ? +search.page : 1
    pageSize = search.pageSize ? +search.pageSize : 10
    timeSort = ''
    getData()
    filter = {
      type: '',
      title: '',
      status: '',
      startTime: '',
      endTime: ''
    }
    api.getScheduleTypes().then(data => {
      setTyeList(data)
    })
  }, [])

  const getData = () => {
    setLoading(true)
    api.getScheduleList({ limit: pageSize, page: currentPage, sortMethod: timeSort, ...filter }).then(data => {
      setList(data.list)
      setCount(data.count)
      setLoading(false)
    }).catch(() => setLoading(false))
  }

  const onChangeTable = (pagination, filters, sorter) => {
    switch(sorter.order) {
      case 'ascend':
        timeSort = 'asc'
        break
      case 'descend':
        timeSort = 'desc'
        break
      default:
        timeSort = ''
    }
    currentPage = pagination.current
    pageSize = pagination.pageSize
    history.replace(`${match.path}?page=${currentPage}&pageSize=${pageSize}`)
    getData()
  }

  const formSubmit = (e) => {
    e.preventDefault()
    currentPage = 1
    history.replace(match.path)
    const vals = getFieldsValue()
    filter.type = vals.type
    filter.title = vals.title
    filter.status = vals.status
    if (vals.range) {
      filter.startTime = moment(vals.range[0]).format('YYYY-MM-DD')
      filter.endTime = moment(vals.range[1]).format('YYYY-MM-DD')
    }
    getData()
  }

  // 重置
  const onReset = () => {
    resetFields()
    filter = {
      type: '',
      title: '',
      status: '',
      startTime: '',
      endTime: ''
    }
    currentPage = 1
    history.replace(match.path)
    getData()
  }

  const onSetConfig = () => {
    setShowConfig(true)
    api.getScheduleConfig().then(data => {
      setConfigList(data)
      setSelectedkeys(data.filter(item => item.checked).map(item => item.id))
    })
  }

  const onSaveConfig = () => {
    api.setScheduleConfig({ config: selectedKeys }).then(() => {
      setShowConfig(false)
      message.success('设置成功')
      getData()
    })
  }

  const onSchedule = (title) => {
    Modal.info({
      title: '消息内容',
      content: (
        <div>
          <p>{title}</p>
        </div>
      ),
      onOk() {
      }
    })
  } 

  return (
    <>
      <div>
        <Form layout="inline" onSubmit={formSubmit}>
          <FormItem label="类型">
            {getFieldDecorator('type', { initialValue: '' })(
              <Select placeholder="请选择" style={{ width: 150 }}>
                <Option value={''}>全部</Option>
                {typeList.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
              </Select>
            )}
          </FormItem>
          <FormItem label="标题">
            {getFieldDecorator('title', { initialValue: '' })(<Input placeholder="请输入" />)}
          </FormItem>
          <FormItem label="状态">
            {getFieldDecorator('status', { initialValue: '' })(
              <Select placeholder="请选择" style={{ width: 150 }}>
                <Option value="">全部</Option>
                <Option value="0">未处理</Option>
                <Option value="1">已处理</Option>
              </Select>
            )}
          </FormItem>
          <FormItem label="创建时间">
            {getFieldDecorator('range', { initialValue: ''})(<RangePicker />)}
          </FormItem>
          <FormItem>
            <BtnGroup right style={{ marginTop: 4 }} cancelName="重置" confirmName="查询" onCancel={onReset} />
          </FormItem>
        </Form>
      </div>
      <div className="mark-wrap"><Button type="primary" onClick={onSetConfig}>设置</Button></div>
      <Alert className="alert-ant" message={`总计：${count}条数据`} type="info" showIcon />
      <Table
        dataSource={list}
        rowKey="id"
        className="table-ant"
        loading={loading}
        pagination={{
          pageSize,
          total: count,
          current: currentPage,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
        }}
        scroll={{ x: 1000 }}
        onChange={onChangeTable}
      >
        <Column title="类型" dataIndex="typeName" />
        <Column title="内容" 
          dataIndex="title"
          render={(companyName, record) => urlObj[record.type] ? 
            <Link to={`${urlObj[record.type]}${record.targetId}&time=${+new Date()}&sign=${CryptoJS.MD5(record.targetId+'ad629fddf8b8756d2e72e96ae035a5a4'+(+new Date())).toString()}`}>{record.type == 15 ? `[${record.applyUser.name}] ${companyName} `: companyName}</Link> :
            <div onClick={() => onSchedule(companyName)}>{record.type == 15 ? `[${record.applyUser.name}] ${companyName} `: companyName}</div>
          }
        />
        <Column title="申请人" dataIndex="applyUser.name" />
        <Column title="创建时间" dataIndex="addTime" sorter={true} />
        <Column title="状态" dataIndex="statusName" />
      </Table>
      <Drawer
        title="待办事项设置"
        width={450}
        onClose={() => setShowConfig(false)}
        visible={showConfig}
      >
        <div className="config-wrap">
          <Alert message="请设置是否接收待办事项，勾选后，接收待办事项" type="info" showIcon />
          <Table 
            dataSource={configList} 
            rowKey="id"
            rowSelection={{
              fixed: true,
              selectedRowKeys: selectedKeys,
              onChange: data => setSelectedkeys(data)
            }}
            pagination={false}
          >
            <Column title="审批类型" dataIndex="name" />
          </Table>
          <BtnGroup style={{ marginTop: 20 }} onCancel={()=> setShowConfig(false)} onConfirm={onSaveConfig} />
        </div>  
      </Drawer>
    </>
  )
}

export default Form.create()(Schedule)