import React, {useEffect, useState} from "react";
import {Button, Col, Form, Input, Row, Select, InputNumber, Table} from "antd";

const { Column } = Table
 const RKInfo = (props) => {
    const [SRJinE, setSRJinE] = useState(0);
    const [list, setList] = useState([]);
    const { 
        canEdit, 
        setDefaultSelectIDs, 
        deleteDKIds, 
        showDaoKuan, 
        setRKForms, 
        RKForms, 
        data, 
        index, 
        payList, 
        deleteItem, 
        getFieldDecorator, 
        setFieldsValue,
        getFieldsValue,
        setNameSame,
        setAddFlag,
        clientInfoName
    } = props

    const defaultVals = {}
    useEffect(() => {
         ['payWay', 'account', 'rkQuota', 'remark'].map(name => {
             if(data[name]){
                 defaultVals[name+'_'+index] = data[name];
             }else{
                 defaultVals[name+'_'+index] = undefined;
             }
         })

         data.SrJE = setSRJinEVal(defaultVals['payWay_' + index], defaultVals['rkQuota_' + index])
         RKForms[index] = data
         if(data.moneyBackDetail){
             setList([data.moneyBackDetail])
         }else{
             setList([])
         }
         clientInfoName && dealSameNameData(RKForms)
         setRKForms(RKForms)

         setFieldsValue(defaultVals)
         }, [RKForms]
     )

     const setSRJinEVal = (payWay, num) => {
         let SrJE = 0
         if(num > 0){
            //  if([3].includes(payWay)){ 
            //      SrJE = Math.round(num * 99) / 100
            //  }else{
                 SrJE = num
            //  }
         }
         setSRJinE(SrJE)

         return SrJE
     }

     const getPureName = (str) => {
         let RexStr = /\(|\)|\（|\）|\uff08|\uff09/g
         str = str.replace(RexStr, function() { 
             return ''
         }) 
         return str
     }

     const dealSameNameData = (RKForms) => {
        // console.log(RKForms)
        if(RKForms.length === 1){
           if(RKForms[0].moneyBackDetail){
               let nameA = getPureName(clientInfoName.trim())
               let nameB = getPureName(RKForms[0].moneyBackDetail.name)
               if(nameA == nameB){
                   setNameSame(true)
               }
           }
        }else if(RKForms.length > 1){
           let nameArr = []
           for(let i = 0; i < RKForms.length; i++){
               if(RKForms[i].moneyBackDetail){
                   let nameA = getPureName(clientInfoName.trim())
                   let nameB = getPureName(RKForms[i].moneyBackDetail.name)
                   if(nameA !== nameB){
                       nameArr.push(nameB)
                   }
               }
               
           }
           if(nameArr.length){
               setNameSame(false)
           }else{
               setNameSame(true)
           }
        }
     }

     const selectPayWay = (value) => {
         data['payWay'] = value
         data.SrJE = setSRJinEVal(value, getFieldsValue(['rkQuota_' + index])['rkQuota_' + index])
         RKForms[index] = data;
         setRKForms(RKForms)
         if ([3, 4].includes(value)) {
            // 防止修改后切换
            setFieldsValue({ 
                ['account_'+index]: undefined,
                ['rkQuota_'+index]: undefined,
                ['remark_'+index]: undefined,
            })
            changeAccount(undefined, 2)
            changeRkQuota(undefined)
            changeRemark(undefined, 2)

            showDaoKuan((data) => {
                changeAccount(data.account, 2)
                changeRkQuota(value === 3 ? Math.round(data.amount * 99) / 100 : data.amount) //如果是刷卡方式，扣除1%手续费
                changeRemark(data.name, 2)
                let data2 = { ...data, rkQuota: value === 3 ? Math.round(data.amount * 99) / 100 : data.amount, remark: data.name }
                setTimeout(() => {
                    ['account', 'rkQuota', 'remark'].map(name => {
                        if (data2[name]) {
                            defaultVals[name + '_' + index] = data2[name];
                        } else {
                            defaultVals[name + '_' + index] = undefined;
                        }
                    })
                    setFieldsValue(defaultVals)
                }, 1000)

                RKForms[index].moneyBackId = data.id
                RKForms[index].moneyBackDetail = data

                const newIDs = []
                RKForms.map(item => {
                    if (item.moneyBackId) {
                        newIDs.push(item.moneyBackId)
                    }
                })

                clientInfoName && dealSameNameData(RKForms)
                setRKForms(RKForms)
                setList([data])
                setDefaultSelectIDs(newIDs)
             }, RKForms[index].moneyBackId)
         }
     }

     const changePayWay = (val) => {
         if (RKForms.length === 1) {
            if (val == 6) {
               setAddFlag(false)
            } else {
               setAddFlag(true)
            }
         }
         deleteDKIds(RKForms[index].moneyBackId)
         RKForms[index].moneyBackId = undefined
         RKForms[index].moneyBackDetail = undefined

         setRKForms(RKForms)
         setList([])
     }

     const changeAccount = (val, type) => {
        if (type === 1) {
            const {value} = val.target
            data['account'] = value
        } else {
            data['account'] = val
        }
        RKForms[index] = data
        setRKForms(RKForms)
    }

     const changeRkQuota = (value) => {
         data['rkQuota'] = value

         data.SrJE = setSRJinEVal(getFieldsValue(['payWay_'+index])['payWay_'+index], value)
         RKForms[index] = data;
         setRKForms(RKForms)
     }

     const changeRemark = (val, type) => {
        if (type === 1) {
            const {value} = val.target
            data['remark'] = value
        } else {
            data['remark'] = val
        }
        RKForms[index] = data;
        setRKForms(RKForms)
    }

    return (
        <>
            <Row>
                <Col span={5}>
                    <Form.Item label="到款方式">
                        {getFieldDecorator('payWay_'+index, {
                            rules: [{ required: true, message: '请选择到款方式' }]
                        })(
                            <Select placeholder="请选择" onChange={changePayWay} disabled={!canEdit} onSelect={selectPayWay}>
                                {payList.map(item => <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)}
                            </Select>
                        )}
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label="账号/卡号/支票号/收据号">
                        {getFieldDecorator('account_'+index, {
                            rules: [{ required: true, message: '请填写账号/卡号/支票号/收据号' }]
                        })(
                            <Input disabled={!data.moneyBackDetail && (data.payWay == 3 || data.payWay == 4) ? canEdit : !canEdit} placeholder="账号/卡号/支票号/收据号"  onChange={(e) => changeAccount(e, 1)} />
                        )}
                    </Form.Item>
                </Col>
                <Col span={5}>
                    <Form.Item label="退款金额">
                        {getFieldDecorator('rkQuota_'+index, {
                            rules: [{ required: true, message: '请填写退款金额' }]
                        })(
                            <InputNumber 
                                disabled={(data.payWay == 3 || data.payWay == 4) ? true : false} 
                                placeholder="退款金额"  
                                onChange={changeRkQuota} 
                                min={0.01}
                                style={{width: '100%'}}
                            />
                        )}
                    </Form.Item>
                </Col>
                {/* <Col span={2}>
                    <Form.Item label={<><span style={{color: 'red'}}>*</span> 金额</>}>
                        {SRJinE}
                    </Form.Item>
                </Col> */}
                <Col span={7}>
                    <Form.Item label="付款主体名称/备注">
                        {getFieldDecorator('remark_'+index,{
                            rules: [{ required: true, message: '付款主体名称/备注' }]
                        })(
                            <Input disabled={!data.moneyBackDetail && (data.payWay == 3 || data.payWay == 4) ? canEdit : !canEdit} placeholder="付款主体名称/备注" onChange={(e) => changeRemark(e, 1)}/>
                        )}
                    </Form.Item>
                </Col>
                <Col span={1}>
                    {canEdit && RKForms.length > 1 &&
                    <Button style={{marginTop: 5}} onClick={() => deleteItem(index)}>-</Button>
                    }
                </Col>
            </Row>
            {
                list.length > 0 &&
                <Table
                    dataSource={list}
                    rowKey="id"
                    pagination = {{hideOnSinglePage:true}}
                >
                    <Column title="到款银行" dataIndex="bank" />
                    <Column title="到款日期" dataIndex="date" />
                    <Column title="付款方户名" dataIndex="name" />
                    <Column title="付款方账号" dataIndex="account" />
                    <Column title="到款金额" dataIndex="amount" />
                    <Column title="可认余额" dataIndex="balance" />
                    <Column title="备注" dataIndex="note" />
                </Table>
            }

        </>
    )

}

export default RKInfo