/**
 * 模块名称: 帮助中心管理 => 发布
 * @author zhuyan@372163.com
 */
import React, { useState, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import api from '@/api'
import {
  Form,
  Input,
  Select,
  Upload,
  message,
  Icon,
  Drawer,
  Button,
  Spin
} from 'antd'
import BtnGroup from '@/components/BtnGroup'
import AddHelpModule from '../AddHelpModule'
import Editor from 'wangeditor'
import { parseSearch } from '@/utils'
import { upLoadModule } from '@/utils/common'

const FormItem = Form.Item
const Option = Select.Option
const { Dragger } = Upload

const AddHelpCenter = (props) => {
  const { getFieldDecorator, validateFields, setFieldsValue } = props.form
  const { history } = props
  const [fileList, setFileList] = useState([])
  const [content, setContent] = useState('')
  const [questionClass, setQuestionClass] = useState([])
  const draggerRef = useRef(null)
  const [editorHtml, setEditorHtml] = useState('')//eslint-disable-line
  const [editorText, setEditorText] = useState('')//eslint-disable-line
  const [moduleAddVisible, setModuleAddVisible] = useState(false)
  const [addModuleLoading, setAddModuleLoading] = useState(true)
  const contentRef = useRef(null)
  const search = parseSearch(props.location.search)

  useEffect(() => {
    onStionClass()
    onQuestionDetail()
    initEditor()
  }, [])

  // 类型
  const onStionClass = (titleVal) => {
    api.getSupportquestionClass({ limit: global.paramsLimit }).then(data => {
      setQuestionClass(data.list)
      data.list.forEach((item, index, arr) => {
        if (item.title === titleVal) {
          setFieldsValue({
            supportQuestionClassId: item.id
          })
        }
      })
    })
  }

  // 筛选类型
  const classSelect = (value) => {
    api.getSupportquestionClass({
      supportQuestionClassName: value,
      limit: global.paramsLimit,
    }).then(data => {
      setQuestionClass(data.list)
    }).catch()
  }

  //富文本
  const initEditor = () => {
    let editor = new Editor(ReactDOM.findDOMNode(contentRef.current))
    dealEditor(editor)
    editor.create()
  }
  //富文本数据配置
  const dealEditor = (editor) => {
    editor.customConfig.zIndex = 100
    editor.customConfig.showLinkImg = false
    editor.customConfig.uploadImgServer = '/api/File/upload'
    editor.customConfig.uploadFileName = 'file'
    editor.customConfig.uploadImgHeaders = {
      token: localStorage.getItem('token')
    }
    // 自定义菜单配置
    editor.customConfig.menus = [
      'head',  // 标题
      'bold',  // 粗体
      'fontSize',  // 字号
      'fontName',  // 字体
      'italic',  // 斜体
      'underline',  // 下划线
      'strikeThrough',  // 删除线
      'foreColor',  // 文字颜色
      'backColor',  // 背景颜色
      'link',  // 插入链接
      'list',  // 列表
      'justify',  // 对齐方式
      'quote',  // 引用
      'image',  // 插入图片
      'table',  // 表格
      'code',  // 插入代码
      'undo',  // 撤销
      'redo'  // 重复
    ]
    //插入视频 : 'video'
    //'emoticon',  // 表情
    editor.customConfig.onchange = (html) => {
      //console.log(html)
      setEditorHtml(html)
      setEditorText(editor.txt.text())
      setFieldsValue({
        content: html
      })
    }
    editor.customConfig.uploadImgHooks = {
      before(xhr, editor, files) {
        //console.log(xhr, editor, files)
      },
      success(xhr, editor, result) {
        //console.log(xhr, editor, result)
      },
      fail(xhr, editor, result) {
        // console.log(xhr, editor, result)
      },
      error(xhr, editor, result) {
        // console.log(xhr, editor, result)
      },
      timeout(xhr, editor) {
        // console.log(xhr, editor)
      },
      customInsert(insertImg, result, editor) {
        //console.log(insertImg, result, editor)
        var url = result.data.full
        insertImg(url)
      }
    }
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 2 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 22 },
    }
  }
  //根据是否传值过来Id判断是上传文档还是编辑文档
  const onQuestionDetail = () => {
    let params = {}
    if (search.id) {
      params.id = search.id
      params.manager = '1'
      api.getSupportQuestionDetail(params, true).then(res => {
        //console.log(res)
        // setTitle(res.title)
        setFieldsValue({
          title: res.title,
          supportQuestionClassId: res.supportQuestionClassId
        })
        if (res.file.length) {
          res.file.forEach((item, index) => {
            fileList.push({
              status: 'done',
              uid: index,
              name: item.name,
              url: item.url
            })
          })
          setFileList(res.file.map((item, index) => ({...item, uid: index})))
        }
        setContent(res.content)
        let editor = new Editor(ReactDOM.findDOMNode(contentRef.current))
        dealEditor(editor)
        editor.create()
        editor.txt.html(res.content)
      }).catch(() => { })
    }
  }

  //提交表单
  const formSubmit = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      let params = Object.assign({}, vals)
      let uploadList = []
      for (let i = 0, k = fileList.length; i < k; i++) {
        if (fileList[i].status !== 'removed') {
          uploadList.push({
            url: fileList[i].url,
            name: fileList[i].name
          })
        }
      }
      params.file = uploadList
      if (search.id) params.id = search.id
      if (!search.id) params.id = ''
      //console.log(match.path)
      if (!err) {
        api.saveSupportQuestionUpdate(params).then(res => {
          if (!search.id) {
            message.success('发布成功')
          } else {
            message.success('编辑成功')
          }
          history.push('/setSystem/helpCenterMgt')
        })
      }
    })
  }

  //取消发布
  const onCancel = () => {
    history.goBack()
    // history.push({
    // 	pathname:'/setSystem/helpCenterMgt',
    // 	state: { 'tabActiveKey': '1' }
    // })
  }

  // 文件上传 
	const uploadFiles = upLoadModule({
		fileList: fileList,
		setFileList: setFileList,
		draggerRef: draggerRef,
		accept: '.gif, .png, .jpg, .jpeg, .PDF, .doc, .docx, .csv, .xlsx',
		allowSizeType: 2
	})

  const addClassifyVisible = () => {
    setModuleAddVisible(true)
  }

  return (
    <>
      <Form onSubmit={formSubmit} {...formItemLayout} style={{ width: "80%" }}>
        <FormItem label="标题">
          {getFieldDecorator('title', {
            rules: [{ required: true, message: '请输入标题' }]
          })(<Input placeholder="请输入标题" autoComplete="off" />)}
        </FormItem>
        <FormItem label="选择分类" >
          {getFieldDecorator('supportQuestionClassId', {
            rules: [{ required: true, message: '请选择分类' }]
          })(
            <Select
              placeholder="请选择"
              showArrow
              filterOption={false}
              onSearch={classSelect}
              autoFocus
              showSearch={true}
              style={{ width: "50%" }}
            >
              {questionClass.map(item => <Option key={item.id} value={item.id}>{item.title}</Option>)}
            </Select>
          )}
          <Button className="addClassify" type="primary" onClick={addClassifyVisible}>添加分类</Button>
        </FormItem>
        <FormItem label="内容">
          {getFieldDecorator('content', {
            initialValue: content,
            rules: [{ required: true, message: '请输入内容' }]
          })(<div className="wangEditor-container" ref={contentRef}></div>)}
        </FormItem>
        <FormItem label="附件" style={{marginTop: 14}}>
          <div className="dragger-box" ref={draggerRef}>
            <Dragger
              {...uploadFiles}
            >
              <p className="ant-upload-drag-icon">
                <Icon type="inbox" />
              </p>
              <p className="ant-upload-text">点击或将文件拖拽到这里上传</p>
              <p className="ant-upload-hint">
                附件支持格式：GIF、PNG、JPG、PDF、doc、docx、csv、xlsx  支持添加多个附件，单个附件大小不超过5M，文件数量不超过5个。
							</p>
            </Dragger>
          </div>
        </FormItem>
        <div style={{marginTop: 30}}>
          <BtnGroup onCancel={onCancel} />
        </div>
      </Form>
      <Drawer
        title="添加分类"
        width={540}
        destroyOnClose={true}
        onClose={() => setModuleAddVisible(false)}
        visible={moduleAddVisible}
        className="add-module-drawer"
      >
        <Spin spinning={addModuleLoading}>
          <AddHelpModule
            setVisible={setModuleAddVisible}
            onSuccess={onStionClass}
            setAddModuleLoading={setAddModuleLoading}
          />
        </Spin>
      </Drawer>
    </>
  )
}

export default Form.create()(AddHelpCenter)