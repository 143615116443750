/**
 * 模块名称: 第三方配置(手机)
 * @author zhuyan@372163.com
 */
import React, { useState, useEffect } from 'react'
import { Input, Form, Radio, Button, message } from 'antd'
import api from '@/api'
import BtnGroup from '@/components/BtnGroup'
import Auth from '@/components/AuthMiddleware'

const FormItem = Form.Item

const NoteConfig = (props) => {
  const { getFieldDecorator, validateFields, setFieldsValue } = props.form
  const [mobilelVal, setMobilelVal] = useState('')
  const [timeValue, setTimeValue] = useState('发送测试短信')
  const [codeDisabled, setCodeDisabled] = useState(false)
  const [authList, setAuthList] = useState([])

  useEffect(() => {
    getThirdPartySmsConfig()
    api.getPageAuth().then(list => setAuthList(list))
  }, [])

  // 获取初始值
  const getThirdPartySmsConfig = () => {
    api.getThirdPartySmsConfig({
      setting: 1
    }, true)
      .then(data => {
        let initData = data
        setFieldsValue({
          appKey: initData.appkey,
          secret: initData.secret,
          isopen: 1
        })
      })
  }

  const mobileChange = (e) => {
    setMobilelVal(e.target.value)
  }

  // 倒计时
  const timeoutInterval = () => {
    setCodeDisabled(true)
    let sumTime = 60
    let interval = setInterval(() => {
      if (sumTime > 0) {
        sumTime--
        setTimeValue("发送短信测试(" + sumTime + "S)")
      } else {
        clearInterval(interval)
        setCodeDisabled(false)
        setTimeValue("发送短信测试")
      }
    }, 1000)
  }

  //发送短信测试
  const onTestSms = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      if (!err) {
        const phoneReg = /0?(13|14|15|18|17)[0-9]{9}/
        if (!phoneReg.test(vals.testtel)) {
          message.error('手机号格式不正确')
          return
        }
        api.setThirdPartySms({
          appkey: vals.appKey,
          secret: vals.secret,
          isopen: vals.isopen,
        }).then((data) => {
          if (mobilelVal.trim() !== '') {
            timeoutInterval()
            api.testThirdPartySms({
              to: mobilelVal
            })
          } else {
            message.error("请输入测试手机号")
          }
        }).catch()
      }
    })
  }

  // 提交
  const formSubmit = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      if (!err) {
        api.setThirdPartySms({
          appkey: vals.appKey,
          secret: vals.secret,
          isopen: vals.isopen,
        }).then((data) => {
          message.success('设置成功')
        }).catch()
      }
    })
  }
  const formItemLayout = {
    labelCol: {
      xs: { span: 10 },
      sm: { span: 5 },
    },
    wrapperCol: {
      xs: { span: 10 },
      sm: { span: 10 },
    }
  }

  return (
    <div className="tel-con">
      <Form onSubmit={formSubmit} {...formItemLayout}>
        <FormItem label="阿里大鱼(appKey)">
          {getFieldDecorator('appKey', {
            rules: [{ required: true, message: '请输入阿里大鱼' }]
          })(
            <Input placeholder="请输入阿里大鱼(appKey)" />
          )}
        </FormItem>
        <FormItem label="阿里大鱼(secretKey)">
          {getFieldDecorator('secret', {
            rules: [{ required: true, message: '阿里大鱼(secretKey)' }]
          })(
            <Input placeholder="请输入阿里大鱼(secretKey)" />
          )}
        </FormItem>
        <FormItem label="测试手机账号" className="testTelSite">
          {getFieldDecorator('testtel', {
            rules: [{
              // pattern: /^[1][3,4,5,7,8][0-9]{9}$/, message: '手机号码格式不正确'
            }],
          })(
            <Input placeholder="请输入手机号码" onChange={mobileChange} />
          )}
          <Auth auths={authList} code="operate">
            <Button type="primary" onClick={onTestSms} disabled={codeDisabled} >{timeValue}</Button>
          </Auth>
        </FormItem>
        <Form.Item label="是否启用">
          {getFieldDecorator('isopen', {
          })(
            <Radio.Group>
              <Radio value={1} >是</Radio>
              <Radio value={0} >否</Radio>
            </Radio.Group>
          )}
        </Form.Item>
        <Auth auths={authList} code="operate">
          <BtnGroup onCancel={() => getThirdPartySmsConfig()} />
        </Auth>
      </Form>
    </div>
  )
}

export default Form.create()(NoteConfig)

